import { FormControl, MenuItem, Select } from "@mui/material";

import { PartnerBusinessArea } from "@sellernote/_shared/src/types/forwarding/partnerManagement";

import NO_LABEL from "pages/partner-management/styles";

import OPTIONS_LIST from "./OPTION_LIST";

export default function PartnerType({
  handleTypeUpdate,
  defaultValue,
}: {
  handleTypeUpdate: (type: PartnerBusinessArea) => void;
  defaultValue?: string;
}) {
  return (
    <FormControl size="small" fullWidth sx={NO_LABEL}>
      <Select
        onChange={(v) =>
          handleTypeUpdate(v.target.value as PartnerBusinessArea)
        }
        defaultValue={defaultValue}
      >
        {OPTIONS_LIST.map((option) => (
          <MenuItem key={option.label} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
