import { Box, Divider, Typography } from "@mui/material";

export default function AccountInfoHeader() {
  return (
    <>
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h6">거래처(정산) 정보</Typography>
      </Box>

      <Divider>
        <Typography variant="caption">
          파트너(매입처)에 대한 거래처 추가 및 수정은 경영지원실에 요청해주세요.
        </Typography>
      </Divider>
    </>
  );
}
