import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";

import SettlementWithdrawalTable from "./SettlementWithdrawalTable";

function SettlementWithdrawalManagement() {
  return (
    <Layout title="출금 관리">
      <SettlementWithdrawalTable />
    </Layout>
  );
}
export default SettlementWithdrawalManagement;
