import { Autocomplete, TextField } from "@mui/material";

import { AdminBidTransportMode } from "@sellernote/_shared/src/types/forwarding/adminBid";

import { TRANSPORT_MODE_LIST } from "../constants/TRANSPORT_MODE_LIST";

export default function TransportModeOption({
  transportModeList,
  onTransportModeEdit,
  isTransportModeContainsAll,
}: {
  transportModeList: string[];
  onTransportModeEdit: (val: AdminBidTransportMode[]) => void;
  isTransportModeContainsAll: boolean;
}) {
  const transportModeForDefaultValue = transportModeList.map((v) => {
    return { label: v, value: v };
  });

  const handleTransportModeEdit = (
    transportOptionList: { value: string }[]
  ) => {
    /** Option의 value만 추출해 ["FCL", "LCL"]와 같은 형태로 변경 */
    const transportOptionValueList = transportOptionList.map(
      ({ value }) => value
    );

    onTransportModeEdit(transportOptionValueList as AdminBidTransportMode[]);
  };

  return (
    <Autocomplete
      multiple
      size="small"
      defaultValue={transportModeForDefaultValue}
      options={isTransportModeContainsAll ? [] : TRANSPORT_MODE_LIST}
      getOptionLabel={({ label }) => label}
      filterSelectedOptions
      renderInput={(params) => <TextField {...params} />}
      onChange={(_, transportOptionList) => {
        handleTransportModeEdit(transportOptionList);
      }}
    />
  );
}
