import { useCallback, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

import { Port } from "@sellernote/_shared/src/types/common/common";
import { AdminOrderDetail } from "@sellernote/_shared/src/types/forwarding/adminOrder";
import { POBid } from "@sellernote/_shared/src/types/forwarding/bid";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import {
  changeBidProjectStatusNameToKr,
  changeBidStatusNameToKr,
  getSupplyValueInUnit,
} from "@sellernote/_shared/src/utils/forwarding/bid";
import { getPortNameForDetailBidList } from "@sellernote/_shared/src/utils/forwarding/port";
import LegacyTable, {
  LegacyTableHeadCell,
} from "@sellernote/_shared-for-forwarding-admin/src/components/LegacyTable";

import CountingModal from "./CountingModal";
import TeamMemberSelectModal from "./TeamMemberSelectModal";

export default function OrderCreateTable({
  orderDetail,
  portList,
}: {
  orderDetail: AdminOrderDetail;
  portList: Port[] | undefined;
}) {
  type CellId = keyof POBid | "cargoInfo" | "shippingInfo" | "checkpoint";

  const location = useLocation();

  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(0);

  const [perPage, setPerPage] = useState(20);

  const [showsCountingModal, setShowsCountingModal] = useState(false);
  const [opensTeamMemberSelectModal, setOpensTeamMemberSelectModal] =
    useState(false);

  const handleUploadModalOpen = () => {
    if (orderDetail.teamPrivate) {
      setShowsCountingModal(true);
      return;
    }
    setOpensTeamMemberSelectModal(true);
  };

  const handleBidIdClick = useCallback(
    (id: number) => {
      /** 선적미계획 의뢰 */
      if (id === orderDetail.subPoNumber) {
        history.push(`/order/sub/${orderDetail.id}`);
      } else {
        history.push(`/bid/detail/${id}`);
      }
    },
    [history, orderDetail.id, orderDetail.subPoNumber]
  );

  const rows = useMemo(() => {
    /** 선적미계획에서는 테이블을 보여주지 않는다. */
    if (
      !orderDetail?.bids ||
      (orderDetail.subPoStatus === "notYet" &&
        location.pathname.includes("/sub"))
    )
      return [];

    const orderDetailBidRows = orderDetail?.bids.map((v) => {
      const row = {
        handleRowClick: () => handleBidIdClick(v.id),
        id: v.id,
        userName: v.userName,
        cargoInfo: (
          <Box>
            <Typography variant="body2" component="div">
              {v.name.length > 1
                ? `${v.name[0]} 외 ${v.name.length - 1}`
                : v.name[0]}
            </Typography>

            <Typography variant="body2" component="div">
              {v.freightType}
            </Typography>

            {v.supply && (
              <Typography variant="body2" component="div">
                {getSupplyValueInUnit(v.freightType, v.supply)}
              </Typography>
            )}
          </Box>
        ),
        shippingInfo: (
          <Box>
            <Typography variant="body2" component="div">
              {v.incoterms}
            </Typography>

            <Typography variant="body2" component="div">
              {getPortNameForDetailBidList(v, "startPortId", portList)}
              {"->"}
              {getPortNameForDetailBidList(v, "endPortId", portList)}
            </Typography>
          </Box>
        ),
        checkpoint: (
          <Box>
            <Typography variant="body2" component="div">
              통관의뢰여부: {v.containCustoms ? "예" : "아니오"}
            </Typography>

            <Typography variant="body2" component="div">
              내륙운송 의뢰여부: {v.inlandType ? "예" : "아니오"}
            </Typography>
          </Box>
        ),
        status: changeBidStatusNameToKr(v.status),
        projectStatus: changeBidProjectStatusNameToKr(v.projectStatus),
        createdAt: toFormattedDate(v.createdAt, "YYYY.MM.DD(dd) HH:mm"),
      };

      return row;
    });

    /** 선적 미계획의뢰가 있는 경우에는 임의 데이터를 추가한 후 다시 날짜 순으로 재 정렬한다. */
    if (orderDetail.subPoStatus === "notYet") {
      const newOrderDetailBidRows = [
        ...orderDetailBidRows,
        {
          handleRowClick: () => handleBidIdClick(orderDetail.subPoNumber),
          id: `NY-${orderDetail.subPoNumber}`,
          userName: "-",
          cargoInfo: "선적미계획 의뢰",
          shippingInfo: "-",
          checkpoint: "-",
          status: "확인 중",
          projectStatus: "운송의뢰 생성 전",
          createdAt: orderDetail.subPoCreatedAt
            ? toFormattedDate(
                orderDetail.subPoCreatedAt,
                "YYYY.MM.DD(dd) HH:mm"
              )
            : toFormattedDate(new Date(), "YYYY.MM.DD(dd) HH:mm"),
        },
      ];
      return newOrderDetailBidRows.sort((a, b) => {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      });
    }

    return orderDetailBidRows;
  }, [
    handleBidIdClick,
    location.pathname,
    orderDetail?.bids,
    orderDetail.subPoCreatedAt,
    orderDetail.subPoNumber,
    orderDetail.subPoStatus,
    portList,
  ]);

  const HeadCells = useMemo(() => {
    const headCells: LegacyTableHeadCell<CellId>[] = [
      {
        id: "id",
        disablePadding: false,
        label: "운송의뢰번호",
      },
      {
        id: "userName",
        disablePadding: false,
        label: "담당자명",
        width: 100,
      },
      {
        id: "cargoInfo",
        disablePadding: false,
        label: "화물정보",
        width: 180,
      },
      {
        id: "shippingInfo",
        disablePadding: false,
        label: "운송정보",
      },
      {
        id: "checkpoint",
        disablePadding: false,
        label: "체크포인트",
        width: 180,
      },
      {
        id: "status",
        disablePadding: false,
        label: "상태",
      },
      {
        id: "projectStatus",
        disablePadding: false,
        label: "세부 상태",
      },
      {
        id: "createdAt",
        disablePadding: false,
        label: "생성일",
      },
    ];
    return headCells;
  }, []);

  const pagination = {
    rowsPerPageOptions: [10, 20, 50, 100, 500, 1000, 10000],
    totalCount: orderDetail.bids.length || 0,
    perPage,
    setPerPage,
    currentPage,
    setCurrentPage,
  };
  return (
    <>
      <LegacyTable
        title="운송정보"
        headCells={HeadCells}
        rows={rows}
        pagination={pagination}
        toolbarItems={{
          right: [
            <Button
              variant="outlined"
              key="countButton"
              disabled={orderDetail.poStatus === "registered"}
              onClick={() => handleUploadModalOpen()}
            >
              운송 의뢰 생성
            </Button>,
          ],
        }}
      />

      {showsCountingModal && (
        <CountingModal
          showsCountingModal={showsCountingModal}
          setShowsCountingModal={setShowsCountingModal}
          POId={orderDetail.id}
          userCompany={orderDetail.userCompany}
          userId={orderDetail.userId}
          POProjectStatus={orderDetail.poProjectStatus}
          hasSubPO={orderDetail.subPoStatus === "notYet"}
          teamId={orderDetail.teamId}
          isOpenApiAuth={orderDetail.isOpenApiAuth}
        />
      )}

      {opensTeamMemberSelectModal && (
        <TeamMemberSelectModal
          opensTeamMemberSelectModal={opensTeamMemberSelectModal}
          setOpensTeamMemberSelectModal={setOpensTeamMemberSelectModal}
          POId={orderDetail.id}
          teamName={orderDetail.teamName}
          POProjectStatus={orderDetail.poProjectStatus}
          hasSubPO={orderDetail.subPoStatus === "notYet"}
          teamId={orderDetail.teamId}
          isOpenApiAuth={orderDetail.isOpenApiAuth}
        />
      )}
    </>
  );
}
