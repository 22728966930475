import { useQueryClient } from "react-query";
import { useAtomValue } from "jotai";

import ADMIN_PARTNER_MANAGEMENT_QUERY, {
  ADMIN_PARTNER_MANAGEMENT_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_PARTNER_MANAGEMENT_QUERY";
import { ResponseFailureInfo } from "@sellernote/_shared/src/types/common/common";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";
import { FORWARDING_PARTNER_MANAGEMENT_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/partnerManagement";

export default function useHandleDeletePartner() {
  const { handleSnackbarOpen } = useSnackbar();

  const { page, perPage } = useAtomValue(
    FORWARDING_PARTNER_MANAGEMENT_ATOMS.SEARCH_PARAMS
  );

  const queryClient = useQueryClient();

  const onSuccess = () => {
    handleSnackbarOpen("파트너가 삭제되었습니다.");
    queryClient.invalidateQueries(
      ADMIN_PARTNER_MANAGEMENT_QUERY_KEY_GEN.getPartnerList({
        page,
        perPage,
      })
    );
  };

  const onError = (err: ResponseFailureInfo | undefined) => {
    handleSnackbarOpen(
      err?.code === 409
        ? "매입 내역이 존재하는 파트너는 삭제가 불가능합니다."
        : "파트너 삭제에 실패했습니다.",
      "error"
    );
  };

  const { mutate: deletePartner } =
    ADMIN_PARTNER_MANAGEMENT_QUERY.useDeletePartner({ onSuccess, onError });

  const handlePartnerDelete = ({ id }: { id: number }) => {
    deletePartner({ pathParams: { id } });
  };

  return { handlePartnerDelete };
}
