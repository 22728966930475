import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useAtom } from "jotai";

import { PartnerSearchType } from "@sellernote/_shared/src/types/forwarding/partnerManagement";
import { FORWARDING_PARTNER_MANAGEMENT_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/partnerManagement";

export default function SearchType() {
  const [params, setParams] = useAtom(
    FORWARDING_PARTNER_MANAGEMENT_ATOMS.SEARCH_PARAMS
  );

  const { searchType, searchValue } = params;

  const handleSearchTypeUpdate = (value: PartnerSearchType) => {
    setParams({ ...params, page: 0, searchType: value });
  };

  const handleSearchValueUpdate = (value: string) => {
    const undefinedValue = value.length ? value : undefined;

    setParams({ ...params, page: 0, searchValue: undefinedValue });
  };

  return (
    <Box display={"flex"} gap={1}>
      <FormControl size="small">
        <InputLabel>검색 유형</InputLabel>

        <Select
          value={searchType}
          label="검색 유형"
          onChange={(e) => {
            handleSearchTypeUpdate(e.target.value as PartnerSearchType);
          }}
          sx={{ width: 150 }}
        >
          <MenuItem value={"name"}>회사명</MenuItem>
          <MenuItem value={"BRNNumber"}>사업자등록번호</MenuItem>
          <MenuItem value={"accountNumber"}>계좌번호</MenuItem>
        </Select>
      </FormControl>

      <TextField
        variant="outlined"
        size="small"
        sx={{
          "& legend": { display: "none" },
          "& fieldset": { top: 0 },
          width: 190,
        }}
        value={searchValue}
        onChange={(e) => handleSearchValueUpdate(e.target.value)}
      />
    </Box>
  );
}
