import styled from "styled-components";

const registrant = styled.div`
  cursor: pointer;
  font-size: 12px;
  width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default {
  registrant,
};
