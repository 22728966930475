import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { Button, CircularProgress } from "@mui/material";
import { AxiosResponse } from "axios";

import { RequestInvoiceData } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminFile";
import { ADMIN_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_FILE_QUERY";
import { ResponseFailureInfo } from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import {
  InvoiceType,
  TrelloBidDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { checkIfTradingStatementCanBeSendToUser } from "@sellernote/_shared/src/utils/forwarding/tradingStatement";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

import useCompareWithDefaultValue from "../../../hooks/useCompareWithDefaultValue";
import useCommonErrorMessage from "../hooks/useCommonErrorMessage";

function UserInvoiceButton({
  invoiceType,
  getInvoiceDataForRequest,
  handleRequestError,
  shipmentDetailData,
  invoiceState,
  isIssuedInvoice,
  directAirRton,
  sessionInvoiceId,
  onSessionInvoiceChange,
}: {
  invoiceType: InvoiceType;
  getInvoiceDataForRequest: () => RequestInvoiceData;
  handleRequestError: (
    response: AxiosResponse<ResponseFailureInfo, any> | undefined
  ) => void;
  shipmentDetailData: TrelloBidDetail;
  invoiceState: ApplyBidFormData;
  isIssuedInvoice: boolean;
  directAirRton: number;
  sessionInvoiceId: string | null;
  onSessionInvoiceChange: (invoiceId: string) => void;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const { mutate: saveInvoice, isLoading: saveInvoiceLoading } =
    ADMIN_FILE_QUERY.useSaveInvoice();

  const { mutate: editInvoice, isLoading: editInvoiceLoading } =
    ADMIN_FILE_QUERY.useEditInvoice();

  const { mutate: sendUserInvoice } = ADMIN_FILE_QUERY.useSendUserInvoice();

  const { checkAndShowCommonErrorMessage } = useCommonErrorMessage();

  const { isCargoInfoUnchanged, isExchangeInfoUnchanged } =
    useCompareWithDefaultValue({
      invoiceState,
      shipmentDetailData,
      directAirRton,
    });

  const handleSuccess = useCallback(
    ({ data }) => {
      const invoiceId = data.id;

      sendUserInvoice(
        {
          pathParams: { invoiceId },
          invoiceType,
        },
        {
          onSuccess: () => {
            if (sessionInvoiceId) {
              queryClient.invalidateQueries(
                ADMIN_BID_QUERY_KEY_GEN.getAdminInvoice({
                  invoiceId,
                })
              );
            } else {
              onSessionInvoiceChange(invoiceId.toString());
            }
            handleSnackbarOpen("요청에 성공했습니다");
          },
          onError({ response }) {
            handleRequestError(response);
          },
        }
      );
    },
    [
      handleRequestError,
      onSessionInvoiceChange,
      handleSnackbarOpen,
      invoiceType,
      queryClient,
      sendUserInvoice,
      sessionInvoiceId,
    ]
  );

  const handleUserInvoiceRequestClick = useCallback(() => {
    if (
      checkAndShowCommonErrorMessage({
        isCargoInfoUnchanged,
        isExchangeInfoUnchanged,
        isIssuedInvoice,
        isValidateBRN: invoiceState.userBRN.length !== 10,
        canBeSendToUser: checkIfTradingStatementCanBeSendToUser(
          shipmentDetailData.projectStatus,
          shipmentDetailData.locale,
          shipmentDetailData.isImport
        ),
        hasDirectAirRton:
          shipmentDetailData.freightType === "AIR" && !directAirRton,
      })
    ) {
      return;
    }

    if (sessionInvoiceId) {
      editInvoice(
        {
          pathParams: {
            invoiceId: Number(sessionInvoiceId),
          },
          invoiceData: getInvoiceDataForRequest(),
          isTemporary: false,
          invoiceType,
        },
        {
          onSuccess: handleSuccess,

          onError: ({ response }) => {
            handleRequestError(response);
          },
        }
      );
    } else {
      saveInvoice(
        {
          invoiceData: getInvoiceDataForRequest(),
          isTemporary: false,
          invoiceType,
        },
        {
          onSuccess: handleSuccess,

          onError: ({ response }) => {
            handleRequestError(response);
          },
        }
      );
    }
  }, [
    checkAndShowCommonErrorMessage,
    isCargoInfoUnchanged,
    isExchangeInfoUnchanged,
    isIssuedInvoice,
    invoiceState.userBRN.length,
    shipmentDetailData.projectStatus,
    shipmentDetailData.locale,
    shipmentDetailData.isImport,
    shipmentDetailData.freightType,
    directAirRton,
    sessionInvoiceId,
    editInvoice,
    getInvoiceDataForRequest,
    invoiceType,
    handleSuccess,
    handleRequestError,
    saveInvoice,
  ]);

  return (
    <Button
      disabled={saveInvoiceLoading || editInvoiceLoading}
      variant="contained"
      color="secondary"
      onClick={handleUserInvoiceRequestClick}
    >
      {saveInvoiceLoading || editInvoiceLoading ? (
        <CircularProgress size={25} />
      ) : (
        "고객사 발송"
      )}
    </Button>
  );
}

export default UserInvoiceButton;
