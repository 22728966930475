import { useQueryClient } from "react-query";
import { Modal } from "antd";

import PARTNER_QUERY from "@sellernote/_shared/src/queries/forwarding/PARTNER_QUERY";

const SelectMainManagerModal = ({
  showSelectMainManager,
  afterClose,
  companyId,
  managerId,
}: {
  showSelectMainManager: boolean;
  afterClose: () => void;
  companyId: number;
  managerId: number;
}) => {
  const queryClient = useQueryClient();

  const {
    mutate: selectMainManager,
    ResponseHandler: ResponseHandlerOfSelectMainManager,
  } = PARTNER_QUERY.useSelectMainManager({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        afterClose();
        queryClient.invalidateQueries(["forwarding", "partner", "list"]);
      },
      customizeMessage: () => ({
        title: "메인 담당자를 등록했습니다.",
      }),
    },
    companyId,
    managerId,
  });

  return (
    <Modal
      title="메인 담당자로 등록하시겠습니까?"
      okText="등록"
      cancelText="취소"
      visible={showSelectMainManager}
      onCancel={() => {
        afterClose();
      }}
      onOk={() => selectMainManager({ isMain: true })}
    >
      {ResponseHandlerOfSelectMainManager}
    </Modal>
  );
};

export default SelectMainManagerModal;
