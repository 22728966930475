const BOX_STYLE = {
  border: 1,
  borderColor: "rgb(230, 235, 241)",
  backgroundColor: "white",
  borderRadius: "4px",
  padding: 2,
  width: 350,
};

export default BOX_STYLE;
