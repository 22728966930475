import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";
import useVesselScheduleTable from "@sellernote/_shared-for-forwarding-admin/src/hooks/useVesselScheduleTable";

function VesselScheduleAir() {
  const { VesselScheduleTable, ResponseHandlerOfGetVesselScheduleList } =
    useVesselScheduleTable({ freightType: "AIR", includesWarehouse: false });

  return (
    <Layout breadcrumbs={[]} title={"운항 스케줄 AIR"}>
      {VesselScheduleTable}

      {ResponseHandlerOfGetVesselScheduleList}
    </Layout>
  );
}

export default withRequireAuth(VesselScheduleAir);
