import { ChangeEvent, useCallback } from "react";
import { UseFormSetValue } from "react-hook-form";

import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { TrelloBidDetail } from "@sellernote/_shared/src/types/forwarding/trello";
import { getValueAsNumberOnly } from "@sellernote/_shared/src/utils/common/validation";

import DescriptionGridBox from "../components/DescriptionGridBox";
import NoLabelTextField from "../components/NoLabelTextField";

function SettlementOfficeInfo({
  invoiceState,
  shipmentDetailData,
  setValue,
  onCommonInputChange,
}: {
  invoiceState: ApplyBidFormData;
  shipmentDetailData: TrelloBidDetail;
  onCommonInputChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  setValue: UseFormSetValue<ApplyBidFormData>;
}) {
  /**
   * 한국 사업자등록번호는 숫자만 입력 가능함
   * 해당 사업자등록번호는 저장 버튼을 눌렀을 때 검증
   */
  const handleUserBRNChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValue(
        "userBRN",
        shipmentDetailData.locale === "KR"
          ? getValueAsNumberOnly(event.target.value)
          : event.target.value
      );
    },
    [setValue, shipmentDetailData.locale]
  );
  return (
    <DescriptionGridBox
      title={"정산처 정보"}
      descriptionValueList={[
        {
          label: "회사명",
          labelGridSize: 3,
          gridSize: 4,
          value: (
            <NoLabelTextField
              name="recipient"
              value={invoiceState.recipient}
              onChange={onCommonInputChange}
              size="small"
              fullWidth
            />
          ),
        },
        {
          label: "사업자등록번호",
          labelGridSize: 3,
          gridSize: 4,
          value: (
            <NoLabelTextField
              value={invoiceState.userBRN}
              onChange={handleUserBRNChange}
              size="small"
              fullWidth
            />
          ),
        },
      ]}
    />
  );
}

export default SettlementOfficeInfo;
