import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_COMMON_QUERY";

/**
 * 파트너 관리 > 등록, 회사정보 수정 모달에서 사용
 * @param filterList는 ["KR", "JP"]와 같은 국가 약어 배열
 */
export default function useGetCountryList({
  filterList,
}: {
  filterList?: string[];
}) {
  const { data: countryData } = ADMIN_COMMON_QUERY.useGetCountryList();

  const filteredCountryList = countryData?.filter(
    (v) => !filterList?.includes(v.name)
  );

  /**
   * filterList를 인자로 받을 경우 선택된 국가값을 제외한 국가 리스트만 노출
   * 선택 해제시 리스트에 추가됨
   */
  const countryList = (() => {
    const countryListToOption =
      (filterList?.length ? filteredCountryList : countryData)?.map(
        ({ nameKR }) => ({
          label: nameKR,
          value: nameKR,
        })
      ) ?? [];

    return [{ label: "전체", value: "ALL" }, ...countryListToOption];
  })();

  return {
    countryList,
  };
}
