import { useState } from "react";

import { Country } from "@sellernote/_shared/src/types/common/common";

/**
 * @deprecated
 *
 * TODO: 신규 파트너 관리로 migration 마치면 삭제
 */
const RegisterPartner = ({
  countryData,
  handlePartnerListRefetch,
}: {
  countryData: Country[];
  handlePartnerListRefetch: () => void;
}) => {
  const [showsRegisterForm, setShowsRegisterForm] = useState(false);

  return null;

  // if (!showsRegisterForm)
  //   return (
  //     <Box sx={{ mb: 5, mt: 2 }}>
  //       <Button variant="contained" onClick={() => setShowsRegisterForm(true)}>
  //         등록하기
  //       </Button>
  //     </Box>
  //   );

  // return (
  //   <RegisterPartnerForm
  //     countryData={countryData}
  //     setShowsRegisterForm={setShowsRegisterForm}
  //     handlePartnerListRefetch={handlePartnerListRefetch}
  //   />
  // );
};

export default RegisterPartner;
