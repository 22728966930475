import { Country } from "@sellernote/_shared/src/types/common/common";

/**
 * 파트너 관리, 발주 관리, 운송 의뢰에서 파트너 리스트 > 담당 국가 노출시 사용
 */
function renderCountryInCharge({
  countryDataList,
  countryInChargeOfPartner,
}: {
  countryDataList: Country[];
  countryInChargeOfPartner: string[];
}) {
  if (countryInChargeOfPartner.includes("ALL")) return "전체";

  const countryListOfPartner = countryInChargeOfPartner.map((country) => {
    if (country === "KV") return "코로보";

    const found = countryDataList?.find((item) => item.name === country);

    return found ? found.nameKR : "-";
  });

  /** 최대 3개 국가까지 보여줌 */
  const maxDisplay = 3;
  const totalCountries = countryListOfPartner.length;

  if (totalCountries <= maxDisplay) return countryListOfPartner.join(", ");

  const displayedCountry = countryListOfPartner.slice(0, maxDisplay).join(", ");

  const remainingCountries = totalCountries - maxDisplay;

  return `${displayedCountry} 외 ${remainingCountries}개국`;
}

export { renderCountryInCharge };
