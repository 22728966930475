import { useState } from "react";

import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_FILE_QUERY";

export default function useHandleFilePreview({
  onFilePreviewShow,
}: {
  onFilePreviewShow: () => void;
}) {
  /** 파일 정보 */
  const [file, setFile] = useState({
    id: -1,
    s3: "",
    name: "",
    extension: "",
  });

  const { mutate: getFileDownloadUrl } =
    ADMIN_FILE_QUERY.useGetFileDownloadUrl();

  const handleFilePreviewOpen = ({
    downloadKey,
    fileName,
    extension,
    id,
  }: {
    downloadKey: string;
    fileName: string;
    extension: string;
    id: number;
  }) => {
    return () => {
      if (!downloadKey) return;

      getFileDownloadUrl(
        {
          pathParams: {
            key: downloadKey,
          },
        },
        {
          onSuccess: ({ data }) => {
            setFile({
              id,
              s3: data.url,
              name: fileName,
              extension,
            });

            onFilePreviewShow();
          },
        }
      );
    };
  };

  return {
    file,
    handleFilePreviewOpen,
  };
}
