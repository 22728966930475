import { createContext, ReactNode, useContext, useState } from "react";

import { SettlementSalesListData } from "@sellernote/_shared/src/types/forwarding/adminSettlement";

interface SalesSettlementContextType {
  filteredSettlementList: SettlementSalesListData[] | undefined | null;
  setFilteredSettlementListData: (
    data: SettlementSalesListData[] | undefined | null
  ) => void;
  tabDisabledData: number[] | undefined | null;
  setTabDisabledData: (value: number[]) => void;
}

const SalesSettlementContext = createContext<
  SalesSettlementContextType | undefined
>(undefined);

export function useSalesSettlementContext() {
  const context = useContext(SalesSettlementContext);

  if (context === undefined) {
    throw new Error(
      "useSalesSettlementContext must be used within a SalesSettlementProvider"
    );
  }
  return context;
}

export function SalesSettlementProvider({ children }: { children: ReactNode }) {
  const [filteredSettlementList, setFilteredSettlementList] = useState<
    SettlementSalesListData[] | undefined | null
  >(null);

  const [tabDisabledData, setTabDisabledData] = useState<
    number[] | undefined | null
  >([]);

  const setFilteredSettlementListData = (
    data: SettlementSalesListData[] | undefined | null
  ) => {
    setFilteredSettlementList(data);
  };

  return (
    <SalesSettlementContext.Provider
      value={{
        filteredSettlementList,
        setFilteredSettlementListData,
        tabDisabledData,
        setTabDisabledData,
      }}
    >
      {children}
    </SalesSettlementContext.Provider>
  );
}
