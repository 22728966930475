import { useMemo, useState } from "react";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { IconButton, Tooltip } from "@mui/material";
import { useRecoilState } from "recoil";
import { SHIPDA_ADMIN_FILTER_ATOMS } from "states/filters";

import { GET_OPERATION_LIST_FILTER } from "@sellernote/_shared/src/api-interfaces/shipda-api/businessManagement";
import {
  ADMIN_OPERATION_CARGO_TYPE_OPTION_LIST,
  ADMIN_OPERATION_INCOTERMS_OPTION_LIST,
  ADMIN_OPERATION_PAYMENT_OPTION_LIST,
} from "@sellernote/_shared/src/constants/forwarding/adminBusinessManagement";
import { Incoterms } from "@sellernote/_shared/src/types/common/common";
import { FreightPaymentType } from "@sellernote/_shared/src/types/forwarding/adminBid";
import {
  BidProjectStatus,
  BidStatus,
  CargoTransportType,
} from "@sellernote/_shared/src/types/forwarding/bid";
import {
  BID_PROJECT_STATUS_OPTION_LIST_FOR_CHECKBOX,
  BID_STATUS_OPTION_LIST_FOR_CHECKBOX,
} from "@sellernote/_shared/src/utils/common/options";

import useClosingFilter from "./useClosingFilter";
import useCommonFilter from "./useCommonFilter";
import useServiceTypeFilter from "./useServiceTypeFilter";

export default function useTableHeadPanel() {
  const [filterData, setFilterData] = useRecoilState(
    SHIPDA_ADMIN_FILTER_ATOMS.ADMIN_OPERATION_FILTER_LIST
  );

  const [headFilterData, setHeadFilterData] =
    useState<GET_OPERATION_LIST_FILTER | null>(filterData ?? null);

  const {
    HeadFilter: IncotermsFilter,
    headFilterData: incotermsFilterData,
    handleReset: incotermsReset,
  } = useCommonFilter<GET_OPERATION_LIST_FILTER, Incoterms>({
    headFilterData,
    setHeadFilterData,
    submitFilterKey: "incoterms",
    optionList: ADMIN_OPERATION_INCOTERMS_OPTION_LIST,
  });

  const {
    HeadFilter: PaymentFilter,
    headFilterData: paymentFilterData,
    handleReset: paymentReset,
  } = useCommonFilter<GET_OPERATION_LIST_FILTER, FreightPaymentType | "none">({
    headFilterData,
    setHeadFilterData,
    submitFilterKey: "payment",
    optionList: ADMIN_OPERATION_PAYMENT_OPTION_LIST,
  });

  const {
    HeadFilter: CargoTypeFilter,
    headFilterData: cargoTypeFilterData,
    handleReset: cargoTypeReset,
  } = useCommonFilter<GET_OPERATION_LIST_FILTER, CargoTransportType>({
    headFilterData,
    setHeadFilterData,
    submitFilterKey: "cargoType",
    optionList: ADMIN_OPERATION_CARGO_TYPE_OPTION_LIST,
  });

  const {
    HeadFilter: BidStatusFilter,
    headFilterData: bidStatusFilterData,
    handleReset: bidStatusReset,
  } = useCommonFilter<GET_OPERATION_LIST_FILTER, BidStatus>({
    headFilterData,
    setHeadFilterData,
    submitFilterKey: "bidStatus",
    size: 200,
    optionList: BID_STATUS_OPTION_LIST_FOR_CHECKBOX,
  });

  const {
    HeadFilter: BidProjectStatusFilter,
    headFilterData: bidProjectStatusFilterData,
    handleReset: bidProjectStatusReset,
  } = useCommonFilter<GET_OPERATION_LIST_FILTER, BidProjectStatus>({
    headFilterData,
    setHeadFilterData,
    submitFilterKey: "bidProjectStatus",
    size: 200,
    optionList: BID_PROJECT_STATUS_OPTION_LIST_FOR_CHECKBOX,
  });

  const {
    ServiceTypeFilter,
    headFilterData: serviceType,
    requestedHeadFilterData: serviceTypeFilterData,
    handleReset: serviceTypeReset,
  } = useServiceTypeFilter(filterData);

  const {
    ClosingFilter,
    headFilterData: closingFilterData,
    handleReset: closingReset,
  } = useClosingFilter(filterData);

  const tableHeadFilterData = useMemo(() => {
    return {
      ...incotermsFilterData,
      ...cargoTypeFilterData,
      ...paymentFilterData,
      ...serviceTypeFilterData,
      ...closingFilterData,
      ...bidStatusFilterData,
      ...bidProjectStatusFilterData,
    };
  }, [
    incotermsFilterData,
    cargoTypeFilterData,
    paymentFilterData,
    serviceTypeFilterData,
    closingFilterData,
    bidStatusFilterData,
    bidProjectStatusFilterData,
  ]);

  const AllResetButton = useMemo(() => {
    return (
      <Tooltip title="테이블 헤드 필터 전체 리셋">
        <IconButton
          onClick={() => {
            incotermsReset();
            cargoTypeReset();
            paymentReset();
            serviceTypeReset();
            closingReset();
            bidStatusReset();
            bidProjectStatusReset();
          }}
        >
          <RestartAltIcon />
        </IconButton>
      </Tooltip>
    );
  }, [
    incotermsReset,
    paymentReset,
    cargoTypeReset,
    serviceTypeReset,
    closingReset,
    bidProjectStatusReset,
    bidStatusReset,
  ]);

  const TableHeadPanel = {
    IncotermsFilter: IncotermsFilter,
    PaymentFilter: PaymentFilter,
    CargoTypeFilter: CargoTypeFilter,
    ServiceTypeFilter: ServiceTypeFilter,
    ClosingFilter: ClosingFilter,
    BidStatusFilter: BidStatusFilter,
    BidProjectStatusFilter: BidProjectStatusFilter,
    AllResetButton: AllResetButton,
  };

  return {
    tableHeadFilterData,
    TableHeadPanel,
    AllResetButton,
  };
}
