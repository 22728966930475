import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";

import PurchaseManagementTable from "./PurchaseManagementTable";

const PurchaseManagement = () => {
  return (
    <Layout title={"매입 관리"}>
      <PurchaseManagementTable />
    </Layout>
  );
};

export default withRequireAuth(PurchaseManagement);
