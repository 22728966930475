import { Box, Typography } from "@mui/material";
import { red } from "@mui/material/colors";

import { InvoiceData } from "@sellernote/_shared/src/types/forwarding/tradingStatement";

function TemporaryWarningAndSendDate({
  invoiceData,
}: {
  invoiceData: InvoiceData | undefined;
}) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={invoiceData?.isTemporary ? "space-between" : "flex-end"}
    >
      {invoiceData?.isTemporary && (
        <Typography sx={{ color: red[600] }} variant="body2" component="div">
          거래명세서가 임시저장된 상태입니다.
        </Typography>
      )}

      {invoiceData?.sendDate && (
        <Box>
          <Typography variant="body2" component="div">
            고객사 발송일: {invoiceData.sendDate}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default TemporaryWarningAndSendDate;
