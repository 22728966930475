import { Control, useFieldArray } from "react-hook-form";
import { Button } from "@mui/material";

import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import TextFieldWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/TextFieldWithReactHookForm";

function ContainersNumberTextField({
  control,
}: {
  control: Control<ApplyBidFormData>;
}) {
  const { remove, append, fields } = useFieldArray({
    control,
    name: "containersNumberInfo",
  });

  const handleContainersItemAdd = () => {
    if (fields) {
      append({
        containersNumber: "",
      });

      return;
    }

    return;
  };

  return (
    <>
      {fields.map((v, index) => {
        return (
          <>
            <TextFieldWithReactHookForm
              control={control}
              name={`containersNumberInfo.${index}.containersNumber`}
              label="CNTR NO."
              variant="outlined"
              size="small"
            />

            <Button color="error" onClick={() => remove(index)}>
              삭제
            </Button>
          </>
        );
      })}

      <Button onClick={handleContainersItemAdd}>추가</Button>
    </>
  );
}

export default ContainersNumberTextField;
