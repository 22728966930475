import { useState } from "react";
import { BackTop, Card } from "antd";

import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_COMMON_QUERY";
import PARTNER_QUERY from "@sellernote/_shared/src/queries/forwarding/PARTNER_QUERY";
import { SellernoteAppRegion } from "@sellernote/_shared/src/types/common/common";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";

import Loading from "components/Loading";

import PartnerListTable from "../PartnerListTable";
import RegisterPartner from "../RegisterPartner";
import OperatorPartnerListTableFilter from "./OperatorPartnerListTableFilter";

const OperatorPartnerList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [businessArea, setBusinessArea] = useState(undefined);
  const [isAutoFetch, setIsAutoFetch] = useState(true);
  const [searchValue, setSearchValue] = useState<{
    companyName: string | undefined;
    BRNNumber: string | undefined;
    accountNumber: string | undefined;
  }>({
    companyName: undefined,
    BRNNumber: undefined,
    accountNumber: undefined,
  });
  const [searchType, setSearchType] = useState("company");
  const [region, setRegion] = useState<SellernoteAppRegion>("KR");

  const { data, refetch: handlePartnerListRefetch } =
    PARTNER_QUERY.useGetPartnerList({
      page: currentPage - 1,
      perPage: 25,
      transportMode: "ALL",
      businessArea,
      ...searchValue,
      enabled: isAutoFetch,
      operatorFlag: true,
      region,
    });

  const { data: countryData } = ADMIN_COMMON_QUERY.useGetCountryList();

  if (data === undefined || countryData === undefined) {
    return (
      <Layout>
        <Loading />
      </Layout>
    );
  }
  return (
    <Layout breadcrumbs={["운영 파트너 관리"]} title={"파트너 관리"}>
      <Card>
        <BackTop />
        <RegisterPartner
          countryData={countryData}
          handlePartnerListRefetch={handlePartnerListRefetch}
        />

        <OperatorPartnerListTableFilter
          setBusinessArea={setBusinessArea}
          businessArea={businessArea}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          searchType={searchType}
          setSearchType={setSearchType}
          isAutoFetch={isAutoFetch}
          setIsAutoFetch={setIsAutoFetch}
          region={region}
          setRegion={setRegion}
        />

        <PartnerListTable
          currentPage={currentPage}
          countryData={countryData}
          partnerList={data?.list}
          setCurrentPage={setCurrentPage}
          total={data?.total}
        />
      </Card>
    </Layout>
  );
};

export default withRequireAuth(OperatorPartnerList);
