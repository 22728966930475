import { useMemo } from "react";
import { Autocomplete, TextField } from "@mui/material";

import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_COMMON_QUERY";
import { Country } from "@sellernote/_shared/src/types/common/common";

import useGetCountryList from "../hooks/useGetCountryList";

export default function CountryInCharge({
  countryInChargeList,
  onCountryInChargeEdit,
  isCountryInChargeContainsAll,
}: {
  countryInChargeList: string[];
  onCountryInChargeEdit: (val: string[]) => void;
  isCountryInChargeContainsAll: boolean;
}) {
  const { data: countryData } = ADMIN_COMMON_QUERY.useGetCountryList();

  const { countryList } = useGetCountryList({
    filterList: countryInChargeList,
  });

  /**
   * 파트너 정보의 담당 국가 데이터
   * Autocomplete에 사용하는 defaultValue 값으로 포맷을 변경
   */
  const countryInChargeOfPartner = useMemo(() => {
    if (countryInChargeList.includes("ALL")) {
      return [{ label: "전체", value: "전체" }];
    }

    const filteredPartnerCountry = countryInChargeList.map((v) => {
      return countryData?.find((country) => country.name === v);
    });

    return filteredPartnerCountry.map((v: Country | undefined) => {
      if (!v) return { label: "", value: "" };

      return { label: v.nameKR, value: v.nameKR };
    });
  }, [countryData, countryInChargeList]);

  const handleCountryInChargeEdit = (selectedCountry: { value: string }[]) => {
    /** 선택된 국가들을 countryData에서 추출한 뒤, ["KR", "CN"]와 같은 형태로 변경 */
    const countryCode: string[] = selectedCountry.map(({ value }) => {
      const targetCountryName = countryData?.find((v: Country) => {
        return v.nameKR === value;
      });

      if (value === "ALL") return "ALL";

      if (!targetCountryName) return "";

      return targetCountryName.name;
    });

    onCountryInChargeEdit(countryCode);
  };

  return (
    <Autocomplete
      multiple
      size="small"
      options={isCountryInChargeContainsAll ? [] : countryList}
      defaultValue={countryInChargeOfPartner}
      getOptionLabel={({ label }) => label}
      filterSelectedOptions
      renderInput={(params) => <TextField {...params} />}
      onChange={(_, selectedCountry) => {
        handleCountryInChargeEdit(selectedCountry);
      }}
    />
  );
}
