import { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import { DepositV2ListItem } from "@sellernote/_shared/src/types/forwarding/adminSettlement";
import { InvoiceResult } from "@sellernote/_shared/src/types/forwarding/trello";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import {
  getInvoiceMatchedStatus,
  getInvoiceResultColor,
} from "@sellernote/_shared/src/utils/forwarding/adminSettlement";

import MatchedInvoiceModal from "./MatchedInvoiceModal";

function MatchAmountPerInvoice({
  depositV2ListItem,
  handleMatchBidIdModalOpen,
}: {
  depositV2ListItem: DepositV2ListItem;
  handleMatchBidIdModalOpen: (depositListItem: DepositV2ListItem) => void;
}) {
  const [showsMatchedInvoiceModal, setShowsMatchedInvoiceModal] =
    useState(false);
  const [invoiceId, setInvoiceId] = useState(0);
  const [shipmentId, setShipmentId] = useState(0);

  const totalMatchedAmount = depositV2ListItem.invoices.reduce((acc, cur) => {
    acc += cur.matchedAmount;

    return acc;
  }, 0);

  const getRemainingAmount = ({
    invoiceResult,
    remainingAmount,
  }: {
    invoiceResult: InvoiceResult | null;
    remainingAmount: number;
  }) => {
    if (invoiceResult === "unpaid") {
      return (
        <Typography color="red" variant="body2">
          {`(${toThousandUnitFormat(remainingAmount)}원)`}
        </Typography>
      );
    }

    return null;
  };

  return (
    <Grid container flexDirection={"column"} spacing={1}>
      {depositV2ListItem.invoices.map((invoice) => {
        return (
          <Grid
            container
            item
            key={invoice.invoiceId}
            alignItems={"center"}
            spacing={1}
          >
            <Grid item container xs={2} justifyContent={"flex-end"}>
              <Grid item>
                <Typography
                  sx={{ cursor: "pointer" }}
                  variant="body2"
                  color="primary"
                  onClick={() => {
                    setInvoiceId(invoice.invoiceId);
                    setShipmentId(invoice.shipmentId);
                    setShowsMatchedInvoiceModal(true);
                  }}
                >
                  {invoice.shipmentId}
                </Typography>
              </Grid>
            </Grid>

            <Grid item container xs={3} justifyContent={"flex-end"}>
              <Grid item>
                <Typography
                  variant="body2"
                  color={
                    invoice.invoiceResult === "unpaid"
                      ? `${grey[500]}`
                      : undefined
                  }
                >
                  {`${toThousandUnitFormat(invoice.matchedAmount)}원`}
                </Typography>
              </Grid>
            </Grid>

            <Grid item>
              <Typography
                variant="body2"
                color={getInvoiceResultColor(invoice.invoiceResult)}
              >
                {getInvoiceMatchedStatus(invoice.invoiceResult)}
              </Typography>
            </Grid>

            <Grid item>
              {getRemainingAmount({
                invoiceResult: invoice.invoiceResult,
                remainingAmount: invoice.remainingAmount,
              })}
            </Grid>
          </Grid>
        );
      })}

      <Grid item container spacing={1}>
        <Grid item container xs={2} justifyContent={"flex-end"}>
          <Grid item>
            <Typography variant="body2">총</Typography>
          </Grid>
        </Grid>

        <Grid item container xs={3} justifyContent={"flex-end"}>
          <Grid item>
            <Typography variant="body2">{`${toThousandUnitFormat(
              totalMatchedAmount
            )}원`}</Typography>
          </Grid>
        </Grid>

        <Grid item>
          <Typography variant="body2">매칭</Typography>
        </Grid>
      </Grid>

      <Grid item>
        <Typography
          onClick={() => handleMatchBidIdModalOpen(depositV2ListItem)}
          sx={{ pl: 2, cursor: "pointer" }}
          variant="body2"
          color="primary"
        >
          수정
        </Typography>
      </Grid>

      {showsMatchedInvoiceModal && (
        <MatchedInvoiceModal
          showsMatchedInvoiceModal={showsMatchedInvoiceModal}
          setShowsMatchedInvoiceModal={setShowsMatchedInvoiceModal}
          invoiceId={invoiceId}
          shipmentId={shipmentId}
        />
      )}
    </Grid>
  );
}

export default MatchAmountPerInvoice;
