import { useMemo, useState } from "react";
import { Typography } from "@mui/material";

import { GET_ADMIN_INLAND_FARES_REQ_SEARCH_KIND } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminInalndFares";
import ADMIN_INLAND_FARES_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_INLAND_FARES_QUERY";
import { InlandAirFares } from "@sellernote/_shared/src/types/forwarding/adminInlandFares";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import LegacyTable, {
  LegacyTableBodyRow,
  LegacyTableHeadCell,
} from "@sellernote/_shared-for-forwarding-admin/src/components/LegacyTable";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";
import useSearchWithTerm, {
  TermSearchType,
} from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSearchWithTerm";

type CellId = keyof InlandAirFares | "startPort" | "endAddress";

const termSearchTypeOptions: TermSearchType<GET_ADMIN_INLAND_FARES_REQ_SEARCH_KIND>[] =
  [
    {
      label: "주소",
      value: "address",
    },
    {
      label: "zoneId",
      value: "zoneId",
    },
  ];

const InlandFareAirList = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(25);

  const { debouncedSearchTerm, termSearchType, TermSearchPanel } =
    useSearchWithTerm({
      termSearchTypeOptions,
    });

  //TODO: 이관 브랜치에서 따로 작업한 전용 hooks가 있음 나중에 병합 시 수정
  const termSearchTypeKeyObject = useMemo(() => {
    if (termSearchType?.value && debouncedSearchTerm) {
      const searchObject: { [key: string]: unknown } = {};
      searchObject[termSearchType?.value] = debouncedSearchTerm;
      return searchObject;
    }
    return undefined;
  }, [debouncedSearchTerm, termSearchType?.value]);

  const { data: inlandFareList } =
    ADMIN_INLAND_FARES_QUERY.useGetAdminInlandAirFares({
      page: 0,
      perPage: 25,
      ...termSearchTypeKeyObject,
    });

  const headCells: LegacyTableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "id",
        disablePadding: false,
        label: "번호",
        width: 100,
      },
      {
        id: "startPort",
        label: "출발지",
        disablePadding: false,
        width: 100,
      },
      {
        id: "endAddress",
        disablePadding: false,
        label: "도착지",
        width: 200,
      },
      {
        label: "1톤차(M:1.1t)",
        id: "section1",
        disablePadding: false,
        width: 100,
      },
      {
        label: "1.4톤차(M1.54t)",
        id: "section2",
        disablePadding: false,
        width: 100,
      },
      {
        label: "2.5톤차(M2.75t)",
        id: "section3",
        disablePadding: false,
        width: 100,
      },
      {
        label: "3.5톤차(M3.85t)",
        id: "section4",
        disablePadding: false,
        width: 100,
      },
      {
        label: "5톤차(M5.5t)",
        id: "section5",
        disablePadding: false,
        width: 100,
      },
      {
        label: "5톤축(M5.5t)",
        id: "section6",
        disablePadding: false,
        width: 100,
      },
      {
        label: "11t톤차(M12.1t)",
        id: "section7",
        disablePadding: false,
        width: 100,
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!inlandFareList?.list) return [];

    return inlandFareList?.list.map((inlandFareListItem) => {
      const row: LegacyTableBodyRow<CellId> = {
        id: inlandFareListItem.id,
        startPort: inlandFareListItem.port.name,
        endAddress: inlandFareListItem.zone.name,
        section1: toThousandUnitFormat(inlandFareListItem.section1),
        section2: toThousandUnitFormat(inlandFareListItem.section2),
        section3: toThousandUnitFormat(inlandFareListItem.section3),
        section4: toThousandUnitFormat(inlandFareListItem.section4),
        section5: toThousandUnitFormat(inlandFareListItem.section5),
        section6: toThousandUnitFormat(inlandFareListItem.section6),
        section7: toThousandUnitFormat(inlandFareListItem.section7),
      };
      return row;
    });
  }, [inlandFareList?.list]);

  return (
    <Layout breadcrumbs={["내륙요금관리"]}>
      <LegacyTable
        toolbarItems={{
          left: [TermSearchPanel],
          right: [
            <Typography
              key="text"
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              t = ton, M = Max, 최대
            </Typography>,
          ],
        }}
        title="AIR 내륙요금 리스트"
        headCells={headCells}
        rows={rows}
        pagination={{
          totalCount: inlandFareList?.total || 0,
          perPage,
          setPerPage,
          currentPage,
          setCurrentPage,
        }}
      />
    </Layout>
  );
};

export default withRequireAuth(InlandFareAirList);
