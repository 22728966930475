import { useQueryClient } from "react-query";
import { Modal } from "antd";

import PARTNER_QUERY from "@sellernote/_shared/src/queries/forwarding/PARTNER_QUERY";

const DeletePartnerModal = ({
  showDeletePartnerModal,
  companyId,
  afterClose,
}: {
  showDeletePartnerModal: boolean;
  companyId: number;
  afterClose: () => void;
}) => {
  const queryClient = useQueryClient();

  const {
    mutate: deletePartner,
    ResponseHandler: ResponseHandlerOfDeletePartner,
  } = PARTNER_QUERY.useDeletePartner({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        afterClose();
        queryClient.invalidateQueries(["forwarding", "partner", "list"]);
      },
      customizeMessage: () => ({
        title: "삭제되었습니다.",
      }),
    },
    companyId,
  });

  return (
    <Modal
      title="삭제 하시겠습니까?"
      okButtonProps={{ danger: true }}
      okText="삭제"
      cancelText="취소"
      visible={showDeletePartnerModal}
      onCancel={() => {
        afterClose();
      }}
      onOk={() => deletePartner({})}
    >
      {ResponseHandlerOfDeletePartner}
    </Modal>
  );
};

export default DeletePartnerModal;
