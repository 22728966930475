import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";

import { ForwardingPurchaseAttachment } from "@sellernote/_shared/src/types/common/file";

import useHandleFilePreview from "./hooks";

import Preview from "./Preview";

export default function PreviewModalBody({
  selectedAttachmentList,
}: {
  selectedAttachmentList: ForwardingPurchaseAttachment[];
}) {
  const [showsFilePreview, setShowsFilePreview] = useState(false);

  const handleFilePreviewShow = () => setShowsFilePreview(true);

  const {
    file: { id, name, s3, extension },
    handleFilePreviewOpen,
  } = useHandleFilePreview({
    onFilePreviewShow: handleFilePreviewShow,
  });

  const fileButton = selectedAttachmentList.map(({ key, name, id }, i) => {
    return (
      <>
        <Button
          key={id}
          size="small"
          sx={{ mt: 2 }}
          onClick={handleFilePreviewOpen({
            downloadKey: key,
            fileName: name,
            extension: name.substring(name.lastIndexOf(".") + 1),
            id,
          })}
        >
          {i + 1}. {name}
        </Button>
      </>
    );
  });

  return (
    <Box>
      <Typography variant="h6" style={{ textAlign: "center" }}>
        파일 리스트
      </Typography>

      <Box sx={{ display: "flex", gap: 3 }}>
        <Box
          sx={{
            width: 500,
            display: "flex",
            flexDirection: "column",
            mt: 1,
          }}
        >
          {fileButton}
        </Box>

        {showsFilePreview && id && (
          <Preview
            showsFilePreview={showsFilePreview}
            setShowsFilePreview={setShowsFilePreview}
            s3={s3}
            extension={extension}
            name={name}
            id={id}
          />
        )}
      </Box>
    </Box>
  );
}
