import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";

import SalesSettlementTable from "./SalesSettlementTable";
import { SalesSettlementProvider } from "./useSalesSettlementContext";

function SalesManagement() {
  return (
    <Layout title="매출 관리">
      <SalesSettlementProvider>
        <SalesSettlementTable />
      </SalesSettlementProvider>
    </Layout>
  );
}

export default withRequireAuth(SalesManagement);
