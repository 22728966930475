import { List, ListItem } from "@mui/material";

const ContainerList = ({ dataList }: { dataList: string[] }) => {
  return (
    <List>
      {dataList.map((item) => (
        <ListItem key={item}>{item}</ListItem>
      ))}
    </List>
  );
};

export default ContainerList;
