import { useCallback } from "react";

import useSessionStorage from "@sellernote/_shared/src/hooks/common/useSessionStorage";

function useSessionInvoiceId() {
  const [sessionInvoiceId, setSessionInvoiceId] = useSessionStorage<
    string | null
  >("invoiceId");

  const handleSessionInvoiceIdChange = useCallback(
    (invoiceId: string) => {
      setSessionInvoiceId(invoiceId);
    },
    [setSessionInvoiceId]
  );

  return {
    sessionInvoiceId,
    handleSessionInvoiceIdChange,
  };
}

export default useSessionInvoiceId;
