import { useState } from "react";

import { FileTypes } from "@sellernote/_shared/src/types/common/upload";
import { AccountDetailFile } from "@sellernote/_shared/src/types/forwarding/adminAccount";

export default function useHandleFileUpload() {
  const [opensUploadModal, setOpensUploadModal] = useState(false);
  /** 사업자등록증 파일 */
  const [BRNFileList, setBRNFileList] = useState<FileTypes[]>([]);
  /** 통장사본 파일 */
  const [bankBookCopyfileList, setBankBookCopyFileList] = useState<FileTypes[]>(
    []
  );
  /** 파일 업로드에 사용되는 파일 도메인(파일 타입) */
  const [fileDomain, setFileDomain] = useState<AccountDetailFile["domain"]>(
    "financialAccountBRN"
  );
  /** 신규 거래처 생성(파트너 등록시 BRN 직접 기입)시 사용하는 임의 id */
  const [timeStamp] = useState(() => Math.floor(Date.now() / 1000));

  const handleFileUploadModalOpen = (domain: AccountDetailFile["domain"]) => {
    setFileDomain(domain);
    setOpensUploadModal(true);
  };

  const handleFileListReset = () => {
    setBRNFileList([]);
    setBankBookCopyFileList([]);
  };

  const validateFile = () => {
    return BRNFileList.length > 0 && bankBookCopyfileList.length > 0;
  };

  return {
    opensUploadModal,
    setOpensUploadModal,

    BRNFileList,
    setBRNFileList,

    bankBookCopyfileList,
    setBankBookCopyFileList,

    fileDomain,
    timeStamp,

    handleFileUploadModalOpen,
    handleFileListReset,
    validateFile,
  };
}
