import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";

import DepositManagementTable from "./DepositManagementTable";

function DepositManagement() {
  return (
    <Layout>
      <DepositManagementTable />
    </Layout>
  );
}

export default DepositManagement;
