export const TRANSPORT_MODE_LIST = [
  {
    label: "ALL",
    value: "ALL",
  },
  {
    label: "FCL",
    value: "FCL",
  },
  {
    label: "LCL",
    value: "LCL",
  },
  {
    label: "AIR",
    value: "AIR",
  },
  {
    label: "EXPRESS",
    value: "EXPRESS",
  },
];
