import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useAtomValue } from "jotai";

import { ISSUE_AUTO_INVOICE_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminSettlement";
import ADMIN_SETTLEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_SETTLEMENT_QUERY";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";
import { FORWARDING_ADMIN_AUTH_SELECTORS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/auth";

import { useSalesSettlementContext } from "../../useSalesSettlementContext";
import AutoInvoiceIssueModal from "./AutoInvoiceIssueModal";

function IssueInvoiceModal({
  issueInvoiceModalVisible,
  setIssueInvoiceModalVisible,
  checkIds,
  refetchAdminSettlementSalesList,
  initCheckBoxSet,
  invoiceEmail,
  isOneRecipient,
}: {
  issueInvoiceModalVisible: boolean;
  setIssueInvoiceModalVisible: Dispatch<SetStateAction<boolean>>;
  checkIds: number[];
  refetchAdminSettlementSalesList: () => void;
  initCheckBoxSet: (values?: number[] | undefined) => void;
  invoiceEmail: string;
  isOneRecipient: boolean;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const { setTabDisabledData, tabDisabledData } = useSalesSettlementContext();

  const [opensAutoInvoiceIssueModal, setOpensAutoInvoiceIssueModal] =
    useState(false);

  const {
    mutate: issueManualInvoice,
    ResponseHandler: ResponseHandlerOfIssueManualInvoice,
  } = ADMIN_SETTLEMENT_QUERY.useIssueManualInvoice();

  const { mutate: issueAutoInvoice, isLoading: issueAutoInvoiceLoading } =
    ADMIN_SETTLEMENT_QUERY.useIssueAutoInvoice();

  const handleManualInvoiceIssue = useCallback(() => {
    if (currentAdminAuthInfo?.authority === "manager") {
      handleSnackbarOpen("권한이 없습니다.", "error");
      return;
    }

    const checkIdsToStringArr = checkIds.map((v) => {
      return v.toString();
    });
    issueManualInvoice(
      {
        issuedInvoiceIds: checkIdsToStringArr,
        isIssued: true,
        isDel: false,
      },
      {
        onSuccess: () => {
          handleSnackbarOpen("요청에 성공했습니다.");
          setIssueInvoiceModalVisible(false);
          refetchAdminSettlementSalesList();
        },
      }
    );
  }, [
    currentAdminAuthInfo?.authority,
    checkIds,
    issueManualInvoice,
    handleSnackbarOpen,
    setIssueInvoiceModalVisible,
    refetchAdminSettlementSalesList,
  ]);

  const handleAutoInvoiceIssueModalOpen = useCallback(() => {
    setOpensAutoInvoiceIssueModal(true);
  }, []);

  const handleAutoInvoiceIssue = useCallback(
    ({
      invoiceId,
      writeDate,
      invoiceeEmail,
      remark,
    }: ISSUE_AUTO_INVOICE_REQ) => {
      issueAutoInvoice(
        {
          invoiceId,
          writeDate,
          invoiceeEmail,
          remark,
        },
        {
          onSuccess: () => {
            handleSnackbarOpen("요청에 성공했습니다.");
            if (tabDisabledData) {
              setTabDisabledData([...tabDisabledData, invoiceId]);
            }
          },

          onError: ({ response }) => {
            if (response?.data?.errorCode === "E170") {
              handleSnackbarOpen(
                "고객사 이메일과 세금계산서 이메일이 없습니다.",
                "error"
              );
              return;
            }

            if (response?.data?.errorCode === "E171") {
              handleSnackbarOpen("세금계산서 화주명이 없습니다.", "error");
              return;
            }

            if (response?.data?.errorCode === "E172") {
              handleSnackbarOpen(
                "세금계산서 BRN이 유효하지 않습니다.",
                "error"
              );
              return;
            }

            if (response?.data?.errorCode === "E173") {
              handleSnackbarOpen("세금계산서가 이미 발행되었습니다.", "error");
              return;
            }

            if (response?.data?.errorCode === "E174") {
              handleSnackbarOpen("세금계산서 대표자 성명이 없습니다.", "error");
              return;
            }

            if (response?.data?.errorCode === "E175") {
              handleSnackbarOpen(
                "세금계산서 업종 혹은 업태가 없습니다.",
                "error"
              );
              return;
            }

            if (response?.data?.errorCode === "E176") {
              handleSnackbarOpen("세금계산서 사업장 주소가 없습니다.", "error");
              return;
            }

            if (response?.data?.errorCode === "E177") {
              handleSnackbarOpen(
                "세금계산서가 이미 국세청으로 전송되어 취소할 수 없습니다.",
                "error"
              );
              return;
            }

            if (response?.data?.errorCode === "E178") {
              handleSnackbarOpen(
                "세금계산서가 문서번호를 찾을 수 없습니다.",
                "error"
              );
              return;
            }

            if (response?.data?.errorCode === "E179") {
              handleSnackbarOpen(
                "세금계산서가 이미 취소됐거나 발행된 적이 없습니다.",
                "error"
              );
              return;
            }

            handleSnackbarOpen("요청에 실패했습니다.", "error");
          },
        }
      );
    },
    [issueAutoInvoice, handleSnackbarOpen, tabDisabledData, setTabDisabledData]
  );

  const handleModalClose = useCallback(() => {
    setIssueInvoiceModalVisible(false);
  }, [setIssueInvoiceModalVisible]);

  const ModalBody = useMemo(() => {
    return (
      <>
        <Typography variant="h6" component="div">
          발행 방법을 선택해주세요.
        </Typography>

        <Grid container spacing={2} sx={{ marginTop: 1, maxWidth: 400 }}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAutoInvoiceIssueModalOpen}
            >
              자동발행
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button
              variant="contained"
              color="success"
              onClick={handleManualInvoiceIssue}
            >
              수동발행
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }, [handleAutoInvoiceIssueModalOpen, handleManualInvoiceIssue]);

  return (
    <>
      <Modal
        isOpened={issueInvoiceModalVisible}
        handleClose={handleModalClose}
        modalBody={ModalBody}
      />

      {opensAutoInvoiceIssueModal && (
        <AutoInvoiceIssueModal
          handleAutoInvoiceIssue={handleAutoInvoiceIssue}
          opensAutoInvoiceIssueModal={opensAutoInvoiceIssueModal}
          setOpensAutoInvoiceIssueModal={setOpensAutoInvoiceIssueModal}
          refetchAdminSettlementSalesList={refetchAdminSettlementSalesList}
          setIssueInvoiceModalVisible={setIssueInvoiceModalVisible}
          issueAutoInvoiceLoading={issueAutoInvoiceLoading}
        />
      )}

      {ResponseHandlerOfIssueManualInvoice}
    </>
  );
}

export default IssueInvoiceModal;
