import {
  Box,
  Button,
  Divider,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import useHandleRegisterForm from "./hooks/useHandleRegisterForm";

import {
  COMMON_BOX_STYLE,
  COMMON_SELECT_STYLE,
  COMMON_SELECT_WIDTH,
  TEXT_FIELD_STYLE,
} from "./constants";
import Title from "./Title";

export default function UserCreateModalBody({
  onUserCreateModalClose,
}: {
  onUserCreateModalClose: () => void;
}) {
  const {
    form,
    handleFormUpdate,
    ResponseHandlerOfRegisterUser,
    handleUserRegister,
  } = useHandleRegisterForm({
    onUserCreateModalClose,
  });

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <Typography variant="h6" textAlign={"center"}>
          사용자 생성
        </Typography>

        <Divider />

        <Box sx={COMMON_BOX_STYLE}>
          <Title title="서비스" />

          <FormControl sx={COMMON_SELECT_WIDTH}>
            <Select
              value={form.country}
              size="small"
              name={"country"}
              onChange={(e) => handleFormUpdate(e.target.value, e.target.name)}
              sx={COMMON_SELECT_STYLE}
            >
              <MenuItem value={"KR"}>쉽다</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box sx={COMMON_BOX_STYLE}>
          <Title title="회사명" />

          <TextField
            value={form.company}
            name={"company"}
            onChange={(e) => handleFormUpdate(e.target.value, e.target.name)}
            size="small"
            sx={TEXT_FIELD_STYLE}
          />
        </Box>

        <Box sx={COMMON_BOX_STYLE}>
          <Title title="성함" />

          <TextField
            value={form.name}
            name={"name"}
            onChange={(e) => handleFormUpdate(e.target.value, e.target.name)}
            size="small"
            sx={TEXT_FIELD_STYLE}
          />
        </Box>

        <Box sx={COMMON_BOX_STYLE}>
          <Title title="E-Mail" />

          <TextField
            value={form.email}
            name={"email"}
            onChange={(e) => handleFormUpdate(e.target.value, e.target.name)}
            size="small"
            sx={TEXT_FIELD_STYLE}
          />
        </Box>

        <Box sx={COMMON_BOX_STYLE}>
          <Title title="전화번호" />

          <TextField
            value={form.phone}
            name={"phone"}
            onChange={(e) => handleFormUpdate(e.target.value, e.target.name)}
            size="small"
            sx={TEXT_FIELD_STYLE}
          />
        </Box>

        <Box sx={COMMON_BOX_STYLE}>
          <Title title="유입 경로" isNecessary={false} />

          <FormControl sx={COMMON_SELECT_WIDTH}>
            <Select
              value={form.registerType}
              size="small"
              name={"registerType"}
              onChange={(e) => handleFormUpdate(e.target.value, e.target.name)}
              sx={COMMON_SELECT_STYLE}
            >
              <MenuItem value={undefined}>구분 없음</MenuItem>
              <MenuItem value={"externalChannel"}>외부채널</MenuItem>
              <MenuItem value={"fclPromotion"}>F타사견적서-100불</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box sx={COMMON_BOX_STYLE}>
          <Title title="화물 타입" isNecessary={false} />

          <FormControl sx={COMMON_SELECT_WIDTH}>
            <Select
              value={form.freightType}
              size="small"
              name={"freightType"}
              onChange={(e) => handleFormUpdate(e.target.value, e.target.name)}
              sx={COMMON_SELECT_STYLE}
            >
              <MenuItem value={undefined}>구분 없음</MenuItem>
              <MenuItem value={"FCL"}>FCL</MenuItem>
              <MenuItem value={"oceanTicket"}>오션티켓</MenuItem>
              <MenuItem value={"consolidation"}>특송</MenuItem>
              <MenuItem value={"fulfillment"}>풀필먼트</MenuItem>
              <MenuItem value={"export"}>수출</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Divider sx={{ mb: 1 }} />

        <Button variant="contained" onClick={handleUserRegister} fullWidth>
          생성
        </Button>
      </Box>

      {ResponseHandlerOfRegisterUser}
    </>
  );
}
