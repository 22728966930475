import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { FilterDataKey } from "@sellernote/_shared/src/types/forwarding/common";
import useForwardingManagerIdFilter from "@sellernote/_shared-for-forwarding-admin/src/hooks/useForwardingManagerIdFilter";
import { SHARED_FOR_FORWARDING_ADMIN_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/states/filters";

import useInProgressFilter from "./useInProgressFilter";

export default function useTableHeadPanel(filterDataKey: FilterDataKey) {
  const { pathname } = useLocation();

  const filterData = useRecoilValue(
    SHARED_FOR_FORWARDING_ADMIN_ATOMS[filterDataKey]
  );

  const {
    ForwardingManagerFilter,
    headFilterData: ForwardingManagerIdFilterData,
    handleReset: ForwardingManagerIdReset,
  } = useForwardingManagerIdFilter(filterData.forwardingManagerIdList);

  const {
    InProgressFilter,
    headFilterData: InProgressFilterData,
    handleReset: InProgressReset,
  } = useInProgressFilter(filterData);

  const tableHeadFilterData = useMemo(() => {
    if (pathname.includes("/sub")) {
      return {
        ...ForwardingManagerIdFilterData,
      };
    }
    return {
      ...ForwardingManagerIdFilterData,

      ...InProgressFilterData,
    };
  }, [ForwardingManagerIdFilterData, InProgressFilterData, pathname]);

  const handleFilterReset = () => {
    ForwardingManagerIdReset();
    InProgressReset();
  };

  const TableHeadPanel = {
    ForwardingManagerFilter: ForwardingManagerFilter,
    InProgressFilter,
  };
  const NotYetTableHeadPanel = {
    ForwardingManagerFilter: ForwardingManagerFilter,
  };
  return {
    tableHeadFilterData,
    TableHeadPanel,
    NotYetTableHeadPanel,
    handleFilterReset,
  };
}
