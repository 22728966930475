import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import { TrelloBidDetail } from "@sellernote/_shared/src/types/forwarding/trello";
import {
  FARECON_DOMESTIC_PARTNER_ID,
  FARECON_FOREIGN_PARTNER_ID,
  GLOBELINK_KOREA_PARTNER_ID,
  GLOBELINK_SHANGHAI_EXPRESS_PARTNER_ID,
  GLOBELINK_SHANGHAI_FCL_PARTNER_ID,
  GLOBELINKSHA_PARTNER_ID,
} from "@sellernote/_shared/src/utils/forwarding/partner";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";

function SelectRefundPartnerModal({
  showsSelectRefundPartnerModal,
  setShowsSelectRefundPartnerModal,
  shipmentDetailData,
  requestRefundInvoice,
}: {
  setShowsSelectRefundPartnerModal: Dispatch<SetStateAction<boolean>>;
  showsSelectRefundPartnerModal: boolean;
  shipmentDetailData: TrelloBidDetail;
  requestRefundInvoice: (refundPartnerId: number) => void;
}) {
  const [refundPartnerId, setRefundPartnerId] = useState(0);

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRefundPartnerId(Number(e.target.value));
  };

  const handleRefundInvoiceRequest = useCallback(() => {
    setShowsSelectRefundPartnerModal(false);
    requestRefundInvoice(refundPartnerId);
  }, [refundPartnerId, requestRefundInvoice, setShowsSelectRefundPartnerModal]);

  const handleModalClose = useCallback(() => {
    setShowsSelectRefundPartnerModal(false);
  }, [setShowsSelectRefundPartnerModal]);

  const refundPartnerList = useMemo(() => {
    return shipmentDetailData.accountPayables.filter((v) => {
      return (
        v.partner?.id === GLOBELINKSHA_PARTNER_ID ||
        v.partner?.id === GLOBELINK_SHANGHAI_EXPRESS_PARTNER_ID ||
        v.partner?.id === GLOBELINK_SHANGHAI_FCL_PARTNER_ID ||
        v.partner?.id === GLOBELINK_KOREA_PARTNER_ID ||
        v.partner?.id === FARECON_DOMESTIC_PARTNER_ID ||
        v.partner?.id === FARECON_FOREIGN_PARTNER_ID
      );
    });
  }, [shipmentDetailData.accountPayables]);

  return (
    <Modal
      handleClose={handleModalClose}
      isOpened={showsSelectRefundPartnerModal}
      showsCloseIcon={true}
      modalBody={
        <Grid container direction="column" spacing={2} alignItems="center">
          <Grid item>
            <Typography variant="h6" component="div">
              발송할 부킹 커미션 파트너를 선택해주세요
            </Typography>
          </Grid>

          <Grid item>
            <FormControl>
              <RadioGroup
                name="radio-buttons-group"
                onChange={handleRadioChange}
              >
                {refundPartnerList.map((v) => {
                  return (
                    <FormControlLabel
                      key={v.id}
                      value={v.id}
                      control={<Radio />}
                      label={v.partner.name}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item>
            <Button
              disabled={!refundPartnerId}
              variant="contained"
              onClick={handleRefundInvoiceRequest}
            >
              발송
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
}

export default SelectRefundPartnerModal;
