import React, { useCallback } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Radio, RadioChangeEvent, Select } from "antd";

import { SellernoteAppRegion } from "@sellernote/_shared/src/types/common/common";

const options = [
  { label: "전체", value: "all" },
  { label: "쉽다 관세사", value: "shipDaCustoms" },
  { label: "화주 관세사", value: "customs" },
  { label: "내륙운송사", value: "inland" },
  { label: "창고", value: "wareHouse" },
  { label: "화물택배사", value: "parcel" },
  { label: "선사", value: "shipping" },
  { label: "기타", value: "etc" },
];

const InputGroup = Input.Group;

const OperatorPartnerListTableFilter = ({
  setBusinessArea,
  businessArea,
  searchValue,
  setSearchValue,
  searchType,
  setSearchType,
  isAutoFetch,
  setIsAutoFetch,
  region,
  setRegion,
}: {
  setBusinessArea: React.Dispatch<React.SetStateAction<undefined>>;
  businessArea: string | undefined;
  searchValue: {
    companyName: string | undefined;
    BRNNumber: string | undefined;
    accountNumber: string | undefined;
  };
  setSearchValue: React.Dispatch<
    React.SetStateAction<{
      companyName: string | undefined;
      BRNNumber: string | undefined;
      accountNumber: string | undefined;
    }>
  >;
  searchType: string;
  setSearchType: React.Dispatch<React.SetStateAction<string>>;
  isAutoFetch: boolean;
  setIsAutoFetch: React.Dispatch<React.SetStateAction<boolean>>;
  region: SellernoteAppRegion;
  setRegion: (val: SellernoteAppRegion) => void;
}) => {
  const handleChangeBusinessArea = (e: RadioChangeEvent) => {
    if (e.target.value === "all") {
      setBusinessArea(undefined);
    } else {
      setBusinessArea(e.target.value);
    }
  };

  const handleChangeSearchType = (e: string) => {
    setSearchValue({
      companyName: undefined,
      BRNNumber: undefined,
      accountNumber: undefined,
    });
    setSearchType(e);
  };

  const handleChangeSearchInputText = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (isAutoFetch) setIsAutoFetch(false);
    if (searchType === "company") {
      setSearchValue({ ...searchValue, companyName: e.target.value });
    } else if (searchType === "BRNNumber") {
      setSearchValue({ ...searchValue, BRNNumber: e.target.value });
    } else {
      setSearchValue({ ...searchValue, accountNumber: e.target.value });
    }
  };

  const getInputValueBySearchType = useCallback(() => {
    if (searchType === "company") {
      return searchValue.companyName;
    } else if (searchType === "BRNNumber") {
      return searchValue.BRNNumber;
    } else {
      return searchValue.accountNumber;
    }
  }, [
    searchType,
    searchValue.companyName,
    searchValue.BRNNumber,
    searchValue.accountNumber,
  ]);

  return (
    <>
      <Radio.Group
        options={options}
        onChange={handleChangeBusinessArea}
        value={businessArea === undefined ? "all" : businessArea}
        optionType="button"
      />
      <InputGroup compact>
        <Select
          onChange={handleChangeSearchType}
          value={searchType}
          style={{ width: "140px" }}
        >
          <Select.Option value="company">{"회사명"}</Select.Option>
          <Select.Option value="BRNNumber">{"사업자등록증번호"}</Select.Option>
          <Select.Option value="accountNumber">{"계좌번호"}</Select.Option>
        </Select>
        <Input
          style={{ width: 243 }}
          onChange={handleChangeSearchInputText}
          allowClear
          onPressEnter={() => setIsAutoFetch(true)}
          value={getInputValueBySearchType()}
        />
        <Button shape="circle" onClick={() => setIsAutoFetch(true)}>
          <SearchOutlined />
        </Button>

        <Select
          onChange={(val) => setRegion(val)}
          value={region}
          style={{ marginLeft: "3px" }}
        >
          <Select.Option value="KR">한국</Select.Option>
          <Select.Option value="SG">싱가포르</Select.Option>
        </Select>
      </InputGroup>
    </>
  );
};

export default OperatorPartnerListTableFilter;
