import TextField, { TextFieldProps } from "@mui/material/TextField";

import { styled } from "@mui/material/styles";

const NoLabelStyledTextField = styled(TextField)(() => ({
  "& legend": {
    display: "none",
  },
  "& fieldset": {
    top: 0,
  },
}));

export default function NoLabelTextField(props: TextFieldProps) {
  return <NoLabelStyledTextField {...props} />;
}
