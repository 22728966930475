import { ReactNode } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

function DescriptionGridBox({
  title,
  warningMessage,
  descriptionValueList,
}: {
  title?: string;
  warningMessage?: JSX.Element;
  descriptionValueList:
    | {
        label?: string;
        /** 라벨의 그리드 사이즈를 조절 (4가 최대) */
        labelGridSize?: number;
        value: ReactNode;
        /** gridSize가 12를 넘는 경우 한줄을 전부 차지*/
        gridSize?: number;
      }[]
    | undefined;
}) {
  return (
    <Box padding={1} border={1} borderColor={grey[400]}>
      <Grid container spacing={1}>
        <Grid container item xs={12} alignItems={"center"} spacing={2}>
          <Grid item>
            {title && (
              <Typography variant="h6" component="div">
                {title}
              </Typography>
            )}
          </Grid>

          {warningMessage && <Grid item>{warningMessage}</Grid>}
        </Grid>

        <Grid item container spacing={2} xs={12}>
          {descriptionValueList?.map((v, index) => {
            const labelSize = v.labelGridSize || 3;
            const valueSize = v.labelGridSize ? 12 - labelSize : 9;
            return (
              <Grid
                item
                container
                xs={v.gridSize || 12}
                key={index}
                alignItems="center"
              >
                <Grid item xs={labelSize}>
                  <Typography variant="body2" color={grey[600]}>
                    {v.label ? `${v.label}:` : null}
                  </Typography>
                </Grid>

                <Grid item xs={v.gridSize ? 12 - labelSize : valueSize}>
                  {v.value}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
}

export default DescriptionGridBox;
