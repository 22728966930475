import { atom } from "recoil";

import { GET_ADMIN_BID_LIST_FILTER } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBid";
import {
  GET_BUSINESS_MANAGEMENT_FILTER_LIST,
  GET_OPERATION_LIST_FILTER,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/businessManagement";

// key에 file structure(package이름 포함)를 반영하여 unique가 보장되도록 한다
const KEY_PREFIX = "shipda-admin/filter/atoms";

const ADMIN_BID_FILTER_LIST = atom<GET_ADMIN_BID_LIST_FILTER>({
  key: `${KEY_PREFIX}/ADMIN_BID_FILTER_LIST`,
  default: {},
});

const ADMIN_OPERATION_FILTER_LIST = atom<GET_OPERATION_LIST_FILTER>({
  key: `${KEY_PREFIX}/ADMIN_OPERATION_FILTER_LIST`,
  default: {},
});

const ADMIN_BUSINESS_MANAGEMENT_FILTER_LIST =
  atom<GET_BUSINESS_MANAGEMENT_FILTER_LIST>({
    key: `${KEY_PREFIX}/ADMIN_BUSINESS_FILTER_LIST`,
    default: {},
  });

export default {
  ADMIN_OPERATION_FILTER_LIST,
  ADMIN_BID_FILTER_LIST,
  ADMIN_BUSINESS_MANAGEMENT_FILTER_LIST,
};
