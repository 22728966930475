import { useQueryClient } from "react-query";
import { Modal } from "antd";

import PARTNER_QUERY from "@sellernote/_shared/src/queries/forwarding/PARTNER_QUERY";

const DeleteManagerModal = ({
  showDeleteManagerModal,
  companyId,
  afterClose,
  managerId,
  isMainManager,
}: {
  showDeleteManagerModal: boolean;
  companyId: number;
  managerId: number;
  afterClose: () => void;
  isMainManager: boolean;
}) => {
  const queryClient = useQueryClient();

  const {
    mutate: deletePartner,
    ResponseHandler: ResponseHandlerOfDeleteManager,
  } = PARTNER_QUERY.useDeleteManager({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        afterClose();
        queryClient.invalidateQueries(["forwarding", "partner", "list"]);
      },
      customizeMessage: () => ({
        title: isMainManager
          ? "담당자1 정보를 삭제했습니다. 담당자2 정보로 대체됩니다."
          : "삭제되었습니다.",
      }),
    },
    companyId,
    managerId,
  });

  return (
    <Modal
      title="삭제 하시겠습니까?"
      okButtonProps={{ danger: true }}
      okText="삭제"
      cancelText="취소"
      visible={showDeleteManagerModal}
      onCancel={() => {
        afterClose();
      }}
      onOk={() => deletePartner({})}
    >
      {ResponseHandlerOfDeleteManager}
    </Modal>
  );
};

export default DeleteManagerModal;
