import { FormControl, MenuItem, Select, Typography } from "@mui/material";

import {
  AdminPromotionDetailStatus,
  AdminPromotionStatus,
} from "@sellernote/_shared/src/types/forwarding/adminPromotion";
import {
  PROMOTION_CANCELED_OPTION_LIST,
  PROMOTION_PENDING_OPTION_LIST,
} from "@sellernote/_shared/src/utils/common/options";
import Modal from "@sellernote/_shared-for-forwarding-admin/src/components/Modal";

function PromotionDetailStatusConfirmModal({
  selectedStatus,
  showsPromotionDetailStatusConfirmModal,
  setShowsPromotionDetailStatusConfirmModal,
  handlePromotionDetailStatusChange,
}: {
  selectedStatus: AdminPromotionStatus;
  showsPromotionDetailStatusConfirmModal: boolean;
  setShowsPromotionDetailStatusConfirmModal: (value: boolean) => void;
  handlePromotionDetailStatusChange: (
    detailStatus: AdminPromotionDetailStatus
  ) => void;
}) {
  return (
    <Modal
      isOpened={showsPromotionDetailStatusConfirmModal}
      handleClose={() => setShowsPromotionDetailStatusConfirmModal(false)}
      modalBody={
        <>
          <Typography variant="h6" component="div">
            {`${selectedStatus === "pending" ? "보류" : "취소"} 사유를
            선택해주세요`}
          </Typography>

          <FormControl variant="standard">
            <Select
              sx={{ width: "200px" }}
              onChange={(e) => {
                handlePromotionDetailStatusChange(
                  e.target.value as AdminPromotionDetailStatus
                );
              }}
            >
              {selectedStatus === "pending"
                ? PROMOTION_PENDING_OPTION_LIST.map((v) => {
                    return (
                      <MenuItem key={v.value} value={v.value}>
                        {v.label}
                      </MenuItem>
                    );
                  })
                : PROMOTION_CANCELED_OPTION_LIST.map((v) => {
                    return (
                      <MenuItem key={v.value} value={v.value}>
                        {v.label}
                      </MenuItem>
                    );
                  })}
            </Select>
          </FormControl>
        </>
      }
    />
  );
}

export default PromotionDetailStatusConfirmModal;
