import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";

import Content from "./Content";
import Header from "./Header";

function PartnerManagement() {
  return (
    <Layout>
      <Header />

      <Content />
    </Layout>
  );
}

export default withRequireAuth(PartnerManagement);
