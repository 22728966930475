import { Box } from "@mui/material";

import useGetPartnerList from "./hooks/useGetPartnerList";

import FilterGroup from "./FilterGroup";
import PartnerTable from "./PartnerTable";

export default function Content() {
  const { data } = useGetPartnerList();

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <FilterGroup />

      <PartnerTable partnerList={data?.list} total={data?.total ?? 0} />
    </Box>
  );
}
