import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Checkbox, Typography } from "@mui/material";
import { useSetRecoilState } from "recoil";

import { GET_ADMIN_SETTLEMENT_PURCHASE_LIST_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminSettlement";
import useSet from "@sellernote/_shared/src/hooks/common/useSet";
import ADMIN_SETTLEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_SETTLEMENT_QUERY";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/_shared/src/states/forwarding/invoice";
import { ForwardingPurchaseAttachment } from "@sellernote/_shared/src/types/common/file";
import { SettlementPurchaseListData } from "@sellernote/_shared/src/types/forwarding/adminSettlement";
import { PartnerBusinessArea } from "@sellernote/_shared/src/types/forwarding/partner";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { changePurchaseDomainToKr } from "@sellernote/_shared/src/utils/forwarding/trello";
import CheckedListPriceSummary from "@sellernote/_shared-for-forwarding-admin/src/components/CheckedListPriceSummary";
import Modal from "@sellernote/_shared-for-forwarding-admin/src/components/Modal";
import Table, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/_shared-for-forwarding-admin/src/components/Table";
import useObjectSet from "@sellernote/_shared-for-forwarding-admin/src/hooks/useObjectSet";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";
import { SETTLEMENT_ADMIN_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/settlement";

import { useResetAtom } from "jotai/utils";

import CancelOrDeleteModal from "./CancelOrDeleteModal";
import PreviewModalBody from "./PreviewModalBody";
import PurchaseListExcelDownload from "./PurchaseListExcelDownload";
import RemittanceCertificateUploadModal from "./RemittanceCertificateUploadModal";
import UpdateWithdrawDate from "./UpdateWithdrawDate";
import usePurchaseManagementTableFilter from "./usePurchaseManagementTableFilter";
import WithdrawalRequestModal from "./WithdrawalRequestModal";

type CellId =
  | keyof SettlementPurchaseListData
  | "checkbox"
  | "bidCreatedAt"
  | "invoice"
  | "settlementFlag"
  | "issueInvoiceType"
  | "forwardingManager"
  | "completeWithdrawal"
  | "delete"
  | "invoiceNumber"
  | "invoiceDate"
  | "mbl"
  | "hbl";

function PurchaseManagementTable() {
  const { handleSnackbarOpen } = useSnackbar();

  const setTrelloCardId = useSetRecoilState(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const resetPurchaseTableSearchWithTermList = useResetAtom(
    SETTLEMENT_ADMIN_ATOMS.PURCHASE_TABLE_SEARCH_WITH_TERM_LIST
  );

  const history = useHistory();

  const [showsCancelWithdrawModal, setShowsCancelWithdrawModal] =
    useState(false);
  const [companyType, setCompanyType] =
    useState<PartnerBusinessArea>("foreign");
  const [bidId, setBidId] = useState(0);
  const [showsDeletePurchaseListModal, setShowsDeletePurchaseListModal] =
    useState(false);
  const [showsUploadModal, setShowsUploadModal] = useState(false);
  const [partnerId, setPartnerId] = useState(0);
  const [bidAccountPayableId, setBidAccountPayableId] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [showsWithdrawalRequestModal, setShowsWithdrawalRequestModal] =
    useState(false);

  /** 매입관리 첨부파일 미리보기 */
  const [showsPreviewModal, setShowsPreviewModal] = useState(false);
  /** 선택된 미리보기 파일 */
  const [selectedAttachmentList, setSelectedAttachmentList] = useState<
    ForwardingPurchaseAttachment[]
  >([]);

  const {
    array: checkBoxArr,
    set: checkBoxSet,
    toggle: toggleCheckBox,
    init: initCheckBoxSet,
  } = useSet<number>();

  const {
    array: finalPriceCheckedArr,
    toggle: toggleFinalPrice,
    init: initCheckboxSetForFinalPrice,
  } = useObjectSet<number, number>();

  const finalPriceSum = finalPriceCheckedArr.reduce((acc, obj) => {
    const value = Object.values(obj)[0];

    return acc + value;
  }, 0);

  const {
    PurchaseSettlementTableFilter,
    isWithdrawnSelectFilterValue,
    dateSearchType,
    startDate,
    endDate,
    forwardingManagerSelectFilterValue,
    companyTypeSelectFilter,
    purchaseSearchWithTermObject,
  } = usePurchaseManagementTableFilter();

  const { mutate: cancelWithdraw } = ADMIN_SETTLEMENT_QUERY.useCancelWithdraw();

  const { mutate: deleteWithdraw } = ADMIN_SETTLEMENT_QUERY.useDeleteWithdraw();

  const fetchListParams: GET_ADMIN_SETTLEMENT_PURCHASE_LIST_REQ = {
    page: currentPage,
    perPage,
    companyType: companyTypeSelectFilter,
    isWithdrawn: isWithdrawnSelectFilterValue,
    forwardingManagerId: forwardingManagerSelectFilterValue,

    // bidId와 finalPrice는 number로 보내야 해서 따로 처리함
    ...purchaseSearchWithTermObject,
    bidId: purchaseSearchWithTermObject?.bidId
      ? Number(purchaseSearchWithTermObject?.bidId)
      : undefined,
    finalPrice: purchaseSearchWithTermObject?.finalPrice
      ? Number(purchaseSearchWithTermObject?.finalPrice)
      : undefined,

    ...(dateSearchType === "createdDate" &&
      startDate &&
      endDate && {
        startCreateDate: startDate,
        endCreateDate: endDate,
      }),

    ...(dateSearchType === "withdrawalDate" &&
      startDate &&
      endDate && {
        startWithdrawalDate: startDate,
        endWithdrawalDate: endDate,
      }),

    ...(dateSearchType === "requestDate" &&
      startDate &&
      endDate && {
        startRequestDate: startDate,
        endRequestDate: endDate,
      }),

    ...(dateSearchType === "withdrawalInvoiceDate" &&
      startDate &&
      endDate && {
        withdrawalInvoiceStartDate: toFormattedDate(startDate, "YYYY-MM-DD"),
        withdrawalInvoiceEndDate: toFormattedDate(endDate, "YYYY-MM-DD"),
      }),
  };

  const { data: purchaseListData, refetch: refetchPurchaseListData } =
    ADMIN_SETTLEMENT_QUERY.useGetAdminSettlementPurchaseList(fetchListParams);

  const handlePreviewModalOpen = useCallback(
    (id: number) => {
      return () => {
        setShowsPreviewModal(true);

        if (!purchaseListData) return [];

        const targetedAttachmentList = purchaseListData.list.find(
          (v) => v.id === id
        )?.purchaseAttachments;

        setSelectedAttachmentList(targetedAttachmentList ?? []);
      };
    },
    [purchaseListData]
  );

  const handlePreviewModalClose = () => setShowsPreviewModal(false);

  const handleDeleteAndCancelButtonClick = useCallback(
    (listData: SettlementPurchaseListData, type: "delete" | "cancel") => {
      if (checkBoxArr.length > 0) {
        handleSnackbarOpen("리스트에 체크를 풀어주세요", "warning");
        return;
      }

      toggleCheckBox(listData.id);
      setBidId(listData.bidId);
      setBidAccountPayableId(listData.bidAccountPayableId);
      setCompanyType(listData.companyType);

      if (type === "cancel") {
        setShowsCancelWithdrawModal(true);
        return;
      }

      setShowsDeletePurchaseListModal(true);
      return;
    },
    [checkBoxArr.length, handleSnackbarOpen, toggleCheckBox]
  );

  const handleWithdrawCancel = () => {
    cancelWithdraw(
      {
        requestIds: checkBoxArr,
        isDel: false,
        isWithdrawn: false,
        bidId: bidId,
        companyType,
        bidAccountPayableId,
      },
      {
        onSuccess: () => {
          initCheckBoxSet();
          setBidId(0);
          setShowsCancelWithdrawModal(false);
          handleSnackbarOpen("요청에 성공했습니다.");
          refetchPurchaseListData();
        },

        onError: (error) => {
          if (error.response?.data.errorCode === "E067") {
            handleSnackbarOpen("취소 권한이 없습니다.", "error");
            return;
          }

          if (error.response?.data.errorCode === "E079") {
            handleSnackbarOpen(
              "입금증, 이체증이 있으면 출금 취소가 불가능합니다.",
              "error"
            );
            return;
          }

          handleSnackbarOpen("출금 취소를 할 수 없습니다.", "error");
        },
      }
    );
  };

  const handleWithdrawDelete = () => {
    deleteWithdraw(
      {
        requestIds: checkBoxArr,
        isDel: true,
        isWithdrawn: false,
        bidId: bidId,
        companyType,
        bidAccountPayableId,
      },
      {
        onSuccess: () => {
          initCheckBoxSet();
          setBidId(0);
          setShowsDeletePurchaseListModal(false);
          handleSnackbarOpen("요청에 성공했습니다.");
          refetchPurchaseListData();
        },

        onError: (error) => {
          if (error.response?.data.errorCode === "E067") {
            handleSnackbarOpen("삭제 권한이 없습니다.", "error");
            return;
          }

          if (error.response?.data.errorCode === "E080") {
            handleSnackbarOpen(
              "이미 출금된 요청은 삭제할 수 없습니다.",
              "error"
            );
            return;
          }

          handleSnackbarOpen("삭제를 할 수 없습니다.", "error");
        },
      }
    );
  };

  const headCells: TableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "checkbox",
        disablePadding: false,
        label: (
          <Checkbox
            onClick={() => {
              if (checkBoxSet.size) {
                initCheckBoxSet();
                initCheckboxSetForFinalPrice();
              } else {
                const idsToCheck = purchaseListData?.list.map((v) => {
                  return v.id;
                });

                const finalPrice = purchaseListData?.list.map((v) => {
                  return {
                    [v.id]: v.finalPrice,
                  };
                });

                initCheckBoxSet(idsToCheck);
                initCheckboxSetForFinalPrice(finalPrice);
              }
            }}
            checked={!!checkBoxSet.size}
          />
        ),
      },
      {
        id: "bidId",
        disablePadding: false,
        label: "의뢰번호",
        width: 100,
      },
      {
        id: "bidCreatedAt",
        disablePadding: false,
        label: "견적생성일",
        width: 110,
      },
      {
        id: "invoice",
        disablePadding: false,
        label: "인보이스",
        width: 80,
      },
      {
        id: "company",
        disablePadding: false,
        label: "회사명",
        width: 100,
      },
      {
        id: "companyType",
        disablePadding: false,
        label: "구분",
        width: 100,
      },
      {
        id: "BRN",
        disablePadding: false,
        label: "사업자 등록번호",
        numeric: true,
        width: 130,
      },
      {
        id: "currency",
        disablePadding: false,
        label: "통화",
        width: 150,
      },
      {
        id: "foreignFinalPrice",
        disablePadding: false,
        label: "외화",
        width: 150,
      },
      {
        id: "exchangeRate",
        disablePadding: false,
        label: "환율",
        width: 120,
      },
      {
        id: "totalPrice",
        disablePadding: false,
        label: "원가소계",
        width: 120,
      },
      {
        id: "taxExemptPrice",
        disablePadding: false,
        label: "비과세",
        width: 120,
      },
      {
        id: "finalPrice",
        disablePadding: false,
        label: "합계",
        width: 120,
      },
      {
        id: "bankName",
        disablePadding: false,
        label: "은행",
        width: 120,
      },
      {
        id: "accountNumber",
        disablePadding: false,
        label: "계좌번호",
        width: 150,
      },
      {
        id: "mbl",
        disablePadding: false,
        label: "mbl",
        width: 200,
      },
      {
        id: "hbl",
        disablePadding: false,
        label: "hbl",
        width: 200,
      },
      {
        id: "withdrawalInvoiceId",
        disablePadding: false,
        label: "INV No.",
        width: 200,
      },
      {
        id: "withdrawalInvoiceDate",
        disablePadding: false,
        label: "INV. Date",
        width: 200,
      },
      {
        id: "settlementFlag",
        disablePadding: false,
        label: "월 정산",
        width: 80,
      },
      {
        id: "createdAt",
        disablePadding: false,
        label: "생성일",
        width: 120,
      },
      {
        id: "withdrawalDate",
        disablePadding: false,
        label: "출금일",
        width: 120,
      },
      {
        id: "forwardingManager",
        disablePadding: false,
        label: "포워딩 담당자",
        width: 120,
      },
      {
        id: "completeWithdrawal",
        disablePadding: false,
        label: "출금완료",
        width: 100,
      },
      {
        id: "attachment",
        disablePadding: false,
        label: "이체증",
        width: 100,
      },
      {
        id: "delete",
        disablePadding: false,
        label: "삭제",
        width: 80,
      },
    ];
  }, [
    checkBoxSet.size,
    initCheckBoxSet,
    initCheckboxSetForFinalPrice,
    purchaseListData?.list,
  ]);

  const rows: TableBodyRow<CellId>[] = useMemo(() => {
    if (!purchaseListData?.list) return [];

    return purchaseListData?.list.map((v) => {
      const row: TableBodyRow<CellId> = {
        checkbox: (
          <Checkbox
            checked={checkBoxSet.has(v.id)}
            onClick={(e) => {
              e.stopPropagation();
              toggleFinalPrice({
                [v.id]: v.finalPrice,
              });
              toggleCheckBox(v.id);
            }}
          />
        ),
        bidId: (
          <Button
            variant="text"
            onClick={() => {
              setTrelloCardId(v.bidId);
              // TODO: 특송화물 조건이 추가되어야 한다.
              history.push(`/trello/${v.bid.isImport ? "general" : "export"}`);
            }}
          >
            {v.bidId}
          </Button>
        ),
        bidCreatedAt: toFormattedDate(v.bid.createdAt, "YYYY-MM-DD"),
        invoice: (
          <Button
            variant="outlined"
            size="small"
            sx={{ fontSize: 10 }}
            onClick={handlePreviewModalOpen(v.id)}
            disabled={!v.purchaseAttachments.length}
          >
            미리보기
          </Button>
        ),
        company: v.company.name,
        companyType: changePurchaseDomainToKr(v.companyType),
        BRN: v.BRN,
        currency: v.currency,
        foreignFinalPrice: toThousandUnitFormat(v.foreignFinalPrice),
        exchangeRate: v.exchangeRate,
        totalPrice: toThousandUnitFormat(
          v.finalPrice - (v.taxExemptPrice ?? 0)
        ),
        taxExemptPrice: toThousandUnitFormat(v?.taxExemptPrice ?? 0),
        finalPrice: (
          <Button
            variant="text"
            onClick={() => {
              setBidAccountPayableId(v.bidAccountPayableId);
              setShowsWithdrawalRequestModal(true);
            }}
          >
            {toThousandUnitFormat(v.finalPrice)}
          </Button>
        ),
        bankName: v.bankName,
        accountNumber: v.accountNumber,
        mbl: v.bid.management.mBL,
        hbl: v.bid.management.hBL,

        withdrawalInvoiceId: v.withdrawalInvoiceId,
        withdrawalInvoiceDate: toFormattedDate(
          v.withdrawalInvoiceDate,
          "YYYY-MM-DD"
        ),
        settlementFlag: v.company.settlementFlag ? "O" : "X",
        createdAt: toFormattedDate(v.createdAt, "YYYY-MM-DD"),
        withdrawalDate: v.withdrawalDate
          ? toFormattedDate(v.withdrawalDate, "YYYY-MM-DD")
          : "-",
        forwardingManager: v.forwardingManager.name,
        completeWithdrawal: (
          <Checkbox
            disabled={!v.isWithdrawn}
            checked={v.isWithdrawn}
            onClick={() => handleDeleteAndCancelButtonClick(v, "cancel")}
          />
        ),
        attachment: (
          <Button
            onClick={() => {
              setPartnerId(v.id);
              setBidId(v.bidId);
              setBidAccountPayableId(v.bidAccountPayableId);
              setShowsUploadModal(true);
            }}
          >
            {v.attachment === null ? "업로드" : "다운로드"}
          </Button>
        ),
        delete: (
          <Button
            color="error"
            onClick={() => handleDeleteAndCancelButtonClick(v, "delete")}
          >
            삭제
          </Button>
        ),
      };
      return row;
    });
  }, [
    checkBoxSet,
    handleDeleteAndCancelButtonClick,
    handlePreviewModalOpen,
    history,
    purchaseListData?.list,
    setTrelloCardId,
    toggleCheckBox,
    toggleFinalPrice,
  ]);

  // 페이지 이탈 시 검색기록 초기화
  useEffect(() => {
    return () => {
      resetPurchaseTableSearchWithTermList();
    };
  }, [resetPurchaseTableSearchWithTermList]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box>{PurchaseSettlementTableFilter}</Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 1,
          background: "#fff",
        }}
      >
        <UpdateWithdrawDate
          checkBoxArr={checkBoxArr}
          initCheckBoxSet={initCheckBoxSet}
          refetchPurchaseListData={refetchPurchaseListData}
        />

        <PurchaseListExcelDownload fetchListParams={fetchListParams} />
      </Box>

      <Box sx={{ p: 1, background: "#fff" }}>
        <Table
          toolbarItems={{
            left: [
              <Typography key="total">
                총 {purchaseListData?.total || 0}건
              </Typography>,
            ],
          }}
          headCells={headCells}
          rows={rows}
          pagination={{
            totalCount: purchaseListData?.total || 0,
            perPage,
            setPerPage,
            currentPage,
            setCurrentPage,
          }}
        />
      </Box>

      {showsCancelWithdrawModal && (
        <CancelOrDeleteModal
          showsCancelOrDeleteModal={showsCancelWithdrawModal}
          setShowsCancelOrDeleteModal={setShowsCancelWithdrawModal}
          initCheckBoxSet={initCheckBoxSet}
          setBidId={setBidId}
          onWithdrawConfirm={handleWithdrawCancel}
          type="cancel"
        />
      )}

      {showsDeletePurchaseListModal && (
        <CancelOrDeleteModal
          showsCancelOrDeleteModal={showsDeletePurchaseListModal}
          setShowsCancelOrDeleteModal={setShowsDeletePurchaseListModal}
          initCheckBoxSet={initCheckBoxSet}
          setBidId={setBidId}
          onWithdrawConfirm={handleWithdrawDelete}
          type="delete"
        />
      )}

      {showsUploadModal && (
        <RemittanceCertificateUploadModal
          showsRemittanceCertificateUploadModal={showsUploadModal}
          setShowsRemittanceCertificateUploadModal={setShowsUploadModal}
          partnerId={partnerId}
          setPartnerId={setPartnerId}
          bidId={bidId}
          fetchPurchaseList={refetchPurchaseListData}
          bidAccountPayableId={bidAccountPayableId}
          purchaseListData={purchaseListData}
        />
      )}

      {showsWithdrawalRequestModal && (
        <WithdrawalRequestModal
          setShowsWithdrawalRequestModal={setShowsWithdrawalRequestModal}
          showsWithdrawalRequestModal={showsWithdrawalRequestModal}
          bidAccountPayableId={bidAccountPayableId}
        />
      )}

      <CheckedListPriceSummary
        numberCheckedItems={checkBoxArr.length}
        finalPrice={finalPriceSum}
        showsOnlyFinalPrice={true}
      />

      <Modal
        isOpened={showsPreviewModal}
        handleClose={handlePreviewModalClose}
        modalBody={
          <PreviewModalBody selectedAttachmentList={selectedAttachmentList} />
        }
      />
    </Box>
  );
}

export default PurchaseManagementTable;
