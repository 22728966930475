import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { Button, CircularProgress } from "@mui/material";
import { AxiosResponse } from "axios";

import {
  RequestInvoiceData,
  SAVE_INVOICE_RES,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/adminFile";
import { ADMIN_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_FILE_QUERY";
import { ResponseFailureInfo } from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import {
  InvoiceType,
  TrelloBidDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { checkIfRefundTradingStatementCanBeSend } from "@sellernote/_shared/src/utils/forwarding/tradingStatement";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

import useCompareWithDefaultValue from "../../../hooks/useCompareWithDefaultValue";
import useCommonErrorMessage from "../hooks/useCommonErrorMessage";

import SelectRefundPartnerModal from "./SelectRefundPartnerModal";

function RefundInvoiceButton({
  invoiceType,
  getInvoiceDataForRequest,
  handleRequestError,
  shipmentDetailData,
  invoiceState,
  isIssuedInvoice,
  directAirRton,
  sessionInvoiceId,
  onSessionInvoiceChange,
}: {
  invoiceType: InvoiceType;
  getInvoiceDataForRequest: () => RequestInvoiceData;
  handleRequestError: (
    response: AxiosResponse<ResponseFailureInfo, any> | undefined
  ) => void;
  shipmentDetailData: TrelloBidDetail;
  invoiceState: ApplyBidFormData;
  isIssuedInvoice: boolean;
  directAirRton: number;
  sessionInvoiceId: string | null;
  onSessionInvoiceChange: (invoiceId: string) => void;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const [showsSelectRefundPartnerModal, setShowsSelectRefundPartnerModal] =
    useState(false);

  const { mutate: saveInvoice, isLoading: saveInvoiceLoading } =
    ADMIN_FILE_QUERY.useSaveInvoice();

  const { mutate: editInvoice, isLoading: editInvoiceLoading } =
    ADMIN_FILE_QUERY.useEditInvoice();

  const { mutate: sendRefundInvoice } = ADMIN_FILE_QUERY.useSendRefundInvoice({
    bidId: shipmentDetailData.id,
  });

  const { checkAndShowCommonErrorMessage } = useCommonErrorMessage();

  const { isCargoInfoUnchanged, isExchangeInfoUnchanged } =
    useCompareWithDefaultValue({
      invoiceState,
      shipmentDetailData,
      directAirRton,
    });

  const refundPartnerCount = useMemo(() => {
    const partnerCount = shipmentDetailData.accountPayables.reduce(
      (acc, cur) => {
        if (
          cur.partner?.id === 607 ||
          cur.partner?.id === 136 ||
          cur.partner?.id === 952 ||
          cur.partner?.id === 612 ||
          cur.partner?.id === 120 ||
          cur.partner?.id === 967
        ) {
          acc += 1;
        }

        return acc;
      },
      0
    );

    return partnerCount;
  }, [shipmentDetailData.accountPayables]);

  const refundPartnerBidAccountPayable = useMemo(() => {
    const refundPartner = shipmentDetailData.accountPayables.find((v) => {
      return (
        v.partner?.id === 607 ||
        v.partner?.id === 136 ||
        v.partner?.id === 952 ||
        v.partner?.id === 612 ||
        v.partner?.id === 120 ||
        v.partner?.id === 967
      );
    });

    return !refundPartner ? 0 : refundPartner.id;
  }, [shipmentDetailData.accountPayables]);

  const handleSuccess = useCallback(
    ({
      data,
      refundPartnerId,
    }: {
      data: SAVE_INVOICE_RES;
      refundPartnerId: number;
    }) => {
      const invoiceId = data.id;

      sendRefundInvoice(
        {
          invoiceType,
          bidAccountPayableId: refundPartnerId,
        },
        {
          onSuccess: () => {
            setShowsSelectRefundPartnerModal(false);

            // invoiceId가 있으면 거래명세서 데이터를 재요청
            if (sessionInvoiceId) {
              queryClient.invalidateQueries(
                ADMIN_BID_QUERY_KEY_GEN.getAdminInvoice({
                  invoiceId,
                })
              );
            }

            // invoiceId가 없으면 거래명세서아이디를 저장
            else {
              onSessionInvoiceChange(invoiceId.toString());
            }

            handleSnackbarOpen("요청에 성공했습니다");
          },

          onError: ({ response }) => {
            handleRequestError(response);
          },
        }
      );
    },
    [
      handleRequestError,
      handleSnackbarOpen,
      invoiceType,
      onSessionInvoiceChange,
      queryClient,
      sendRefundInvoice,
      sessionInvoiceId,
    ]
  );

  const requestRefundInvoice = useCallback(
    (refundPartnerId: number) => {
      // invoiceId가 없으면 첫 생성 API로 있으면 수정 API로 요청
      if (sessionInvoiceId) {
        editInvoice(
          {
            pathParams: {
              invoiceId: Number(sessionInvoiceId),
            },
            invoiceData: getInvoiceDataForRequest(),
            isTemporary: false,
            invoiceType,
          },
          {
            onSuccess: ({ data }) => {
              handleSuccess({ data, refundPartnerId });
            },

            onError: ({ response }) => {
              handleRequestError(response);
            },
          }
        );
      } else {
        saveInvoice(
          {
            invoiceData: getInvoiceDataForRequest(),
            isTemporary: false,
            invoiceType,
          },
          {
            onSuccess: ({ data }) => {
              handleSuccess({ data, refundPartnerId });
            },

            onError: ({ response }) => {
              handleRequestError(response);
            },
          }
        );
      }
    },
    [
      editInvoice,
      getInvoiceDataForRequest,
      handleRequestError,
      handleSuccess,
      invoiceType,
      saveInvoice,
      sessionInvoiceId,
    ]
  );

  const handleRefundInvoiceRequestClick = useCallback(() => {
    if (
      checkAndShowCommonErrorMessage({
        isCargoInfoUnchanged,
        isExchangeInfoUnchanged,
        isIssuedInvoice,
        canBeSendToBookingCommission: checkIfRefundTradingStatementCanBeSend(
          shipmentDetailData.projectStatus
        ),
        hasDirectAirRton:
          shipmentDetailData.freightType === "AIR" && !directAirRton,
      })
    ) {
      return;
    }

    if (refundPartnerCount > 1) {
      setShowsSelectRefundPartnerModal(true);
      return;
    }

    if (!refundPartnerBidAccountPayable) {
      handleSnackbarOpen("부킹커미션 파트너가 아닙니다.", "error");
      return;
    }

    requestRefundInvoice(refundPartnerBidAccountPayable);
  }, [
    checkAndShowCommonErrorMessage,
    isCargoInfoUnchanged,
    isExchangeInfoUnchanged,
    isIssuedInvoice,
    shipmentDetailData.projectStatus,
    shipmentDetailData.freightType,
    directAirRton,
    refundPartnerCount,
    refundPartnerBidAccountPayable,
    requestRefundInvoice,
    handleSnackbarOpen,
  ]);

  return (
    <>
      <Button
        disabled={saveInvoiceLoading || editInvoiceLoading}
        variant="contained"
        color="secondary"
        onClick={handleRefundInvoiceRequestClick}
      >
        {saveInvoiceLoading || editInvoiceLoading ? (
          <CircularProgress size={25} />
        ) : (
          "부킹 커미션 발송"
        )}
      </Button>

      {showsSelectRefundPartnerModal && (
        <SelectRefundPartnerModal
          setShowsSelectRefundPartnerModal={setShowsSelectRefundPartnerModal}
          showsSelectRefundPartnerModal={showsSelectRefundPartnerModal}
          shipmentDetailData={shipmentDetailData}
          requestRefundInvoice={requestRefundInvoice}
        />
      )}
    </>
  );
}

export default RefundInvoiceButton;
