import { Dispatch, SetStateAction } from "react";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Box, Divider, IconButton, Typography } from "@mui/material";

import { MappedPartnerManagerInfo } from "@sellernote/_shared/src/types/forwarding/partnerManagement";

export default function ManagerInfoHeader({
  setManagerList,
}: {
  setManagerList: Dispatch<SetStateAction<MappedPartnerManagerInfo[]>>;
}) {
  const handleManagerAdd = () => {
    setManagerList((prev) => [
      ...prev,
      {
        id: prev.length + 1,
        isMain: false,
        name: "",
        position: "",
        phone: "",
        email: "",
        isCustomsAdmin: false,
      },
    ]);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h6">담당자 정보</Typography>

        <IconButton color="primary" onClick={handleManagerAdd}>
          <PersonAddIcon />
        </IconButton>
      </Box>

      <Divider />
    </>
  );
}
