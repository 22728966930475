import { Dispatch, SetStateAction } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Switch,
  TextField,
} from "@mui/material";

import {
  MappedPartnerManagerInfo,
  PartnerBusinessArea,
} from "@sellernote/_shared/src/types/forwarding/partnerManagement";
import { getEmailValidation } from "@sellernote/_shared/src/utils/common/validation";
import Table from "@sellernote/_shared-for-forwarding-admin/src/components/Table";

import BOX_STYLE from "../styles";
import useHandleManagerUpdate from "./hooks";
import { getCellList } from "./utils";

import ManagerInfoHeader from "./ManagerInfoHeader";

export default function ManagerInfo({
  businessArea,
  managerList,
  setManagerList,
}: {
  businessArea: PartnerBusinessArea;
  managerList: MappedPartnerManagerInfo[];
  setManagerList: Dispatch<SetStateAction<MappedPartnerManagerInfo[]>>;
}) {
  const { id }: { id: string } = useParams();

  const isCustomPartner =
    businessArea === "shipDaCustoms" || businessArea === "customs";

  const { handleManagerUpdate, handleManagerDelete, handleManagerStateUpdate } =
    useHandleManagerUpdate({
      managerList,
      setManagerList,
      id: Number(id),
    });

  const row = (() => {
    const rowList = managerList.map((manager) => {
      return {
        isMain: (
          <Checkbox
            checked={manager.isMain}
            size="small"
            sx={{ ml: -1 }}
            onChange={(e) => {
              handleManagerStateUpdate(manager.id, "isMain", e.target.checked);
            }}
          />
        ),

        name: (
          <TextField
            variant="standard"
            value={manager.name}
            fullWidth
            onChange={(e) => {
              handleManagerStateUpdate(manager.id, "name", e.target.value);
            }}
          />
        ),

        position: (
          <TextField
            variant="standard"
            value={manager.position}
            fullWidth
            onChange={(e) => {
              handleManagerStateUpdate(manager.id, "position", e.target.value);
            }}
          />
        ),

        phone: (
          <TextField
            variant="standard"
            value={manager.phone}
            fullWidth
            onChange={(e) => {
              handleManagerStateUpdate(manager.id, "phone", e.target.value);
            }}
          />
        ),

        email: (
          <TextField
            variant="standard"
            value={manager.email}
            fullWidth
            onChange={(e) => {
              handleManagerStateUpdate(manager.id, "email", e.target.value);
            }}
            error={!getEmailValidation(manager.email).result}
            helperText={getEmailValidation(manager.email).message}
          />
        ),

        ...(isCustomPartner
          ? {
              isCustomsAdmin: (
                <Switch
                  checked={manager.isCustomsAdmin}
                  onChange={(e) => {
                    handleManagerStateUpdate(
                      manager.id,
                      "isCustomsAdmin",
                      e.target.checked
                    );
                  }}
                />
              ),
            }
          : {}),

        delete: (
          <Button
            size="small"
            variant="outlined"
            sx={{ p: 0 }}
            onClick={() => handleManagerDelete(manager.id)}
          >
            삭제
          </Button>
        ),
      };
    });

    return rowList;
  })();

  return (
    <Box sx={{ ...BOX_STYLE, width: 1250 }}>
      <ManagerInfoHeader setManagerList={setManagerList} />

      <Box display={"flex"} flexDirection={"column"} mt={2} gap={2}>
        <Table
          headCells={getCellList(isCustomPartner)}
          rows={row}
          showsTableVerticalLine
        />

        <Divider />

        <Box alignSelf={"flex-end"}>
          <Button
            variant="contained"
            size="small"
            onClick={handleManagerUpdate}
          >
            저장
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
