const PARTNER_TYPE = [
  {
    label: "전체",
    value: undefined,
  },
  {
    label: "해외",
    value: "foreign",
  },

  {
    label: "국내",
    value: "domestic",
  },
  {
    label: "쉽다 관세사",
    value: "shipDaCustoms",
  },
  {
    label: "화주 관세사",
    value: "customs",
  },
  {
    label: "내륙 운송사",
    value: "inland",
  },
  {
    label: "창고",
    value: "wareHouse",
  },
  {
    label: "소포",
    value: "parcel",
  },
  {
    label: "선적",
    value: "shipping",
  },
  {
    label: "기타",
    value: "etc",
  },
];

export default PARTNER_TYPE;
