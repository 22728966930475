import { useState } from "react";
import { useQueryClient } from "react-query";
import { useAtom, useAtomValue } from "jotai";

import { CREATE_PARTNER_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/partnerManagement";
import { APP_ENV } from "@sellernote/_shared/src/constants";
import ADMIN_PARTNER_MANAGEMENT_QUERY, {
  ADMIN_PARTNER_MANAGEMENT_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_PARTNER_MANAGEMENT_QUERY";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";
import { FORWARDING_ADMIN_AUTH_SELECTORS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/auth";
import { FORWARDING_PARTNER_MANAGEMENT_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/partnerManagement";

import { setPayload, validateCreatePartner } from "../utils";

/** 호웅님 운영서버 어드민 ID */
const FINANCE_TEAM_LEADER_ID = 4;

export default function useHandleCreatePartner({
  onModalClose,
  timeStamp,
  handleFileListReset,
  validateFile,
}: {
  onModalClose: () => void;
  timeStamp: number;
  handleFileListReset: () => void;
  validateFile: () => boolean;
}) {
  const { id } = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const [{ page, perPage }, setParams] = useAtom(
    FORWARDING_PARTNER_MANAGEMENT_ATOMS.SEARCH_PARAMS
  );

  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const [isSelected, setIsSelected] = useState(false);
  const [isCreate, setIsCreate] = useState(false);

  const handleSuccess = () => {
    handleSnackbarOpen("파트너를 성공적으로 생성했습니다.");
    setParams((prev) => ({ ...prev, page: 0 }));
    handleFileListReset();
    setIsCreate(false);
    setIsCreate(false);
    onModalClose();

    return queryClient.invalidateQueries(
      ADMIN_PARTNER_MANAGEMENT_QUERY_KEY_GEN.getPartnerList({ page, perPage })
    );
  };

  const { mutate: createPartner } =
    ADMIN_PARTNER_MANAGEMENT_QUERY.useCreatePartner(handleSuccess);

  const [form, setForm] = useState<CREATE_PARTNER_REQ>({
    name: "",
    businessArea: "",
    countries: [],
    language: "",
    transportModes: [],
    isFinancial: true,
    invoiceIssueFlag: true,
    managerName: "",
    managerPhone: "",
    managerPosition: "",
    managerEmail: "",
    targetAccountPayloads: [{ BRN: "", fileRefs: null }],
    address: "",
  });

  /**
   * 거래처 정보 필요 여부
   * 1. 파트너 유형이 해외가 아닐때
   * 2. 금전거래 여부가 true일때
   * 3. 거래처가 매핑(선택)됐을 때
   */
  const requiresAccountInfo =
    form.businessArea !== "foreign" && form.isFinancial && !isSelected;

  const handlePartnerCreate = () => {
    if (!validateCreatePartner(form, handleSnackbarOpen, requiresAccountInfo))
      return;

    /** 거래처 선택이 아니고, 금전거래 여부가 True일 때 파일 첨부 여부 확인 */
    if (requiresAccountInfo && form.isFinancial && !validateFile()) {
      handleSnackbarOpen("거래처 등록 필수 파일을 첨부해주세요.", "warning");
      return;
    }

    /** 운영서버에서 금전거래 여부가 True일때는 호웅님만 파트너 등록이 가능함 */
    if (APP_ENV === "production") {
      if (form.isFinancial && id !== FINANCE_TEAM_LEADER_ID) {
        handleSnackbarOpen(
          "권한이 없습니다. 금전거래가 필요한 파트너 등록은 경영지원실에 문의해주세요.",
          "warning"
        );
        return;
      }
    }

    const payload = setPayload({ form, isSelected, timeStamp });

    createPartner(payload);
  };

  /** 파트너 생성 > 정보 업데이트  */
  function handleInfoUpdate<T>(name: string, value: T | T[]) {
    setForm((prev) => ({ ...prev, [name]: value }));
  }

  /** 파트너 생성 > 거래처 정보 > 사업자 등록번호 업데이트 */
  const handleAutoCompleteSelect = (val: string | null, isNew?: boolean) => {
    if (val === null) {
      handleSnackbarOpen(
        "정상적인 사업자등록번호가 아닙니다. 해당 거래처 정보를 확인해주세요.",
        "warning"
      );
      return;
    }

    if (isNew) {
      setIsSelected(false);
      setIsCreate(true);
    }

    setForm({
      ...form,
      targetAccountPayloads: [
        {
          BRN: val.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3"),
          fileRefs: null,
        },
      ],
    });
  };

  return {
    requiresAccountInfo,
    handlePartnerCreate,

    form,
    handleInfoUpdate,

    isSelected,
    setIsSelected,

    isCreate,
    setIsCreate,

    handleAutoCompleteSelect,
  };
}
