import { useCallback, useMemo, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useAtom } from "jotai";

import ADMIN_TRADING_STATEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_TRADING_STATEMENT_QUERY";
import { Currency } from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { InvoiceData } from "@sellernote/_shared/src/types/forwarding/tradingStatement";
import {
  ExchangeRate,
  InvoiceType,
  TrelloBidDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";
import {
  getExchangeDate,
  getInvoiceExchangeRateList,
} from "@sellernote/_shared/src/utils/forwarding/tradingStatement";
import { FORWARDING_ADMIN_TRADING_STATEMENT_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/tradingStatement";

import {
  applyExchangeAndCargoUpdatesToAllFeeData,
  changeExchangeRateList,
} from "../utils";

/**
 * 환율정보의 로직을 분리하는 커스텀 훅
 *   - 환율은 initial, default, dynamic 세가지로 나뉜다.
 *     - initial: 거래명세서페이지 최상단에서 초기 기준날짜로 호출하는 환율 리스트 값(첫 호출 후 재호출 없음)
 *     - default: 환율값 변경이 있을 때 인풋에 파란색 하이라이트를 위한 기준이 되는 환율값
 *       - 거래명세서 저장 데이터가 없다면 초기 셋팅은 initial 호출 값으로 설정
 *       - 거래명세서 저장 데이터가 있다면 initial에 저장된 환율 값을 재적용한 환율 리스트를 설정
 *       - 첫 셋팅은 최상단 initial 호출 후 성공 핸들러에서 함
 *       - 적용하기 버튼을 누르는 경우 기준값을 변경한다.
 *     - dynamic: 기준날짜를 변경할 때마다 해당페이지에서 호출하는 환율리스트 값
 *       - 호출 후 성공 핸들러에서 폼 환율리스트를 다시 셋팅해준다.
 *       - 초기화를 하거나 기준날짜를 한번도 변경하지 않는 경우에는 호출 후 핸들러를 실행하지 않는다.
 *
 */
export default function useExchangeInfoLogic({
  invoiceState,
  shipmentDetailData,
  setValue,
  invoiceData,
  initialExchangeList,
  directAirRton,
  handleExchangeRateDate,
  exchangeRateDate,
}: {
  invoiceState: ApplyBidFormData;
  shipmentDetailData: TrelloBidDetail;
  setValue: UseFormSetValue<ApplyBidFormData>;
  invoiceData: InvoiceData | undefined;
  initialExchangeList: ExchangeRate[];
  directAirRton: number;
  handleExchangeRateDate: (date: string) => void;
  exchangeRateDate: string;
}) {
  const { invoiceType } = useParams<{
    invoiceType: InvoiceType;
  }>();

  const [defaultExchangeRateList, setDefaultExchangeRateList] = useAtom(
    FORWARDING_ADMIN_TRADING_STATEMENT_ATOMS.TRADING_STATEMENT_DEFAULT_EXCHANGE_RATE_LIST
  );

  /** 기준날짜 변경을 한번도 하지 않았는지 여부 */
  const [isUnchangedExchangeRateDate, setIsUnchangedExchangeRateDate] =
    useState(true);

  /**
   * DatePicker에 따라 변하는 변동 환율 데이터
   * - 환율변경시 모든 페이지가 리랜더링 되지 않게 환율을 해당 컴포넌트에서 따로 요청
   */
  const { data: dynamicExchangeRateList } =
    ADMIN_TRADING_STATEMENT_QUERY.useGetDynamicExchangeList({
      params: {
        all: true,
        date: exchangeRateDate,
        countryTarget: "KR",
      },
      // 성공 시 react-hook-form 상태의 환율을 업데이트
      onSuccess: (data) => {
        // 기준 날짜를 한번도 변경하지 않는 경우에는 폼의 환율리스트 상태를 변경하지 않는다.
        if (!isUnchangedExchangeRateDate) {
          // 부킹 커미션(리펀드) 에서는 1000원 고정
          if (invoiceType === "refund") {
            const refundExchangeRateList = data.map((v) => {
              return v.currency === "USD" ? { ...v, rate: 1000 } : v;
            });

            setValue("invoiceExchangeRateList", refundExchangeRateList);
            return;
          }

          setValue("invoiceExchangeRateList", data);
        }
      },
    });

  /** 기준날짜가 변경된 환율과 입력된 값이 다른 Currency만 모은 리스트  */
  const differentCurrencyListFromDynamicExchangeRate = useMemo(() => {
    return dynamicExchangeRateList?.reduce((acc, cur) => {
      const invoiceStateExchangeRate =
        invoiceState.invoiceExchangeRateList.find((exchange) => {
          return exchange.currency === cur.currency;
        })?.rate;

      if (invoiceStateExchangeRate && cur.rate !== invoiceStateExchangeRate) {
        acc = [...acc, cur.currency];
      }

      return acc;
    }, [] as Currency[]);
  }, [dynamicExchangeRateList, invoiceState.invoiceExchangeRateList]);

  /** 기준날짜가 변경된 환율과 입력된 값이 다른 통화가 하나라도 있는지 확인 */
  const isDifferentFromDynamicExchangeRate = useMemo(() => {
    return (
      differentCurrencyListFromDynamicExchangeRate &&
      differentCurrencyListFromDynamicExchangeRate.length !== 0
    );
  }, [differentCurrencyListFromDynamicExchangeRate]);

  const handleExchangeRateListChange = useCallback(
    (rate: number, currency: string) => {
      setValue(
        "invoiceExchangeRateList",
        changeExchangeRateList({
          currency,
          rate,
          invoiceState,
        })
      );
    },
    [invoiceState, setValue]
  );

  const handleApplyExchangeInfoClick = useCallback(() => {
    applyExchangeAndCargoUpdatesToAllFeeData({
      invoiceState,
      shipmentDetailData,
      directAirRton,
      setValue,
      cbm: invoiceState.cbm,
      ton: invoiceState.ton,
      exchangeRateList: invoiceState.invoiceExchangeRateList,
    });

    // 환율 기준 값을 현재 값으로 변경
    setDefaultExchangeRateList(invoiceState.invoiceExchangeRateList);
  }, [
    directAirRton,
    invoiceState,
    setDefaultExchangeRateList,
    setValue,
    shipmentDetailData,
  ]);

  const handleResetExchangeInfoClick = useCallback(() => {
    // 초기화 시에는 첫 랜더링처럼 환율 기준날짜를 변경하지 않은걸로 간주
    setIsUnchangedExchangeRateDate(true);

    handleExchangeRateDate(
      getExchangeDate({ invoiceData, shipmentDetailData })
    );

    // 폼 상태도 초기값 환율로 갱신
    setValue(
      "invoiceExchangeRateList",
      getInvoiceExchangeRateList({
        invoiceType,
        exchangeRateData: initialExchangeList,
        invoiceData,
      })
    );
  }, [
    handleExchangeRateDate,
    initialExchangeList,
    invoiceData,
    invoiceType,
    setValue,
    shipmentDetailData,
  ]);

  const isResetButtonDisabled = useMemo(() => {
    const initialInvoiceExchangeRateList = getInvoiceExchangeRateList({
      invoiceType,
      exchangeRateData: initialExchangeList,
      invoiceData,
    });

    return ["USD", "EUR", "CNY", "GBP"].every((currency) => {
      const initialInvoiceExchangeRate = initialInvoiceExchangeRateList.find(
        (item) => item.currency === currency
      )?.rate;

      const defaultExchangeRate = defaultExchangeRateList.find(
        (item) => item.currency === currency
      )?.rate;

      const invoiceExchangeRate = invoiceState.invoiceExchangeRateList.find(
        (item) => item.currency === currency
      )?.rate;

      return (
        initialInvoiceExchangeRate !== undefined &&
        defaultExchangeRate !== undefined &&
        invoiceExchangeRate !== undefined &&
        initialInvoiceExchangeRate === defaultExchangeRate &&
        invoiceExchangeRate === defaultExchangeRate
      );
    });
  }, [
    defaultExchangeRateList,
    initialExchangeList,
    invoiceData,
    invoiceState.invoiceExchangeRateList,
    invoiceType,
  ]);

  return {
    handleApplyExchangeInfoClick,
    handleResetExchangeInfoClick,
    handleExchangeRateListChange,
    differentCurrencyListFromDynamicExchangeRate,
    defaultExchangeRateList,
    isDifferentFromDynamicExchangeRate,
    setIsUnchangedExchangeRateDate,
    isResetButtonDisabled,
  };
}
