import React from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
} from "antd";

import { sendRequest } from "@sellernote/_shared/src/services/request";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

const RegisterCoupon = ({
  showsRegisterCouponModal,
  setShowsRegisterCouponModal,
  refetchCouponData,
}: {
  showsRegisterCouponModal: boolean;
  setShowsRegisterCouponModal: React.Dispatch<React.SetStateAction<boolean>>;
  refetchCouponData: () => void;
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const onFinish = async (values: any) => {
    try {
      await sendRequest({
        method: "post",
        path: `/coupon/campaign`,
        apiType: "ShipdaAdminDefaultNew",
        data: {
          ...values,
        },
      });
      handleSnackbarOpen("쿠폰을 등록했습니다.");
      refetchCouponData();
      setShowsRegisterCouponModal(false);
    } catch (err) {
      handleSnackbarOpen("쿠폰등록에 실패했습니다.", "error");
    }
  };

  return (
    <Modal
      width={800}
      title="캠페인 생성"
      destroyOnClose={true}
      visible={showsRegisterCouponModal}
      onCancel={() => setShowsRegisterCouponModal(false)}
      footer={[
        <Button key="back" onClick={() => setShowsRegisterCouponModal(false)}>
          돌아가기
        </Button>,
      ]}
    >
      <Form layout="vertical" onFinish={onFinish}>
        <Form.Item
          rules={[
            {
              required: true,
              message: "빈 칸을 채워주세요",
            },
          ]}
          label="쿠폰명"
          name="campaignName"
        >
          <Input style={{ width: 400 }}></Input>
        </Form.Item>
        <Form.Item label="쿠폰 설명" name="comment">
          <Input style={{ width: 200 }}></Input>
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: "빈 칸을 채워주세요",
            },
          ]}
          label="쿠폰 형식"
          name="kind"
        >
          <Select style={{ width: 200 }}>
            <Select.Option value="oneTime">일회용 쿠폰</Select.Option>
            <Select.Option value="fixed">일반 쿠폰</Select.Option>
            <Select.Option value="programatic">자동발급 쿠폰</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="쿠폰 생성 갯수" name="couponAmount">
          <InputNumber style={{ width: 200 }}></InputNumber>
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: "빈 칸을 채워주세요",
            },
          ]}
          label="할인 금액"
          name="discountAmount"
        >
          <InputNumber style={{ width: 200 }}></InputNumber>
        </Form.Item>
        <div style={{ display: "flex" }}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "빈 칸을 채워주세요",
              },
            ]}
            style={{ marginRight: 16 }}
            label="캠페인 시작일"
            name="startDate"
          >
            <DatePicker style={{ width: 200 }}></DatePicker>
          </Form.Item>

          <Form.Item
            rules={[
              {
                required: true,
                message: "빈 칸을 채워주세요",
              },
            ]}
            label="캠페인 종료일"
            name="endDate"
          >
            <DatePicker style={{ width: 200 }}></DatePicker>
          </Form.Item>
        </div>
        <Form.Item
          rules={[
            {
              required: true,
              message: "빈 칸을 채워주세요",
            },
          ]}
          label="쿠폰 유효기간"
          name="couponValidDays"
        >
          <InputNumber style={{ width: 200 }}></InputNumber>
        </Form.Item>
        <Form.Item>
          <Button
            style={{
              backgroundColor: "#1890FF",
              width: 154,
              height: 32,
              padding: 0,
              color: "#fff",
            }}
            htmlType="submit"
          >
            쿠폰 생성
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RegisterCoupon;
