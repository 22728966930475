const COLUMN_LIST = [
  {
    id: "ID",
    width: 95,
  },
  {
    id: "계산서 작성일",
    width: 200,
  },
  {
    id: "공급받는자 이메일",
    width: 230,
  },
  {
    id: "비고",
    width: 450,
  },
  {
    id: "발행상태",
    width: 100,
  },
  {
    id: "발행",
    width: 100,
  },
];

export { COLUMN_LIST };
