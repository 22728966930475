import { Box, Button, Card, Divider, Typography } from "@mui/material";

import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";

import FCL_HTML from "./FCL.html";
import LCL_HTML from "./LCL.html";
import useEmailBrochures from "./useEmailBrochures";

const COMMON_STYLE = {
  display: "flex",
  flexDirection: "column",
  padding: 3,
} as const;

function EmailBrochures() {
  const {
    showPreview: showPreviewFCL,
    Preview: PreviewFCL,
    copyCode: copyCodeFCL,
  } = useEmailBrochures("FCL 브로셔", FCL_HTML);

  const {
    showPreview: showPreviewLCL,
    Preview: PreviewLCL,
    copyCode: copyCodeLCL,
  } = useEmailBrochures("LCL 브로셔", LCL_HTML);

  return (
    <>
      <Layout breadcrumbs={["영업 관리"]} title={"이메일 브로셔"}>
        <Box display={"flex"} gap={2} mt={2}>
          <Card sx={COMMON_STYLE}>
            <Typography component={"span"}>FCL</Typography>

            <Divider flexItem orientation="horizontal" />

            <Box display={"flex"} alignItems={"center"} gap={2} mt={2}>
              <Button onClick={showPreviewFCL} size="small" variant="outlined">
                미리보기
              </Button>

              <Button onClick={copyCodeFCL} size="small" variant="contained">
                코드 복사
              </Button>
            </Box>

            {PreviewFCL}
          </Card>

          <Card sx={COMMON_STYLE}>
            <Typography component={"span"}>LCL</Typography>

            <Divider flexItem orientation="horizontal" />

            <Box display={"flex"} alignItems={"center"} gap={2} mt={2}>
              <Button onClick={showPreviewLCL} size="small" variant="outlined">
                미리보기
              </Button>

              <Button onClick={copyCodeLCL} size="small" variant="contained">
                코드 복사
              </Button>
            </Box>

            {PreviewLCL}
          </Card>
        </Box>
      </Layout>
    </>
  );
}

export default withRequireAuth(EmailBrochures);
