import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Checkbox, IconButton, Typography } from "@mui/material";
import { useAtom } from "jotai";
import { useSetRecoilState } from "recoil";

import useSet from "@sellernote/_shared/src/hooks/common/useSet";
import ADMIN_SETTLEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_SETTLEMENT_QUERY";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/_shared/src/states/forwarding/invoice";
import {
  SettlementSalesListData,
  SettlementSalesListStatus,
} from "@sellernote/_shared/src/types/forwarding/adminSettlement";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { getSettlementBLNumber } from "@sellernote/_shared/src/utils/forwarding/adminSettlement";
import { getInvoiceTypeKR } from "@sellernote/_shared/src/utils/forwarding/tradingStatement";
import CheckedListPriceSummary from "@sellernote/_shared-for-forwarding-admin/src/components/CheckedListPriceSummary";
import Table, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/_shared-for-forwarding-admin/src/components/Table";
import useObjectSet from "@sellernote/_shared-for-forwarding-admin/src/hooks/useObjectSet";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";
import { SETTLEMENT_ADMIN_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/settlement";

import { useSalesSettlementContext } from "../useSalesSettlementContext";
import CancelInvoiceModal from "./CancelInvoiceModal";
import DeleteSalesListModal from "./DeleteSalesListModal";
import ExcelDownloadModal from "./ExcelDownloadModal";
import IssueInvoiceModal from "./IssueInvoiceModal";
import UpdateInvoiceIssuedDate from "./UpdateInvoiceIssuedDate";
import useSalesSettlementTableFilter from "./useSalesSettlementTableFilter";

const SalesSettlementTable = () => {
  const { handleSnackbarOpen } = useSnackbar();

  const setTrelloCardId = useSetRecoilState(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const [salesManagementShipmentIdFilter, setSalesManagementShipmentIdFilter] =
    useAtom(SETTLEMENT_ADMIN_ATOMS.SALES_MANAGEMENT_SHIPMENT_ID_FILTER);

  /**
   * 매출관리 페이지에서 벗어날 때, 트렐로 상세 > 매출관리 > 거래명세서 > 취소 요청시 변경한 상태값을 원복함
   */
  useEffect(() => {
    return () => {
      if (salesManagementShipmentIdFilter) {
        setSalesManagementShipmentIdFilter(undefined);
      }
    };
  }, [salesManagementShipmentIdFilter, setSalesManagementShipmentIdFilter]);

  const { setFilteredSettlementListData } = useSalesSettlementContext();

  const history = useHistory();

  const [issueInvoiceModalVisible, setIssueInvoiceModalVisible] =
    useState(false);

  const [deleteSalesListModalVisible, setDeleteSalesListModalVisible] =
    useState(false);

  const [openCancelInvoiceModal, setOpenCancelInvoiceModal] = useState(false);

  const [deleteSalesListId, setDeleteSalesListId] = useState(0);

  const [cancelInvoiceId, setCancelInvoiceId] = useState(0);

  const [isAutoIssueInvoice, setIsAutoIssueInvoice] = useState(false);

  const [invoiceEmail, setInvoiceEmail] = useState("");

  const [showsExcelDownloadModal, setShowsExcelDownloadModal] = useState(false);

  const [perPage, setPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);

  const {
    array: checkBoxArr,
    set: checkBoxSet,
    toggle: toggleCheckBox,
    init: initCheckBoxSet,
  } = useSet<number>();

  const {
    array: vatPriceArr,
    toggle: toggleVat,
    init: initCheckBoxSetForVat,
  } = useObjectSet<number, number>();

  const {
    array: totalPriceArr,
    toggle: toggleTotalPrice,
    init: initCheckboxSetForTotalPrice,
  } = useObjectSet<number, number>();

  const vatPriceSum = vatPriceArr.reduce((acc, obj) => {
    const value = Object.values(obj)[0];

    return acc + value;
  }, 0);

  const totalPriceSum = totalPriceArr.reduce((acc, obj) => {
    const value = Object.values(obj)[0];

    return acc + value;
  }, 0);

  const {
    SalesSettlementTableFilter,
    invoiceTypeSelectFilterValue,
    hasDepositSelectFilterValue,
    isIssuedSelectFilterValue,
    dateSearchType,
    startDate,
    endDate,
    debouncedSearchTermWithObject,
    forwardingManagerSelectFilterValue,
    hasRequestIssueDateSelectFilterValue,
    invoiceStatusSelectFilterValue,
  } = useSalesSettlementTableFilter();

  type CellId =
    | keyof SettlementSalesListData
    | "bidCreatedAt"
    | "subjectType"
    | "blNumber"
    | "forwardingManager"
    | "checkbox"
    | "cancel"
    | "issueType"
    | "teamName"
    | "invoiceEmail"
    | "company"
    | "status"
    | "isRequestCanceled";

  const fetchListParams = {
    bidId: salesManagementShipmentIdFilter
      ? salesManagementShipmentIdFilter
      : undefined,
    page: currentPage,
    perPage,
    isIssued: isIssuedSelectFilterValue,
    forwardingManagerId: forwardingManagerSelectFilterValue,
    invoiceType: invoiceTypeSelectFilterValue,
    hasRequestIssueDate: hasRequestIssueDateSelectFilterValue,
    hasDepositDate: hasDepositSelectFilterValue,
    ...debouncedSearchTermWithObject,
    ...(dateSearchType === "createdAt" &&
      startDate &&
      endDate && {
        createdAtFromDate: startDate,
        createdAtToDate: endDate,
      }),
    ...(dateSearchType === "depositDate" &&
      startDate &&
      endDate && {
        depositFromDate: startDate,
        depositToDate: endDate,
      }),
    ...(dateSearchType === "issueDate" &&
      startDate &&
      endDate && {
        fromIssueDate: startDate,
        toIssueDate: endDate,
      }),
    ...(invoiceStatusSelectFilterValue !== "null" && {
      status: invoiceStatusSelectFilterValue,
    }),
  };

  const { data: salesListData, refetch: refetchAdminSettlementSalesList } =
    ADMIN_SETTLEMENT_QUERY.useGetAdminSettlementSalesList({
      params: fetchListParams,
      onSuccess: () => {
        initCheckBoxSet();
        initCheckBoxSetForVat();
        initCheckboxSetForTotalPrice();
      },
    });

  const checkedListData = useMemo(() => {
    return salesListData?.list.filter((v) => {
      return checkBoxArr.includes(v.id);
    });
  }, [checkBoxArr, salesListData?.list]);

  const isOneRecipient = useMemo(() => {
    if (!checkedListData) {
      return false;
    }

    return checkedListData.every((v) => {
      // 같은 수신자가 두명 이상인지만 확인하면 되므로 앞 데이터를 기준으로 함
      return v.recipient === checkedListData[0].recipient;
    });
  }, [checkedListData]);

  const getInvoiceEmail = useCallback(() => {
    if (!checkedListData) {
      return "";
    }

    if (isOneRecipient) {
      return (
        // 수신자가 이미 단일이므로 앞 데이터를 사용
        checkedListData[0].team.invoiceEmail || checkedListData[0].team.email
      );
    }

    return "";
  }, [checkedListData, isOneRecipient]);

  const handleIssueInvoiceModalOpen = useCallback(() => {
    if (checkBoxArr.length < 1) {
      handleSnackbarOpen("체크된 리스트가 없습니다.", "warning");
      return;
    }

    const filteredSalesListData = salesListData?.list.filter((list) => {
      return checkBoxArr.includes(list.id);
    });

    setFilteredSettlementListData(filteredSalesListData);

    setInvoiceEmail(getInvoiceEmail());

    setIssueInvoiceModalVisible(true);
  }, [
    checkBoxArr,
    getInvoiceEmail,
    handleSnackbarOpen,
    salesListData?.list,
    setFilteredSettlementListData,
  ]);

  const handleDeleteSalesListModalOpen = useCallback((id: number) => {
    return () => {
      setDeleteSalesListModalVisible(true);
      setDeleteSalesListId(id);
    };
  }, []);

  const handleCancelInvoiceModalOpen = useCallback(
    (id: number, mgtKey: string | null) => {
      return () => {
        if (mgtKey) {
          setIsAutoIssueInvoice(true);
        }
        setCancelInvoiceId(id);
        setOpenCancelInvoiceModal(true);
      };
    },
    []
  );

  const getIssueInvoiceType = useCallback(
    (isIssued: boolean, mgtKey: string | null) => {
      if (!isIssued) {
        return "-";
      }
      if (!mgtKey) {
        return "수동";
      }
      return "자동";
    },
    []
  );

  const getKrInvoiceStatus = (status: SettlementSalesListStatus) => {
    if (status === null) return "-";

    return {
      rejected: "발행실패",
      sending: "전송중",
      done: "전송성공",
      failed: "전송실패",
      requestCanceled: "취소요청",
      canceled: "발행취소",
    }[status];
  };

  const headCells: TableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "checkbox",
        disablePadding: false,
        label: (
          <Checkbox
            onClick={() => {
              if (checkBoxSet.size) {
                initCheckBoxSet();
                initCheckBoxSetForVat([]);
                initCheckboxSetForTotalPrice([]);
              } else {
                const idsToCheck = salesListData?.list.map((v) => {
                  return v.id;
                });

                const vatPrice = salesListData?.list.map((v) => {
                  return {
                    [v.id]: v.vatPrice,
                  };
                });

                const totalPrice = salesListData?.list.map((v) => {
                  return {
                    [v.id]: v.totalPrice,
                  };
                });

                if (!vatPrice && !totalPrice) return;

                initCheckBoxSetForVat(vatPrice);
                initCheckboxSetForTotalPrice(totalPrice);
                initCheckBoxSet(idsToCheck);
              }
            }}
            checked={!!checkBoxSet.size}
          />
        ),
      },
      {
        id: "isIssued",
        disablePadding: false,
        label: "계산서 발행",
        width: 80,
      },
      {
        id: "bidId",
        disablePadding: false,
        label: "의뢰번호",
        width: 100,
      },
      {
        id: "bidCreatedAt",
        disablePadding: false,
        label: "견적생성일",
        width: 120,
      },
      {
        id: "invoiceType",
        disablePadding: false,
        label: "명세서",
        width: 80,
      },
      {
        id: "BRN",
        disablePadding: false,
        label: "사업자등록번호",
        width: 140,
      },
      {
        id: "invoiceEmail",
        disablePadding: false,
        label: "세금계산서이메일",
        width: 150,
      },
      {
        id: "recipient",
        disablePadding: false,
        label: "실화주명",
        width: 120,
      },
      {
        id: "company",
        disablePadding: false,
        label: "회사명",
        width: 120,
      },
      {
        id: "issueInvoiceType",
        disablePadding: false,
        label: "구분",
        width: 70,
      },
      {
        id: "subjectType",
        disablePadding: false,
        label: "품목",
        width: 130,
      },
      {
        id: "totalPrice",
        disablePadding: false,
        label: "공급가액",
        width: 100,
      },
      {
        id: "vatPrice",
        disablePadding: false,
        label: "세액",
        width: 100,
      },
      {
        id: "finalPrice",
        disablePadding: false,
        label: "합계",
        width: 100,
      },
      {
        id: "blNumber",
        disablePadding: false,
        label: "BL NO",
        width: 170,
      },
      {
        id: "createdAt",
        disablePadding: false,
        label: "생성일",
        width: 120,
      },
      {
        id: "depositDate",
        disablePadding: false,
        label: "입금일",
        width: 120,
      },
      {
        id: "issueDate",
        disablePadding: false,
        label: "계산서발행일",
        width: 120,
      },
      {
        id: "forwardingManager",
        disablePadding: false,
        label: "포워딩 담당자",
        width: 80,
      },
      {
        id: "issueType",
        disablePadding: false,
        label: "발행타입",
        width: 90,
      },

      {
        id: "requestIssueDate",
        disablePadding: false,
        label: "고객 요청일",
        width: 120,
      },
      {
        id: "requestUser",
        disablePadding: false,
        label: "요청인",
        width: 100,
      },
      {
        id: "status",
        disablePadding: false,
        label: "상태",
        width: 100,
      },
      {
        id: "cancel",
        disablePadding: false,
        label: "삭제",
        width: 80,
      },
    ];
  }, [
    checkBoxSet.size,
    initCheckBoxSet,
    initCheckBoxSetForVat,
    initCheckboxSetForTotalPrice,
    salesListData?.list,
  ]);

  const rows = useMemo(() => {
    if (!salesListData?.list) return [];

    return salesListData?.list.map((v) => {
      const row: TableBodyRow<CellId> = {
        checkbox: (
          <Checkbox
            checked={checkBoxSet.has(v.id)}
            onClick={(e) => {
              e.stopPropagation();
              toggleCheckBox(v.id);
              toggleVat({
                [v.id]: v.vatPrice,
              });
              toggleTotalPrice({
                [v.id]: v.totalPrice,
              });
            }}
          />
        ),
        isIssued: (
          <Checkbox
            disabled={!v.isIssued}
            checked={v.isIssued}
            onClick={handleCancelInvoiceModalOpen(v.id, v.mgtKey)}
          />
        ),
        bidId: (
          <Button
            variant="text"
            onClick={() => {
              setTrelloCardId(v.bidId);
              // TODO: 특송화물 조건이 추가되어야 한다.
              history.push(`/trello/${v.bid.isImport ? "general" : "export"}`);
            }}
          >
            {v.bidId}
          </Button>
        ),
        bidCreatedAt: toFormattedDate(v.bid.createdAt, "YYYY-MM-DD"),
        invoiceType: getInvoiceTypeKR(v.invoiceType),
        BRN: v.BRN,
        invoiceEmail: v.team.invoiceEmail,
        recipient: v.recipient,
        company: v.team.company,
        issueInvoiceType: v.issueInvoiceType === "taxation" ? "01" : "02",
        subjectType:
          v.issueInvoiceType === "taxation" ? "국내부대비용 외" : "해상운임 외",
        totalPrice: toThousandUnitFormat(v.totalPrice),
        vatPrice: toThousandUnitFormat(v.vatPrice),
        finalPrice: toThousandUnitFormat(v.finalPrice),
        blNumber: getSettlementBLNumber(v.management),
        createdAt: toFormattedDate(v.createdAt, "YYYY-MM-DD"),
        depositDate: toFormattedDate(v.depositDate, "YYYY-MM-DD"),
        issueDate: !v.issueDate
          ? ""
          : toFormattedDate(v.issueDate, "YYYY-MM-DD"),
        forwardingManager: v.forwardingManager?.name || "-",
        issueType: getIssueInvoiceType(v.isIssued, v.mgtKey),

        requestIssueDate: toFormattedDate(v.requestIssueDate, "YYYY-MM-DD"),
        requestUser: v.requestUser?.name,
        status: getKrInvoiceStatus(v.status),
        cancel: (
          <IconButton
            aria-label="delete"
            onClick={handleDeleteSalesListModalOpen(v.id)}
          >
            <DeleteIcon />
          </IconButton>
        ),
      };

      return row;
    });
  }, [
    checkBoxSet,
    getIssueInvoiceType,
    handleCancelInvoiceModalOpen,
    handleDeleteSalesListModalOpen,
    history,
    salesListData?.list,
    setTrelloCardId,
    toggleCheckBox,
    toggleTotalPrice,
    toggleVat,
  ]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: 1,
          pt: 1,
          px: 1,
          background: "#fff",
        }}
      >
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
          {SalesSettlementTableFilter}

          <UpdateInvoiceIssuedDate
            checkIds={checkBoxArr}
            initCheckBoxSet={initCheckBoxSet}
            refetchAdminSettlementSalesList={refetchAdminSettlementSalesList}
          />
        </Box>

        <Box sx={{ display: "flex", gap: "4px", alignSelf: "flex-start" }}>
          <Button
            sx={{ height: "40px" }}
            variant="contained"
            color="success"
            onClick={handleIssueInvoiceModalOpen}
          >
            계산서 발행
          </Button>

          <Button
            sx={{ height: "40px" }}
            variant="contained"
            color="secondary"
            onClick={() => setShowsExcelDownloadModal(true)}
          >
            엑셀 다운로드
          </Button>
        </Box>
      </Box>

      <Box sx={{ p: 1, background: "#fff" }}>
        <Table
          scrollbarSize="large"
          showsTableVerticalLine={true}
          headCells={headCells}
          rows={rows}
          toolbarItems={{
            left: [
              <Typography key="total">
                총 {salesListData?.total || 0}건
              </Typography>,
            ],
          }}
          pagination={{
            totalCount: salesListData?.total || 0,
            perPage,
            setPerPage,
            currentPage,
            setCurrentPage,
          }}
        />
      </Box>

      <IssueInvoiceModal
        issueInvoiceModalVisible={issueInvoiceModalVisible}
        setIssueInvoiceModalVisible={setIssueInvoiceModalVisible}
        checkIds={checkBoxArr}
        refetchAdminSettlementSalesList={refetchAdminSettlementSalesList}
        initCheckBoxSet={initCheckBoxSet}
        invoiceEmail={invoiceEmail}
        isOneRecipient={isOneRecipient}
      />

      <DeleteSalesListModal
        deleteSalesListModalModalVisible={deleteSalesListModalVisible}
        setDeleteSalesListModalModalVisible={setDeleteSalesListModalVisible}
        deleteSalesListId={deleteSalesListId}
        setDeleteSalesListId={setDeleteSalesListId}
        refetchAdminSettlementSalesList={refetchAdminSettlementSalesList}
      />

      <CancelInvoiceModal
        openCancelInvoiceModal={openCancelInvoiceModal}
        setOpenCancelInvoiceModal={setOpenCancelInvoiceModal}
        cancelInvoiceId={cancelInvoiceId}
        refetchAdminSettlementSalesList={refetchAdminSettlementSalesList}
        setCancelInvoiceId={setCancelInvoiceId}
        isAutoIssueInvoice={isAutoIssueInvoice}
        setIsAutoIssueInvoice={setIsAutoIssueInvoice}
      />

      {showsExcelDownloadModal && (
        <ExcelDownloadModal
          showsExcelDownloadModal={showsExcelDownloadModal}
          setShowsExcelDownloadModal={setShowsExcelDownloadModal}
          fetchListParams={fetchListParams}
        />
      )}

      <CheckedListPriceSummary
        numberCheckedItems={checkBoxArr.length}
        totalPrice={totalPriceSum}
        vatPrice={vatPriceSum}
      />
    </Box>
  );
};

export default SalesSettlementTable;
