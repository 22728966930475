import { Box } from "@mui/material";

export default function PartnerIcon() {
  return (
    <Box
      sx={{
        height: 110,
        width: 110,
      }}
      alignSelf={"center"}
      mt={2}
      component="img"
      alt="partner-icon"
      src="/images/partner/partner.webp"
    />
  );
}
