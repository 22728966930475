import { Dispatch, SetStateAction } from "react";
import { Grid, Typography } from "@mui/material";

import ADMIN_SETTLEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_SETTLEMENT_QUERY";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";

import PartnerCompanyTable from "./PartnerCompanyTable";
import SearchWithdrawalPartner from "./SearchWithdrawalPartner";

function MatchWithdrawalBidIdModal({
  showsMatchWithdrawalBidIdModal,
  setShowsMatchWithdrawalBidIdModal,
  partnerId,
  paymentInvoiceId,
  setPartnerId,
  withdrawalAmount,
  refetchSettlementWithdrawalList,
}: {
  showsMatchWithdrawalBidIdModal: boolean;
  setShowsMatchWithdrawalBidIdModal: Dispatch<SetStateAction<boolean>>;
  partnerId: number;
  paymentInvoiceId: number;
  setPartnerId: Dispatch<SetStateAction<number>>;
  withdrawalAmount: number;
  refetchSettlementWithdrawalList: () => void;
}) {
  const {
    data: settlementWithdrawalPartners,
    refetch: refetchSettlementWithdrawalPartners,
  } = ADMIN_SETTLEMENT_QUERY.useGetAdminSettlementWithdrawalPartners({
    partnerCompanyId: partnerId,
    paymentInvoiceId,
  });

  const handleModalClose = () => {
    setShowsMatchWithdrawalBidIdModal(false);
    refetchSettlementWithdrawalList();
  };

  return (
    <Modal
      isOpened={showsMatchWithdrawalBidIdModal}
      handleClose={handleModalClose}
      modalBody={
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography
              variant="h6"
              component="div"
              sx={{ textAlign: "center" }}
            >
              출금 의뢰번호 매칭
            </Typography>
          </Grid>

          {!partnerId && (
            <Grid item>
              <Typography
                variant="body2"
                component="div"
                sx={{ textAlign: "center" }}
              >
                회사명을 검색 후 선택해 주세요.
              </Typography>
            </Grid>
          )}

          <Grid item>
            <SearchWithdrawalPartner
              partnerId={partnerId}
              setPartnerId={setPartnerId}
            />
          </Grid>

          {settlementWithdrawalPartners &&
            !!partnerId &&
            settlementWithdrawalPartners.length === 0 && (
              <Grid item>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ textAlign: "center" }}
                >
                  출금확인이 필요한 거래명세서가 없습니다
                </Typography>
              </Grid>
            )}

          {settlementWithdrawalPartners &&
            settlementWithdrawalPartners.length > 0 && (
              <Grid item>
                <PartnerCompanyTable
                  settlementWithdrawalPartners={settlementWithdrawalPartners}
                  withdrawalAmount={withdrawalAmount}
                  paymentInvoiceId={paymentInvoiceId}
                  handleModalClose={handleModalClose}
                  refetchSettlementWithdrawalPartners={
                    refetchSettlementWithdrawalPartners
                  }
                />
              </Grid>
            )}
        </Grid>
      }
    />
  );
}

export default MatchWithdrawalBidIdModal;
