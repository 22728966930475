import { ChangeEvent, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Input,
  Typography,
} from "@mui/material";

import PARTNER_QUERY from "@sellernote/_shared/src/queries/forwarding/PARTNER_QUERY";
import { AdminBidTransportMode } from "@sellernote/_shared/src/types/forwarding/adminBid";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";
import UploadModal from "@sellernote/_shared-for-forwarding-admin/src/containers/UploadModal";

import { PartnerData, SelectedPartnerInfo } from "./types";

import CountryInCharge from "./CountryInCharge";
import TransportModeOption from "./TransportModeOption";

const EditPartnerInfoModal = ({
  data,
  listDataIndex,
  setShowEditPartnerInfoModal,
  showEditPartnerInfoModal,
}: PartnerData) => {
  const queryClient = useQueryClient();

  const selectedPartner = useMemo(() => {
    return data[listDataIndex];
  }, [data, listDataIndex]);

  const [form, setForm] = useState<SelectedPartnerInfo>({
    companyName: selectedPartner.name, // 회사명
    monthCalculate: selectedPartner.settlementFlag, // 월 정산 여부
    bank: selectedPartner.bankName, // 은행
    accountNumber: selectedPartner.accountNumber, // 계좌번호
    isBill: selectedPartner.invoiceIssueFlag, // 계산서 발행 여부
    brn: selectedPartner.BRNNumber, // 사업자등록번호
    countryInChargeList: selectedPartner.countries, // 담당 국가
    transportModeList: selectedPartner.transportModes, // 운송 유형
  });

  const [showsUploadModal, setShowsUploadModal] = useState(false);

  /**
   * 담당 국가, 운송 유형은 해당 함수에서 핸들링하지 않음
   */
  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    setForm({ ...form, [name]: value });
  };

  const handleCountryInChargeEdit = (countryList: string[]) => {
    setForm({ ...form, countryInChargeList: countryList });
  };

  const handleTransportModeEdit = (
    transportModeList: AdminBidTransportMode[]
  ) => {
    setForm({ ...form, transportModeList: transportModeList });
  };

  const isCountryInChargeContainsAll = form.countryInChargeList.includes("ALL");

  const isTransportModeContainsAll = form.transportModeList.includes("ALL");

  const { mutate: editPartner, ResponseHandler: ResponseHandlerOfEditPartner } =
    PARTNER_QUERY.useEditPartner({
      successModalInfo: {
        handleConfirmSuccess: (initQuery) => {
          initQuery();
          setShowEditPartnerInfoModal(false);
          queryClient.invalidateQueries(["forwarding", "partner", "list"]);
        },
        customizeMessage: () => ({
          title: "수정이 완료됐습니다.",
        }),
      },
      companyNumber: data[listDataIndex].id,
    });

  const handlePartnerInfoUpdate = () => {
    editPartner({
      settlementFlag: form.monthCalculate,
      bankName: form.bank || null,
      accountNumber: form.accountNumber || null,
      invoiceIssueFlag: form.isBill,
      BRNNumber: form.brn || null,
      name: form.companyName,
      countries: form.countryInChargeList,
      transportModes: form.transportModeList,
    });
  };

  return (
    <Modal
      isOpened={showEditPartnerInfoModal}
      handleClose={() => setShowEditPartnerInfoModal(false)}
      modalBody={
        <>
          <Box
            style={{
              width: 300,
              display: "flex",
              flexDirection: "column",
              gap: 20,
            }}
          >
            <Box>
              <Typography>회사명</Typography>

              <Input
                name="companyName"
                value={form.companyName}
                onChange={handleFormChange}
                style={{ width: "100%" }}
              />
            </Box>

            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    name="monthCalculate"
                    checked={form.monthCalculate}
                    onChange={handleFormChange}
                  />
                }
                label="월 정산"
              />
            </Box>

            <Box>
              <Typography>은행</Typography>

              <Input
                name="bank"
                value={form.bank === null ? "" : form.bank}
                onChange={handleFormChange}
                style={{ width: "100%" }}
              />
            </Box>

            <Box>
              <Typography>계좌번호</Typography>

              <Input
                name="accountNumber"
                value={form.accountNumber === null ? "" : form.accountNumber}
                onChange={handleFormChange}
                style={{ width: "100%" }}
              />
            </Box>

            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    name="isBill"
                    checked={form.isBill}
                    onChange={handleFormChange}
                  />
                }
                label="계산서 발행"
              />
            </Box>

            <Box>
              <Typography>은행 사업자 등록번호</Typography>

              <Input
                name="brn"
                value={form.brn === null ? "" : form.brn}
                onChange={handleFormChange}
                style={{ width: "100%" }}
              />
            </Box>

            <Box>
              <Typography>사업자등록증</Typography>

              <Button
                variant="outlined"
                style={{ marginTop: 10 }}
                onClick={() => setShowsUploadModal(true)}
              >
                등록하기
              </Button>
            </Box>

            <Box>
              <Typography>담당 국가</Typography>

              <CountryInCharge
                countryInChargeList={form.countryInChargeList}
                onCountryInChargeEdit={handleCountryInChargeEdit}
                isCountryInChargeContainsAll={isCountryInChargeContainsAll}
              />
            </Box>

            <Box>
              <Typography>운송유형</Typography>

              <TransportModeOption
                transportModeList={form.transportModeList}
                onTransportModeEdit={handleTransportModeEdit}
                isTransportModeContainsAll={isTransportModeContainsAll}
              />
            </Box>
          </Box>

          <Button
            variant="contained"
            style={{ marginTop: 32, alignItems: "center" }}
            onClick={handlePartnerInfoUpdate}
          >
            저장하기
          </Button>

          <UploadModal
            setShowsUploadModal={setShowsUploadModal}
            showsUploadModal={showsUploadModal}
            type={"partnerBrn"}
            dataType={"partner"}
            isPermission={false}
            id={data[listDataIndex].id}
          />

          {ResponseHandlerOfEditPartner}
        </>
      }
    />
  );
};

export default EditPartnerInfoModal;
