import { Divider, Typography } from "@mui/material";

export default function Header() {
  return (
    <>
      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
        파트너 관리
      </Typography>

      <Divider sx={{ mt: 1, mb: 2 }} />
    </>
  );
}
