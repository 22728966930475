import { useEffect, useRef } from "react";
import { CSVLink } from "react-csv";
import { useQueryClient } from "react-query";
import { Button, CircularProgress } from "@mui/material";

import { GET_ADMIN_SETTLEMENT_PURCHASE_LIST_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminSettlement";
import ADMIN_SETTLEMENT_QUERY, {
  ADMIN_SETTLEMENT_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_SETTLEMENT_QUERY";

const PurchaseListExcelDownload = ({
  fetchListParams,
}: {
  fetchListParams: GET_ADMIN_SETTLEMENT_PURCHASE_LIST_REQ;
}) => {
  const queryClient = useQueryClient();

  const csvLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const {
    excelList,
    refetch: refetchExcelData,
    isLoading,
  } = ADMIN_SETTLEMENT_QUERY.useGetAdminSettlementPurchaseExcelList(
    fetchListParams
  );

  useEffect(() => {
    if (!excelList) {
      return;
    }

    csvLink?.current?.link.click();

    queryClient.resetQueries(
      ADMIN_SETTLEMENT_QUERY_KEY_GEN.getAdminSettlementPurchaseExcelList(
        fetchListParams
      )
    );
  }, [excelList, fetchListParams, queryClient]);

  const fetchPurchaseExcelList = () => {
    refetchExcelData();
  };

  return (
    <>
      <Button
        variant={"outlined"}
        disabled={isLoading}
        onClick={fetchPurchaseExcelList}
        color="success"
        sx={{ height: "40px" }}
      >
        {isLoading ? (
          <CircularProgress color="success" size={24} />
        ) : (
          "엑셀 다운로드"
        )}
      </Button>

      {excelList && (
        <CSVLink
          ref={csvLink}
          headers={[
            { label: "의뢰번호", key: "bidId" },
            { label: "mbl", key: "mbl" },
            { label: "hbl", key: "hbl" },
            { label: "의뢰유저명", key: "userName" },
            { label: "파트너명", key: "partnerName" },
            { label: "견적생성일", key: "bidCreatedAt" },
            { label: "구분", key: "companyType" },
            { label: "사업자등록번호", key: "BRN" },
            { label: "통화", key: "currency" },
            { label: "외화", key: "foreignFinalPrice" },
            { label: "환율", key: "exchangeRate" },
            { label: "합계", key: "finalPrice" },
            { label: "담당자", key: "forwardingManager" },
            { label: "ETD", key: "ETD" },
            { label: "ETA", key: "ETA" },
            { label: "리스트 생성일", key: "createdAt" },
            { label: "INV. No", key: "withdrawalInvoiceId" },
            { label: "INV. Date", key: "withdrawalInvoiceDate" },
            { label: "출금완료", key: "isWithdrawn" },
            { label: "출금일", key: "withdrawalDate" },
            { label: "updated date", key: "lastUpdateAt" },
          ]}
          data={excelList}
          filename="매입관리리스트.csv"
          target="_blank"
        >
          엑셀 다운로드
        </CSVLink>
      )}
    </>
  );
};

export default PurchaseListExcelDownload;
