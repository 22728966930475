import { useCallback } from "react";
import { useAtomValue } from "jotai";

import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";
import { FORWARDING_ADMIN_TRADING_STATEMENT_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/tradingStatement";

export default function useCommonErrorMessage(isTempSave?: boolean) {
  const { handleSnackbarOpen } = useSnackbar();

  const defaultExchangeRateList = useAtomValue(
    FORWARDING_ADMIN_TRADING_STATEMENT_ATOMS.TRADING_STATEMENT_DEFAULT_EXCHANGE_RATE_LIST
  );

  /**
   * 조건에 걸리는 경우 스낵바를 호출하고 true를 리턴해주는 함수
   */
  const checkAndShowCommonErrorMessage = useCallback(
    ({
      /** 화물정보 수정 여부 */
      isCargoInfoUnchanged,
      /** 환율정보 수정 여부 */
      isExchangeInfoUnchanged,
      /** (거래, 창고료, 기타, 부킹커미션 중 택일)명세서 발행 여부 */
      isIssuedInvoice,
      /** 거래명세서 발행 여부 */
      isIssuedBidInvoice,
      /** 사업자 등록번호 검증여부 */
      isValidateBRN,
      /** 고객사 발송을 할 수 있는지 여부 */
      canBeSendToUser,
      /** 관세사 발송을 할 수 있는지 여부 */
      canBeSendToCustomsBroker,
      /** 부킹커미션 발송을 할 수 있는지 여부 */
      canBeSendToBookingCommission,
      /** directAirRton을 입력했는지 여부 */
      hasDirectAirRton,
    }: {
      isCargoInfoUnchanged: boolean;
      isExchangeInfoUnchanged: boolean;
      isIssuedInvoice?: boolean;
      isIssuedBidInvoice?: boolean;
      isValidateBRN?: boolean;
      canBeSendToUser?: boolean;
      canBeSendToCustomsBroker?: boolean;
      canBeSendToBookingCommission?: boolean;
      hasDirectAirRton?: boolean;
    }) => {
      /**
       * 기본환율정보 선택 여부
       * - 어차피 공통으로 사용하는 atom에서 가져오는 상태라 prop으로 전달받지않고 이 hook에서 바로 사용한다.
       */
      const hasDefaultExchangeRateList = !!defaultExchangeRateList?.length;

      const showErrorSnackbar = (message: string) => {
        handleSnackbarOpen(message, "error");
        return true;
      };

      if (
        canBeSendToCustomsBroker ||
        canBeSendToUser ||
        canBeSendToBookingCommission
      ) {
        return showErrorSnackbar(
          "발행할 수 있는 의뢰상태가 아니거나 권한이 없습니다."
        );
      }

      if (!isCargoInfoUnchanged) {
        return showErrorSnackbar("변경된 화물 정보를 적용해주세요.");
      }

      if (!isTempSave && !hasDefaultExchangeRateList) {
        return showErrorSnackbar("환율 정보를 입력해주세요.");
      }

      if (!isExchangeInfoUnchanged) {
        return showErrorSnackbar("변경된 환율 정보를 적용해주세요.");
      }

      /** API에서 관세사 발송은 창고료, 기타, 부킹 커미션일 때에도 기본 거래명세서를 체크해서 프론트에서도 검증을 추가  */
      if (isIssuedInvoice || isIssuedBidInvoice) {
        return showErrorSnackbar("이미 발행요청 된 명세서입니다.");
      }

      /** 고객사 발송에서는 해당 명세서의 발행여부만 파악함 */
      if (isIssuedInvoice) {
        return showErrorSnackbar("이미 발행요청 된 명세서입니다.");
      }

      if (isValidateBRN) {
        return showErrorSnackbar("정상적인 사업자등록번호가 아닙니다.");
      }

      if (hasDirectAirRton) {
        return showErrorSnackbar("C.W 값을 입력해주세요.");
      }
    },
    [defaultExchangeRateList?.length, handleSnackbarOpen]
  );

  return { checkAndShowCommonErrorMessage };
}
