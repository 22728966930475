import { useMemo } from "react";
import { Box } from "@mui/material";

import {
  GET_ADMIN_PROMOTION_INFO_REQ_DATE_KIND,
  GET_ADMIN_PROMOTION_INFO_REQ_SEARCH_KIND,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/adminPromotion";
import { AdminPromotionStatus } from "@sellernote/_shared/src/types/forwarding/adminPromotion";
import useRadioGroupFilter, {
  RadioGroupFilterOptionList,
} from "@sellernote/_shared-for-forwarding-admin/src/hooks/useRadioGroupFilter";
import useSearchWithDate, {
  SearchWithDateTypeOption,
} from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSearchWithDate";
import useSearchWithTerm, {
  TermSearchType,
} from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSearchWithTerm";
import useSelectFilter from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSelectFilter";

const dateSearchTypeOptionList: SearchWithDateTypeOption<GET_ADMIN_PROMOTION_INFO_REQ_DATE_KIND>[] =
  [
    {
      label: "신청일",
      value: "promotionDetailDate",
    },
    {
      label: "가입일",
      value: "userDate",
    },
  ];

const termSearchTypeOptionList: TermSearchType<GET_ADMIN_PROMOTION_INFO_REQ_SEARCH_KIND>[] =
  [
    {
      label: "회사명",
      value: "company",
    },
    {
      label: "고객 이름",
      value: "userName",
    },
    {
      label: "고객 번호",
      value: "userPhone",
    },
    {
      label: "고객 이메일",
      value: "userEmail",
    },
    {
      label: "의뢰번호",
      value: "bidId",
    },
  ];

export const radioGroupOptionList: RadioGroupFilterOptionList<
  AdminPromotionStatus | "all"
>[] = [
  {
    label: "전체",
    value: "all",
  },
  {
    label: "진행 중",
    value: "inProgress",
  },
  {
    label: "대기",
    value: "waiting",
  },
  {
    label: "보류",
    value: "pending",
  },
  {
    label: "취소",
    value: "canceled",
  },
  {
    label: "완료",
    value: "finished",
  },
];

export default function usePromotionTableFilter({
  adminUserId,
  forwardingManagerAdminList,
}: {
  adminUserId: number;
  forwardingManagerAdminList: {
    label: string;
    value: number;
  }[];
}) {
  const {
    SelectFilterPanel: ForwardingManagerSelectFilterPanel,
    selectFilter: forwardingManagerSelectFilter,
  } = useSelectFilter({
    defaultValue: adminUserId,
    selectFilterOptionList: [
      { label: "전체", value: 0 },
      ...forwardingManagerAdminList,
    ],
    label: "담당자",
  });

  const { RadioGroupFilterPanel, radioGroupFilter } = useRadioGroupFilter({
    radioGroupFilterOptionList: radioGroupOptionList,
  });

  const { TermSearchPanel, debouncedSearchTermWithObject } = useSearchWithTerm({
    termSearchTypeOptions: termSearchTypeOptionList,
  });

  const { DateSearchPanel, dateSearchType, startDate, endDate } =
    useSearchWithDate({
      dateSearchTypeOptions: dateSearchTypeOptionList,
    });

  const PromotionTableFilter = useMemo(() => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          flexWrap: "wrap",
          gap: 1,
        }}
      >
        {TermSearchPanel}
        {DateSearchPanel}
      </Box>
    );
  }, [DateSearchPanel, TermSearchPanel]);

  return {
    ForwardingManagerSelectFilterPanel,
    RadioGroupFilterPanel,
    PromotionTableFilter,
    dateSearchType,
    startDate,
    endDate,
    debouncedSearchTermWithObject,
    forwardingManagerSelectFilterValue: forwardingManagerSelectFilter?.value,
    radioGroupFilter,
  };
}
