import { useState } from "react";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Box, Button } from "@mui/material";
import { useAtomValue } from "jotai";

import { FORWARDING_PARTNER_MANAGEMENT_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/partnerManagement";

import CreatePartnerModal from "./CreatePartnerModal";
import PartnerBusinessArea from "./PartnerBusinessArea";
import PartnerFreightType from "./PartnerFreightType";
import SearchType from "./SearchType";

export default function FilterGroup() {
  const [opensCreateModal, setOpensCreateModal] = useState(false);

  const { businessArea } = useAtomValue(
    FORWARDING_PARTNER_MANAGEMENT_ATOMS.SEARCH_PARAMS
  );

  const isForeignOrDomesticPartner =
    businessArea === "foreign" || businessArea === "domestic";

  return (
    <Box
      display={"flex"}
      gap={2}
      bgcolor="white"
      border={1}
      padding={1}
      borderColor={"#dddddd"}
    >
      <PartnerBusinessArea />

      <Box borderLeft={"1px solid #dddddd"} />

      {isForeignOrDomesticPartner && <PartnerFreightType />}

      <SearchType />

      <Box display={"flex"} marginLeft={"auto"}>
        <Button
          variant="contained"
          color="info"
          size="small"
          startIcon={<PersonAddIcon />}
          onClick={() => setOpensCreateModal(true)}
        >
          파트너 등록
        </Button>
      </Box>

      <CreatePartnerModal
        isOpen={opensCreateModal}
        onClose={() => setOpensCreateModal(false)}
      />
    </Box>
  );
}
