import { Dispatch, SetStateAction } from "react";
import { Grid, Typography } from "@mui/material";

import ADMIN_SETTLEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_SETTLEMENT_QUERY";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";

import CompanyTable from "./CompanyTable";
import SearchTeamName from "./SearchTeamName";

function MatchBidIdModal({
  showsMatchBidIdModal,
  setShowsMatchBidIdModal,
  companyId,
  paymentInvoiceId,
  setCompanyId,
  depositAmount,
  refetchSettlementDepositList,
}: {
  showsMatchBidIdModal: boolean;
  setShowsMatchBidIdModal: Dispatch<SetStateAction<boolean>>;
  companyId: number;
  paymentInvoiceId: number;
  setCompanyId: Dispatch<SetStateAction<number>>;
  depositAmount: number;
  refetchSettlementDepositList: () => void;
}) {
  const {
    data: settlementDepositCompanies,
    refetch: refetchSettlementDepositCompanies,
  } = ADMIN_SETTLEMENT_QUERY.useGetAdminSettlementDepositCompanies({
    companyId,
    paymentInvoiceId,
  });

  const handleModalClose = () => {
    setShowsMatchBidIdModal(false);
    refetchSettlementDepositList();
  };

  return (
    <Modal
      isOpened={showsMatchBidIdModal}
      handleClose={handleModalClose}
      modalBody={
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography
              variant="h6"
              component="div"
              sx={{ textAlign: "center" }}
            >
              입금 의뢰번호 매칭
            </Typography>
          </Grid>

          {!companyId && (
            <Grid item>
              <Typography
                variant="body2"
                component="div"
                sx={{ textAlign: "center" }}
              >
                회사명을 검색 후 선택해 주세요.
              </Typography>
            </Grid>
          )}

          <Grid item>
            <SearchTeamName companyId={companyId} setCompanyId={setCompanyId} />
          </Grid>

          {settlementDepositCompanies &&
            !!companyId &&
            settlementDepositCompanies.length === 0 && (
              <Grid item>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ textAlign: "center" }}
                >
                  입금확인이 필요한 거래명세서가 없습니다
                </Typography>
              </Grid>
            )}

          {settlementDepositCompanies && settlementDepositCompanies.length > 0 && (
            <Grid item>
              <CompanyTable
                settlementDepositCompanies={settlementDepositCompanies}
                depositAmount={depositAmount}
                paymentInvoiceId={paymentInvoiceId}
                handleModalClose={handleModalClose}
                refetchSettlementDepositCompanies={
                  refetchSettlementDepositCompanies
                }
              />
            </Grid>
          )}
        </Grid>
      }
    />
  );
}

export default MatchBidIdModal;
