import React from "react";
import { Button, Grid, Typography } from "@mui/material";

import Modal from "@sellernote/_shared-for-forwarding-admin/src/components/Modal";

const CancelOrDeleteModal = ({
  showsCancelOrDeleteModal,
  setShowsCancelOrDeleteModal,
  initCheckBoxSet,
  setBidId,
  onWithdrawConfirm,
  type,
}: {
  showsCancelOrDeleteModal: boolean;
  setShowsCancelOrDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  initCheckBoxSet: (values?: number[] | undefined) => void;
  setBidId: React.Dispatch<React.SetStateAction<number>>;
  type: "cancel" | "delete";
  onWithdrawConfirm: () => void;
}) => {
  const handleModalClose = () => {
    initCheckBoxSet();
    setBidId(0);
    setShowsCancelOrDeleteModal(false);
  };

  return (
    <Modal
      isOpened={showsCancelOrDeleteModal}
      handleClose={handleModalClose}
      modalBody={
        <Grid container spacing={4} direction="column" alignItems={"center"}>
          <Grid item>
            <Typography variant="h6" component="div">
              {type === "cancel"
                ? "출금을 취소하시겠습니까?"
                : "리스트를 삭제하시겠습니까?"}
            </Typography>
          </Grid>

          <Grid container item justifyContent={"center"} spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={onWithdrawConfirm}
              >
                예
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="success"
                onClick={handleModalClose}
              >
                아니오
              </Button>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};

export default CancelOrDeleteModal;
