import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useQueryClient } from "react-query";
import { Box } from "@mui/material";
import { Button, Input, Modal } from "antd";
import * as EmailValidator from "email-validator";

import PARTNER_QUERY from "@sellernote/_shared/src/queries/forwarding/PARTNER_QUERY";
import {
  ManagerRequestType,
  PartnerManagerItem,
} from "@sellernote/_shared/src/types/forwarding/partner";
import regEx from "@sellernote/_shared/src/utils/common/regEx";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

const EditOrRegisterManagerModal = ({
  showEditOrRegisterManagerModal,
  setShowEditOrRegisterManagerModal,
  companyId,
  managerData,
  requestType,
  setManagerData,
}: {
  setShowEditOrRegisterManagerModal: Dispatch<SetStateAction<boolean>>;
  showEditOrRegisterManagerModal: boolean;
  companyId: number;
  managerData?: PartnerManagerItem;
  requestType: ManagerRequestType;
  setManagerData: Dispatch<SetStateAction<PartnerManagerItem | undefined>>;
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const [name, setName] = useState(managerData?.name);
  const [position, setPosition] = useState(managerData?.position);
  const [phone, setPhone] = useState(managerData?.phone);
  const [email, setEmail] = useState(managerData?.email);

  useEffect(() => {
    return () => {
      setManagerData(undefined);
    };
  }, [setManagerData]);

  const { mutate: editManager, ResponseHandler: ResponseHandlerOfEditManager } =
    PARTNER_QUERY.useEditManager({
      successModalInfo: {
        handleConfirmSuccess: (initQuery) => {
          initQuery();
          setShowEditOrRegisterManagerModal(false);
          queryClient.invalidateQueries(["forwarding", "partner", "list"]);
        },
        customizeMessage: () => ({
          title: "파트너를 수정했습니다.",
        }),
      },
      companyNumber: companyId,
      managerId: managerData?.id,
    });

  const {
    mutate: registerManager,
    ResponseHandler: ResponseHandlerOfRegisterManager,
  } = PARTNER_QUERY.useRegisterManager({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        setShowEditOrRegisterManagerModal(false);
        queryClient.invalidateQueries(["forwarding", "partner", "list"]);
      },
      customizeMessage: () => ({
        title: "파트너를 등록했습니다.",
      }),
    },
    companyId: companyId,
  });

  const handleEditOrRegisterManagerModalClose = useCallback(() => {
    setShowEditOrRegisterManagerModal(false);
  }, [setShowEditOrRegisterManagerModal]);

  const handleManagerNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setName(e.target.value);
    },
    []
  );

  const handleManagerPositionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPosition(e.target.value);
    },
    []
  );

  const handleManagerPhoneChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPhone(e.target.value.replace(regEx.number, ""));
    },
    []
  );

  const handleManagerEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
    },
    []
  );

  const handleManagerRequestClick = useCallback(() => {
    if (!name) {
      handleSnackbarOpen("이름을 입력해주세요.", "warning");
      return;
    }

    if (!email) {
      handleSnackbarOpen("이메일을 입력해주세요.", "warning");
      return;
    }

    if (!EmailValidator.validate(email)) {
      handleSnackbarOpen("이메일 형식이 아닙니다.", "warning");
      return;
    }

    if (!managerData) {
      registerManager({
        phone,
        position,
        name,
        email,
        isMain: false,
      });
    } else {
      editManager({
        phone,
        position,
        name,
        email,
        isMain: managerData.isMain,
      });
    }
  }, [
    editManager,
    email,
    handleSnackbarOpen,
    managerData,
    name,
    phone,
    position,
    registerManager,
  ]);

  const modalTitleByRequestType = (() => {
    if (requestType === "addManager") return "담당자 등록";

    return "담당자 수정";
  })();

  return (
    <Modal
      closable={false}
      title={modalTitleByRequestType}
      visible={showEditOrRegisterManagerModal}
      footer={[
        <Button key="back" onClick={handleEditOrRegisterManagerModalClose}>
          돌아가기
        </Button>,
      ]}
    >
      <Box>
        담당자 이름
        <Input value={name} onChange={handleManagerNameChange} />
        직함
        <Input value={position} onChange={handleManagerPositionChange} />
        전화번호
        <Input value={phone} onChange={handleManagerPhoneChange} />
        이메일
        <Input value={email} onChange={handleManagerEmailChange} />
      </Box>

      <Box sx={{ mt: 2 }}>
        <Button onClick={handleManagerRequestClick} type="primary">
          {requestType === "addManager" ? "등록" : "수정"}
        </Button>
      </Box>

      {ResponseHandlerOfEditManager}
      {ResponseHandlerOfRegisterManager}
    </Modal>
  );
};

export default EditOrRegisterManagerModal;
