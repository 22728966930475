import { ChangeEvent, useCallback } from "react";
import { Control, UseFormSetValue } from "react-hook-form";
import { Box } from "@mui/material";

import { Port } from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { InvoiceData } from "@sellernote/_shared/src/types/forwarding/tradingStatement";
import {
  ExchangeRate,
  TrelloBidDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";

import CargoInfo from "./CargoInfo";
import Comment from "./Comment";
import ExchangeInfo from "./ExchangeInfo";
import SettlementOfficeInfo from "./SettlementOfficeInfo";
import TransportInfo from "./TransportInfo";

const ShipmentInfo = ({
  invoiceState,
  setValue,
  portData,
  bidDetailData,
  control,
  invoiceData,
  initialExchangeList,
  directAirRton,
  handleDirectAirRtonChange,
  exchangeRateDate,
  handleExchangeRateDate,
}: {
  invoiceState: ApplyBidFormData;
  setValue: UseFormSetValue<ApplyBidFormData>;
  portData: Port[];
  bidDetailData: TrelloBidDetail;
  control: Control<ApplyBidFormData>;
  invoiceData: InvoiceData | undefined;
  initialExchangeList: ExchangeRate[];
  /** AIR일 때 직접 입력하는 Rton 값 */
  directAirRton: number;
  handleDirectAirRtonChange: (rton: number) => void;
  /** 환율 기준날짜 */
  exchangeRateDate: string;
  handleExchangeRateDate: (date: string) => void;
}) => {
  const handleCommonInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const changeInputName = event.target.name as keyof ApplyBidFormData;

      setValue(changeInputName, event.target.value);
    },
    [setValue]
  );

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <TransportInfo
        invoiceState={invoiceState}
        shipmentDetailData={bidDetailData}
        onCommonInputChange={handleCommonInputChange}
        setValue={setValue}
        portList={portData}
      />

      <CargoInfo
        invoiceState={invoiceState}
        shipmentDetailData={bidDetailData}
        directAirRton={directAirRton}
        handleDirectAirRtonChange={handleDirectAirRtonChange}
        onCommonInputChange={handleCommonInputChange}
        control={control}
        setValue={setValue}
        invoiceData={invoiceData}
      />

      <SettlementOfficeInfo
        invoiceState={invoiceState}
        shipmentDetailData={bidDetailData}
        onCommonInputChange={handleCommonInputChange}
        setValue={setValue}
      />

      <ExchangeInfo
        invoiceState={invoiceState}
        shipmentDetailData={bidDetailData}
        setValue={setValue}
        invoiceData={invoiceData}
        initialExchangeList={initialExchangeList}
        directAirRton={directAirRton}
        handleExchangeRateDate={handleExchangeRateDate}
        exchangeRateDate={exchangeRateDate}
      />

      <Comment
        invoiceState={invoiceState}
        onCommonInputChange={handleCommonInputChange}
      />
    </Box>
  );
};

export default ShipmentInfo;
