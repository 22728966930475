import { Autocomplete, SxProps, TextField } from "@mui/material";

import { SelectedTransportMode } from "@sellernote/_shared/src/types/forwarding/partnerManagement";

import NO_LABEL from "pages/partner-management/styles";

import OPTIONS_LIST from "./OPTIONS_LIST";

export default function TransportMode({
  defaultList,
  setTransportModeList,
  hidesLabel = false,
  sx,
}: {
  defaultList?: SelectedTransportMode[];
  setTransportModeList: (val: SelectedTransportMode[]) => void;
  hidesLabel?: boolean;
  sx?: SxProps;
}) {
  return (
    <Autocomplete
      multiple
      size="small"
      sx={{ minWidth: 150 }}
      defaultValue={defaultList}
      options={OPTIONS_LIST}
      getOptionLabel={({ label }) => label}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          label={hidesLabel ? "" : "운송 유형"}
          sx={{ ...(hidesLabel ? NO_LABEL : {}), ...sx }}
        />
      )}
      onChange={(_, transportMode) => setTransportModeList([...transportMode])}
    />
  );
}
