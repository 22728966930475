import { useCallback } from "react";
import { Grid, Typography } from "@mui/material";

import { PartnerBusinessArea } from "@sellernote/_shared/src/types/forwarding/partner";
import { WithdrawalFormDetail } from "@sellernote/_shared/src/types/forwarding/trello";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";

function WithdrawalRequestBody({
  formListIndex,
  formData,
  companyType,
}: {
  formListIndex: number;
  formData: WithdrawalFormDetail;
  companyType: PartnerBusinessArea;
}) {
  const getTypographyOfFormValue = useCallback(
    (formDataKey: keyof WithdrawalFormDetail) => {
      if (
        formDataKey === "amount" ||
        formDataKey === "unitPrice" ||
        formDataKey === "finalPrice" ||
        formDataKey === "totalPrice" ||
        formDataKey === "vatPrice"
      ) {
        return (
          <Typography
            variant="body1"
            component="div"
            sx={{ textAlign: "right" }}
          >
            {toThousandUnitFormat(formData[formDataKey])}
          </Typography>
        );
      }

      return (
        <Typography variant="body1" component="div">
          {formData[formDataKey]}
        </Typography>
      );
    },
    [formData]
  );

  return (
    <Grid
      item
      container
      columns={16}
      spacing={2}
      sx={{ marginTop: "8px" }}
      key={formListIndex}
    >
      <Grid item xs={companyType !== "foreign" ? 2 : 2.5}>
        {getTypographyOfFormValue("name")}
      </Grid>

      <Grid item xs={2}>
        {getTypographyOfFormValue("currency")}
      </Grid>

      <Grid item xs={companyType !== "foreign" ? 1.5 : 2}>
        {getTypographyOfFormValue("unitPrice")}
      </Grid>

      <Grid item xs={2}>
        {getTypographyOfFormValue("itemUnitMeasurement")}
      </Grid>

      <Grid item xs={companyType !== "foreign" ? 1 : 2}>
        {getTypographyOfFormValue("amount")}
      </Grid>

      {companyType !== "foreign" && (
        <Grid item xs={2}>
          {getTypographyOfFormValue("totalPrice")}
        </Grid>
      )}

      {companyType !== "foreign" && (
        <Grid item xs={1.5}>
          {getTypographyOfFormValue("vatPrice")}
        </Grid>
      )}

      <Grid item xs={2}>
        {getTypographyOfFormValue("finalPrice")}
      </Grid>
    </Grid>
  );
}

export default WithdrawalRequestBody;
