import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useAtomValue } from "jotai";

import ADMIN_SETTLEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_SETTLEMENT_QUERY";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";
import TextField from "@sellernote/_shared-for-admin/src/components/TextField";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";
import { FORWARDING_ADMIN_AUTH_SELECTORS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/auth";

function ForceCancelInvoiceModal({
  showsForceCancelModal,
  setShowsForceCancelModal,
  handleErrorResponse,
  handleSuccessResponse,
  cancelInvoiceId,
}: {
  showsForceCancelModal: boolean;
  setShowsForceCancelModal: Dispatch<SetStateAction<boolean>>;
  handleErrorResponse: () => void;
  handleSuccessResponse: () => void;
  cancelInvoiceId: number;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const [message, setMessage] = useState("");

  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const { mutate: forceCancelTaxInvoice } =
    ADMIN_SETTLEMENT_QUERY.useForceCancelTaxInvoice(cancelInvoiceId);

  const handleMessageChange = useCallback(
    (e) => setMessage(e.target.value),
    []
  );

  const handleInvoiceForceCancel = useCallback(() => {
    if (currentAdminAuthInfo?.authority === "manager") {
      handleSnackbarOpen("발행 강제취소 권한이 없습니다.", "error");
      handleErrorResponse();
      return;
    }

    forceCancelTaxInvoice(
      { message },
      {
        onSuccess: handleSuccessResponse,

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
          handleErrorResponse();
        },
      }
    );
  }, [
    currentAdminAuthInfo?.authority,
    forceCancelTaxInvoice,
    message,
    handleSuccessResponse,
    handleSnackbarOpen,
    handleErrorResponse,
  ]);

  const handleModalClose = useCallback(() => {
    setMessage("");
    setShowsForceCancelModal(false);
  }, [setShowsForceCancelModal]);

  const ModalBody = useMemo(() => {
    return (
      <Grid container spacing={4} direction="column">
        <Grid item xs={12}>
          <Typography variant="h6" component="div">
            강제 취소 사유를 입력해주세요
          </Typography>
        </Grid>

        <Grid item>
          <TextField
            fullWidth
            label={"사유 입력은 필수입니다."}
            onChange={handleMessageChange}
            value={message}
          />
        </Grid>

        <Grid container item xs={12} justifyContent="center">
          <Grid item>
            <Button
              disabled={!message}
              variant="contained"
              color="primary"
              onClick={handleInvoiceForceCancel}
            >
              강제 취소
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }, [handleInvoiceForceCancel, handleMessageChange, message]);

  return (
    <>
      <Modal
        isOpened={showsForceCancelModal}
        handleClose={handleModalClose}
        modalBody={ModalBody}
      />
    </>
  );
}

export default ForceCancelInvoiceModal;
