import { useEffect } from "react";

import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_COMMON_QUERY";
import LegacyTable from "@sellernote/_shared-for-forwarding-admin/src/components/LegacyTable";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";
import { useAdminFareTable } from "@sellernote/_shared-for-forwarding-admin/src/hooks/useAdminFareTable";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

const FareManagementLclList = () => {
  const { handleSnackbarOpen } = useSnackbar();

  const { data: portList } = ADMIN_COMMON_QUERY.useGetPortList();

  const { headCells, Rows, pagination, isFetched } = useAdminFareTable({
    portList,
    freightType: "LCL",
  });

  useEffect(() => {
    if (isFetched) {
      handleSnackbarOpen("요청에 성공했습니다.");
    }
  }, [handleSnackbarOpen, isFetched]);

  return (
    <Layout breadcrumbs={["운임 관리"]} title="LCL운임 관리">
      {headCells && Rows && (
        <LegacyTable
          title="LCL 운임관리 리스트"
          headCells={headCells}
          rows={Rows}
          pagination={pagination}
        />
      )}
    </Layout>
  );
};

export default withRequireAuth(FareManagementLclList);
