import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { Box, Button, Typography } from "@mui/material";

import ADMIN_SETTLEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_SETTLEMENT_QUERY";
import Modal from "@sellernote/_shared-for-forwarding-admin/src/components/Modal";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

import ForceCancelInvoiceModal from "./ForceCancelInvoiceModal";

function CancelInvoiceModal({
  openCancelInvoiceModal,
  setOpenCancelInvoiceModal,
  setCancelInvoiceId,
  refetchAdminSettlementSalesList,
  cancelInvoiceId,
  isAutoIssueInvoice,
  setIsAutoIssueInvoice,
}: {
  openCancelInvoiceModal: boolean;
  setOpenCancelInvoiceModal: Dispatch<SetStateAction<boolean>>;
  cancelInvoiceId: number;
  refetchAdminSettlementSalesList: () => void;
  setCancelInvoiceId: Dispatch<SetStateAction<number>>;
  isAutoIssueInvoice: boolean;
  setIsAutoIssueInvoice: Dispatch<SetStateAction<boolean>>;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const [showsForceCancelModal, setShowsForceCancelModal] = useState(false);

  const {
    mutate: cancelInvoice,
    ResponseHandler: ResponseHandlerOfIssueManualInvoice,
  } = ADMIN_SETTLEMENT_QUERY.useIssueManualInvoice();

  const handleForceCancelModalOpen = () => {
    setShowsForceCancelModal(true);
  };

  const resetState = useCallback(() => {
    setShowsForceCancelModal(false);
    setOpenCancelInvoiceModal(false);
    setCancelInvoiceId(0);
    setIsAutoIssueInvoice(false);
  }, [setCancelInvoiceId, setIsAutoIssueInvoice, setOpenCancelInvoiceModal]);

  const handleSuccessResponse = useCallback(() => {
    handleSnackbarOpen("요청에 성공했습니다.");
    resetState();
    refetchAdminSettlementSalesList();
  }, [handleSnackbarOpen, resetState, refetchAdminSettlementSalesList]);

  const handleErrorResponse = useCallback(() => {
    resetState();
  }, [resetState]);

  const handleInvoiceCancel = useCallback(() => {
    cancelInvoice(
      {
        issuedInvoiceIds: [cancelInvoiceId.toString()],
        isIssued: false,
        isDel: false,
      },
      {
        onSuccess: () => {
          resetState();
          refetchAdminSettlementSalesList();
        },
        onError: () => {
          resetState();
        },
      }
    );
  }, [
    cancelInvoice,
    cancelInvoiceId,
    resetState,
    refetchAdminSettlementSalesList,
  ]);

  const handleModalClose = useCallback(() => {
    setOpenCancelInvoiceModal(false);
    setCancelInvoiceId(0);
    setIsAutoIssueInvoice(false);
  }, [setCancelInvoiceId, setOpenCancelInvoiceModal, setIsAutoIssueInvoice]);

  return (
    <>
      <Modal
        isOpened={openCancelInvoiceModal}
        handleClose={handleModalClose}
        modalBody={
          <Box display={"flex"} flexDirection={"column"}>
            <Typography variant="h6" sx={{ fontWeight: 600, mb: 3 }}>
              계산서 발행을 취소하시겠습니까?
            </Typography>

            <Button
              size="small"
              variant="contained"
              onClick={
                isAutoIssueInvoice
                  ? handleForceCancelModalOpen
                  : handleInvoiceCancel
              }
            >
              {isAutoIssueInvoice ? "강제 취소" : "취소"}
            </Button>
          </Box>
        }
      />

      {showsForceCancelModal && (
        <ForceCancelInvoiceModal
          showsForceCancelModal={showsForceCancelModal}
          setShowsForceCancelModal={setShowsForceCancelModal}
          handleErrorResponse={handleErrorResponse}
          handleSuccessResponse={handleSuccessResponse}
          cancelInvoiceId={cancelInvoiceId}
        />
      )}

      {ResponseHandlerOfIssueManualInvoice}
    </>
  );
}

export default CancelInvoiceModal;
