import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";
import useVesselScheduleTable from "@sellernote/_shared-for-forwarding-admin/src/hooks/useVesselScheduleTable";

const VesselScheduleLclWarehouse = () => {
  const { VesselScheduleTable, ResponseHandlerOfGetVesselScheduleList } =
    useVesselScheduleTable({ freightType: "LCL", includesWarehouse: true });

  return (
    <Layout breadcrumbs={[]} title={"운항 스케줄 LCL(창고)"}>
      {VesselScheduleTable}

      {ResponseHandlerOfGetVesselScheduleList}
    </Layout>
  );
};

export default withRequireAuth(VesselScheduleLclWarehouse);
