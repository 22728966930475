const COMMON_BOX_STYLE = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const TEXT_FIELD_STYLE = {
  "& legend": { display: "none" },
  "& fieldset": { top: 0, padding: 0 },
  ".MuiOutlinedInput-input": { padding: 1 },
};

const COMMON_SELECT_STYLE = { "& legend": { display: "none" } };

const COMMON_SELECT_WIDTH = { width: 182 };

export {
  COMMON_BOX_STYLE,
  TEXT_FIELD_STYLE,
  COMMON_SELECT_STYLE,
  COMMON_SELECT_WIDTH,
};
