import { Button } from "@mui/material";

import { AccountDetailFile } from "@sellernote/_shared/src/types/forwarding/adminAccount";
import { FileRelatedToFinAccount } from "@sellernote/_shared/src/types/forwarding/partnerManagement";
import { omitWithEllipsis } from "@sellernote/_shared/src/utils/common/string";

const renderFileButton = ({
  list,
  targetDomain,
  setPreviewFile,
}: {
  list: FileRelatedToFinAccount[];
  targetDomain: AccountDetailFile["domain"];
  setPreviewFile: (value: FileRelatedToFinAccount) => void;
}) => {
  if (!list.length) return "-";

  const targetFileList = list.filter(({ domain }) => domain === targetDomain);

  const fileButton = targetFileList.map((file) => (
    <Button key={file.key} onClick={() => setPreviewFile(file)} sx={{ p: 0 }}>
      {omitWithEllipsis({
        src: file.name,
        maxLength: 14,
        ellipsis: "...",
      })}
    </Button>
  ));

  return fileButton;
};

export { renderFileButton };
