import { useCallback, useState } from "react";
import { Box, SxProps, Theme } from "@mui/material";
import { useRecoilState } from "recoil";

import ADMIN_ORDER_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_ORDER_QUERY";
import {
  POProjectStatus,
  POStatus,
} from "@sellernote/_shared/src/types/forwarding/adminOrder";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import { SHARED_FOR_FORWARDING_ADMIN_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/states/filters";

import OrderManagementTable from "./OrderManagementTable";
import useOrderTablePanel from "./OrderManagementTable/useOrderTablePanel";
import useTableHeadPanel from "./useTableHeadPanel";

const boxLayoutStyle: SxProps<Theme> = { p: 1, background: "#fff" };

const Order = () => {
  const [filterData, setFilterData] = useRecoilState(
    SHARED_FOR_FORWARDING_ADMIN_ATOMS.ADMIN_ORDER_FILTER_LIST
  );

  const [currentPage, setCurrentPage] = useState(0);

  const [perPage, setPerPage] = useState(20);

  const {
    debouncedSearchTermWithObject,
    dateFilter,
    totalStatus,
    totalDetailStatus,
    TablePanel,
  } = useOrderTablePanel("ADMIN_ORDER_FILTER_LIST");

  const {
    TableHeadPanel,
    tableHeadFilterData: orderHeadFilterData,
    handleFilterReset,
  } = useTableHeadPanel("ADMIN_ORDER_FILTER_LIST");

  const saveFilterDataRefCallback = useCallback(() => {
    setFilterData({
      ...orderHeadFilterData,
      ...debouncedSearchTermWithObject,
      ...dateFilter,
      poStatus: totalStatus as POStatus,
      poProjectStatus: totalDetailStatus as POProjectStatus,
    });
  }, [
    orderHeadFilterData,
    debouncedSearchTermWithObject,
    totalStatus,
    totalDetailStatus,
    dateFilter,
    setFilterData,
  ]);

  const {
    data: orderList,
    isLoading: isLoadingForOrderList,
    refetch: refetchOrderList,
  } = ADMIN_ORDER_QUERY.useGetAdminOrderList({
    page: currentPage,
    perPage,
    ...filterData,
  });

  return (
    <Layout title="발주관리">
      <Box sx={boxLayoutStyle}>{TablePanel}</Box>

      <Box sx={boxLayoutStyle}>
        <OrderManagementTable
          orderList={orderList}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          perPage={perPage}
          setPerPage={setPerPage}
          TableHeadPanel={TableHeadPanel}
          saveFilterDataRefCallback={saveFilterDataRefCallback}
          isLoadingForOrderList={isLoadingForOrderList}
          refetchOrderList={refetchOrderList}
          handleFilterReset={handleFilterReset}
        />
      </Box>
    </Layout>
  );
};

export default Order;
