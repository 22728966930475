import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";

import TeamTable from "../TeamTable";

function TeamList() {
  return (
    <Layout title="팀 관리">
      <TeamTable />
    </Layout>
  );
}

export default withRequireAuth(TeamList);
