const CELL_LIST = [
  {
    id: "isMain",
    disablePadding: false,
    label: "메인",
    width: 60,
  },
  {
    id: "name",
    disablePadding: false,
    label: "거래처명",
    width: 170,
  },
  {
    id: "representativeName",
    disablePadding: false,
    label: "대표자",
    width: 100,
  },
  {
    id: "BRN",
    disablePadding: false,
    label: "사업자등록번호",
    width: 130,
  },
  {
    id: "BRNFile",
    disablePadding: false,
    label: "사업자등록증",
    width: 180,
  },
  {
    id: "taxEmail",
    disablePadding: false,
    label: "세금계산서 메일",
    width: 200,
  },
  {
    id: "bankAccountInfo",
    disablePadding: false,
    label: "계좌정보",
    width: 180,
  },
  {
    id: "BankBookCopy",
    disablePadding: false,
    label: "통장사본",
    width: 180,
  },
];

export default CELL_LIST;
