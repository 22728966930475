import { Typography } from "@mui/material";

export default function Title({
  title,
  isNecessary = true,
}: {
  title: string;
  isNecessary?: boolean;
}) {
  return (
    <Typography sx={{ width: 100 }}>
      {isNecessary && <span style={{ color: "#FF0000" }}>*</span>} {title}:
    </Typography>
  );
}
