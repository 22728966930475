import { useMemo } from "react";

import { UserListItem } from "@sellernote/_shared/src/types/forwarding/adminBidUser";
import { LegacyTableHeadCell } from "@sellernote/_shared-for-forwarding-admin/src/components/LegacyTable";
import useTableHeadSortFilter from "@sellernote/_shared-for-forwarding-admin/src/hooks/useTableHeadSortFilter";

const sortTypeOptionList = ["totalBidsCount", "inProgressBidCount"];

export default function useHandleUserTable() {
  const { sortFilterMap, sort, order } = useTableHeadSortFilter({
    sortTypeOptionList,
  });

  const headCellList: LegacyTableHeadCell<keyof UserListItem>[] =
    useMemo(() => {
      return [
        {
          id: "id",
          disablePadding: false,
          label: "NO",
        },
        {
          id: "company",
          disablePadding: false,
          label: "회사명",
        },
        {
          id: "country",
          disablePadding: false,
          label: "국가",
          width: 80,
        },
        {
          id: "name",
          disablePadding: false,
          label: "담당자 이름",
        },
        {
          id: "phone",
          disablePadding: false,
          label: "연락처",
        },
        {
          id: "email",
          disablePadding: false,
          label: "이메일",
        },
        {
          id: "forwardingManagerId",
          disablePadding: false,
          label: "포워딩 담당자",
        },
        {
          id: "searchCount",
          disablePadding: false,
          label: "검색건수",
        },
        {
          id: "totalBidsCount",
          disablePadding: false,
          label: "견적의뢰수",
          width: 130,
          filter: sortFilterMap.get("totalBidsCount"),
        },
        {
          id: "inProgressBidCount",
          disablePadding: false,
          label: "진행건수",
          width: 130,
          filter: sortFilterMap.get("inProgressBidCount"),
        },
        {
          id: "createdAt",
          disablePadding: false,
          label: "생성일",
        },
      ];
    }, [sortFilterMap]);

  return {
    sort,
    order,
    headCellList,
  };
}
