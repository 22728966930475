import { ChangeEvent, useCallback, useMemo } from "react";
import { UseFormSetValue } from "react-hook-form";
import { Autocomplete, Box } from "@mui/material";
import {
  DatePicker as MUIDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { Port } from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { TrelloBidDetail } from "@sellernote/_shared/src/types/forwarding/trello";
import {
  toFormattedDate,
  toFormattedDateToUTCDate,
  transformUTCDateToLocalDateTime,
} from "@sellernote/_shared/src/utils/common/date";

import DescriptionGridBox from "../components/DescriptionGridBox";
import NoLabelTextField from "../components/NoLabelTextField";

function TransportInfo({
  invoiceState,
  shipmentDetailData,
  onCommonInputChange,
  portList,
  setValue,
}: {
  invoiceState: ApplyBidFormData;
  shipmentDetailData: TrelloBidDetail;
  onCommonInputChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  portList: Port[];
  setValue: UseFormSetValue<ApplyBidFormData>;
}) {
  const portListForAutoCompleteOptions = useMemo(() => {
    return portList.map((v) => {
      return { value: v.nameEN, label: v.nameEN };
    });
  }, [portList]);

  const getCountryNameByPortName = useCallback(
    (portName: string) => {
      const filterPortData = portList.find((v) => {
        return v.nameEN === portName;
      });

      return filterPortData?.country || "";
    },
    [portList]
  );

  const handleStartPortChange = useCallback(
    (autoCompleteValue: { value: string; label: string } | null) => {
      if (autoCompleteValue) {
        setValue("startPort", autoCompleteValue.value);

        setValue(
          "startCountryName",
          getCountryNameByPortName(autoCompleteValue.value)
        );
      }
      return;
    },
    [setValue, getCountryNameByPortName]
  );

  const handleEndPortChange = useCallback(
    (autoCompleteValue: { value: string; label: string } | null) => {
      if (autoCompleteValue) {
        setValue("endPort", autoCompleteValue.value);

        setValue(
          "endCountryName",
          getCountryNameByPortName(autoCompleteValue.value)
        );
      }
      return;
    },
    [setValue, getCountryNameByPortName]
  );

  return (
    <DescriptionGridBox
      title={"운송 정보"}
      descriptionValueList={[
        {
          label: "M B/L NO",
          gridSize: 4,
          value:
            (shipmentDetailData.management.BLType === "MBL" ||
              shipmentDetailData.management.BLType === "DirectMBL" ||
              shipmentDetailData.management.BLType === "ALL") &&
            shipmentDetailData.management.mBL,
        },

        {
          label: "H B/L NO",
          gridSize: 4,
          value:
            (shipmentDetailData.management.BLType === "HBL" ||
              shipmentDetailData.management.BLType === "ALL") &&
            shipmentDetailData.management.hBL,
        },
        {
          label: "INCOTERMS",

          gridSize: 4,
          value: shipmentDetailData.incoterms,
        },
        ...(shipmentDetailData.freightType !== "AIR"
          ? [
              {
                label: "VSL / VYG",
                gridSize: 4,
                value: (
                  <Box sx={{ display: "flex" }}>
                    <NoLabelTextField
                      value={invoiceState.shipName}
                      onChange={onCommonInputChange}
                      size="small"
                      name="shipName"
                      fullWidth
                    />

                    <NoLabelTextField
                      value={invoiceState.voyageNo}
                      onChange={onCommonInputChange}
                      size="small"
                      name="voyageNo"
                      fullWidth
                    />
                  </Box>
                ),
              },
            ]
          : []),
        {
          label: "PORT OF LOADING",
          gridSize: 4,
          value: (
            <Autocomplete
              size="small"
              onChange={(event, newValue) => handleStartPortChange(newValue)}
              value={{
                value: invoiceState.startPort,
                label: invoiceState.startPort,
              }}
              options={portListForAutoCompleteOptions}
              renderInput={(params) => <NoLabelTextField {...params} />}
            />
          ),
        },
        {
          label: "PORT OF DISCHARGE",
          gridSize: 4,
          value: (
            <Autocomplete
              size="small"
              onChange={(event, newValue) => handleEndPortChange(newValue)}
              value={{
                value: invoiceState.endPort,
                label: invoiceState.endPort,
              }}
              options={portListForAutoCompleteOptions}
              renderInput={(params) => <NoLabelTextField {...params} />}
            />
          ),
        },
        {
          label: "Arrival date",
          gridSize: 4,
          value: !shipmentDetailData.isImport ? (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MUIDatePicker
                inputFormat="yyyy-MM-dd"
                value={invoiceState.arrivalDate}
                onChange={(date) => {
                  if (!date) {
                    setValue("arrivalDate", "");
                    return;
                  }

                  setValue(
                    "arrivalDate",
                    transformUTCDateToLocalDateTime({
                      utcDateTime: date,
                      timeZone: "Asia/Seoul",
                      when: "start",
                    })?.toISOString() || toFormattedDateToUTCDate(date)
                  );
                }}
                renderInput={(params) => (
                  <NoLabelTextField
                    {...params}
                    onKeyDown={(event) => {
                      event.preventDefault();
                    }}
                    fullWidth={true}
                    autoComplete="off"
                    size="small"
                  />
                )}
              />
            </LocalizationProvider>
          ) : (
            toFormattedDate(invoiceState.arrivalDate, "YYYY-MM-DD")
          ),
        },

        {
          label: "MODE",
          gridSize: 4,
          value: shipmentDetailData.freightType,
        },
      ]}
    />
  );
}

export default TransportInfo;
